// Invoice page that businesses can share to their customer without requiring them to sign up
import { formatNumberToCurrency } from "../../../lib/currency"
import logo from "../../../assets/Logo_DigLog.png"
import { useParams } from "react-router-dom"
import useInvoicePayDetails from "../../../hooks/api/use-invoice-pay-details"
import InvoicePreview from "../../../components/InvoicePreview"
import { Invoice } from "../../../lib/models"
import { FC, useState } from "react"
import { BACKEND_API } from "../../../constants/Config"
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js"
import {
  capturePayPalPayment,
  createInvoicePayPalOrder,
} from "../../../lib/invoice"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { t } from "i18next"
import { Trans } from "react-i18next"
import { AxiosError } from "axios"
import { generateTemplateCSSVar } from "../../../lib/templates"
import useTemplatePublic from "../../../hooks/api/use-template-public"
import { generateVariantName } from "../../../lib/variants"
import useProducts from "../../../hooks/api/use-products"

const InvoicePayment = () => {
  const { id } = useParams<{ id: string }>()
  const { data: products } = useProducts()
  const { data, mutate } = useInvoicePayDetails(id)
  const { data: template } = useTemplatePublic(data?.business.id)

  if (!data) return null

  const invoice = {
    ...data,
    createdAt: new Date(data.createdAt),
    invoiceDate: new Date(data.invoiceDate),
    dueDate: new Date(data.dueDate),
  }

  return (
    <div className={"bg-gray-50"} style={generateTemplateCSSVar(template)}>
      <div className="mx-auto box-border flex min-h-screen w-full max-w-[1400px] flex-col px-4 md:px-8">
        <div className={"mb-6 flex pt-8"}>
          <p className={"m-0 p-0 text-xl font-medium"}>
            Invoice from {data.business.name}
          </p>

          <a
            href={`${BACKEND_API}/invoice/${invoice.id}/pay/pdf`}
            className="ml-auto font-bold text-template-primary no-underline hover:underline"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Download PDF
          </a>
        </div>

        <div className="relative flex w-full flex-col-reverse items-start gap-4 lg:flex-row">
          <InvoicePreview
            invoice={invoice}
            items={data.orderedProducts.map((item) => {
              const product = products?.find(
                (product) => product.id === item.product.id
              )

              let variantName = ""
              if (item.productVariant) {
                const variant = product?.productVariants?.find(
                  (v) => v.id === item.productVariant?.id
                )

                variantName = generateVariantName(
                  variant?.variantValues,
                  product?.productOptions
                )
              }

              return {
                id: item.id,
                product: {
                  id: item.id,
                  name: item.name,
                  description: item.product.description,
                  productVariant: {
                    id: item?.productVariant?.id ?? "",
                    name: variantName,
                  },
                },
                sellingPrice: item.sellingPrice,
                description: "",
                quantity: item.quantity,
              }
            })}
            business={data.business}
            showOutstanding
          />
          <PaymentDetails
            invoice={invoice}
            paymentMethods={data.paymentMethods}
            onPaymentSuccess={async () => {
              await mutate()
            }}
            onPaymentFailed={async () => {
              await mutate()
            }}
          />
        </div>
        <Footer />
      </div>
    </div>
  )
}

const PaymentDetails: FC<{
  invoice: Invoice
  paymentMethods: Array<{
    clientId: string
    method: string
    merchantId: string
  }>
  onPaymentSuccess: () => void
  onPaymentFailed: () => void
}> = ({ paymentMethods, onPaymentSuccess, invoice, onPaymentFailed }) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const paypalCreds = paymentMethods.find((p) => p.method === "Paypal")

  return (
    <div className="top-4 mx-auto w-full max-w-[1400px] rounded-xl border border-solid border-gray-200 bg-white shadow-sm lg:sticky lg:basis-1/4">
      <div className="flex items-center p-4">
        <p className={"m-0 p-0 text-lg font-medium"}>Balance due:</p>
        <p className={"my-0 ml-auto p-0 text-2xl font-medium"}>
          {formatNumberToCurrency(invoice.currency, invoice.outstanding ?? 0)}
        </p>
      </div>

      {error && (
        <div className="border border-x-0 border-b-0 border-t border-solid border-gray-200 p-4 font-medium">
          <p className="m-0 rounded-lg border border-solid border-amber-200 bg-amber-50 p-3 text-center font-medium text-amber-700">
            {error}
          </p>
        </div>
      )}

      {!loading &&
        invoice.totalPrice > 0 &&
        invoice.status !== "PAID" &&
        invoice.status !== "PENDING" &&
        paypalCreds && (
          <div className="flex flex-col items-center border-x-0 border-b-0 border-t border-solid border-gray-200 p-4">
            <PayPalScriptProvider
              options={{
                "client-id": paypalCreds.clientId,
                "merchant-id": paypalCreds.merchantId,
                // "enable-funding": "ideal,venmo,bancontact",
                components: "buttons",
                currency: invoice.currency,
              }}
            >
              <PayButtons
                createOrder={async () => {
                  setError(null)
                  const result = await createInvoicePayPalOrder(invoice.id)
                  return result.data.orderId
                }}
                onApprove={async (orderId, restart) => {
                  try {
                    await capturePayPalPayment(invoice.id, orderId)
                    await onPaymentSuccess()
                  } catch (e) {
                    if (e instanceof AxiosError) {
                      if (e.response?.data.key === "INSTRUMENT_DECLINED") {
                        restart()
                      }
                      setError(
                        t(
                          "Oops, we failed to capture your payment, please try again."
                        )
                      )
                    } else {
                      setError(
                        t("Oops, something went wrong, please try again.")
                      )
                    }
                  }
                }}
                onError={async (message) => {
                  setError(message)
                  await onPaymentFailed()
                }}
              />
            </PayPalScriptProvider>
          </div>
        )}

      {!loading && invoice.status === "PAID" && (
        <div className="border-x-0 border-b-0 border-t border-solid border-gray-200 p-4 font-medium">
          <p className="m-0 rounded-lg bg-green-50 p-3 text-center font-medium text-green-700">
            <Trans>Invoice Paid</Trans>
          </p>
        </div>
      )}

      {!loading && invoice.status === "PENDING" && (
        <div className="border-x-0 border-b-0 border-t border-solid border-gray-200 p-4 font-medium">
          <p className="m-0 rounded-lg bg-amber-50 p-3 text-center font-medium text-amber-700">
            <Trans>Payment is being processed.</Trans>
          </p>
        </div>
      )}

      {loading && (
        <div className="flex justify-center border-x-0 border-b-0 border-t border-solid border-gray-200 p-4 font-medium">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

const PayButtons: FC<{
  createOrder: () => Promise<string>
  onApprove: (orderId: string, restart: () => void) => Promise<void>
  onError: (message: string) => void
}> = ({ createOrder, onApprove, onError }) => {
  const [paypal] = usePayPalScriptReducer()

  if (paypal.isPending) {
    return (
      <div className="flex justify-center p-4 font-medium">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <PayPalButtons
      className={"flex w-full justify-center !bg-template-primary"}
      createOrder={createOrder}
      onApprove={(data, actions) => {
        return onApprove(data.orderID, actions.restart)
      }}
      onError={(e) => {
        onError(t("Oops, something went wrong, please try again."))
      }}
      onCancel={(e) => {
        onError(t("Payment cancelled, please try again."))
      }}
    />
  )
}

const Footer = () => (
  <footer className="sticky mb-8 mt-auto flex items-center justify-center pt-16">
    <p className={"m-0 mr-2 p-0 text-gray-700"}>Powered by</p>
    <img src={logo} alt={"DizLog"} className={"h-6"} />
  </footer>
)

export default InvoicePayment
