import ThermalPrinter from "./model"
import useCompanionApp from "../../hooks/use-companion-app"

export default class CompanionAppThermalPrinter implements ThermalPrinter {
  private deviceId: string = ""
  private type: "RECEIPT" | "LABEL" | "KITCHEN" = "RECEIPT"
  private identifier: string | null = ""

  constructor(type: "RECEIPT" | "LABEL" | "KITCHEN", identifier?: string) {
    this.type = type
    if (identifier) {
      this.identifier = identifier
    }
  }

  async getConnectedDeviceIds(): Promise<string[]> {
    return []
  }

  async connect(): Promise<void> {
    // do nothing
  }

  async reconnect(deviceId: string): Promise<void> {
    // do nothing
  }

  // print should sent data in the format of
  // { action: "print", type: "RECEIPT", data: [1, 2, 3, 4] }
  async print(data: Uint8Array): Promise<void> {
    await useCompanionApp
      .getState()
      .printThermal(this.type, this.identifier, data)
  }

  async disconnect(): Promise<void> {
    // do nothing
  }

  getId(): string {
    return this.deviceId
  }

  onDisconnect(callback: () => void): void {
    const socket = useCompanionApp.getState().socket
    if (socket) {
      onclose = callback
    }
  }
}
