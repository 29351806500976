import useAPI from "./use-api"
import { mutate, SWRConfiguration } from "swr"
import { PRODUCT_TYPE, ReceiptType } from "../../lib/models"
import { useEffect, useState } from "react"
import { getOfflineOrder } from "../../lib/offline-pos/db"
import Big from "big.js"
import { getAPI } from "../../lib/api"

export enum RefundStatus {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  REFUNDED = "REFUNDED",
}

export enum RentalStatus {
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
  CANCELLED = "CANCELLED",
}

export interface GetCartTransactionResponse {
  id: string
  isMpos: boolean
  isServiceOrder: boolean
  promotions: string[] | undefined
  cancelReason?: string
  promotionDetails?: Array<{
    name: string
    created_at: string
    updated_at: string
    discountPercentage: number
    businessId: any
    id: string
    discountApplyType: string
    discountType: string
    code: string
    discountFixedAmount: any
    isAppliedToAllProducts: boolean
    isAppliedToAllCountries: boolean
    isAppliedToAllCustomers: boolean
    isProductGotFree: boolean
    minItemQuantity: number
    minPurchaseAmount: number
    maxUsesPerOrder: any
    limitTotal: any
    isLimitedToOnePerCustomer: boolean
    productGotPercentage: any
    maxFreeShippingAmount: any
    startTime: string
    endTime: any
    productGotQuantity: any
    isActive: boolean
    subsriptionServiceIds: any
    subsriptionPublicityIds: any
    usageCount: number
    minDuration: any
    isAppliedPerQuantity: boolean
    restrictedAccess: boolean
    category: string
    absoluteUsageLimit: number
    perCustomerUsageLimit: number
    enableAbsoluteUsageLimit: boolean
    enablePerCustomerUsageLimit: boolean
    taxExemption: boolean
  }>

  buxPaymentStatus: string
  orderStatus: string
  totalCartAmount: number
  discount: number
  cashReceived: number
  cashChange: number
  totalItemQuantity: number
  orderNumber: number
  tax: number
  shippingCost: number
  paymentMethodName: string
  paymentMethodCode: string
  paymentChannel?: string
  buxRequestId: string
  pickupTime: any
  serviceCharge: number
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
  promotion: any
  notes: any
  isWalkIn?: boolean
  dueDate?: string
  customer?: {
    id?: string
    fullName: string
    phone: string
    email: string
    points?: number
    enableCashback?: boolean
    cashback?: number
    taxId?: string
    seniorCitizenId?: string
    dob: string
    membershipCode: string
    customerLocations?: Array<{
      id: string
      addressLine1: string
      name: string
      addressLine2: string
      city: string
      province: string
      zip: string
      latitude: any
      longitude: any
      isPrimaryLocation: boolean
      phone: string
      isBillingAddress: boolean
    }>
  }
  grandTotal: number
  orderedProducts: OrderedProduct[]
  rentalStatus?: RentalStatus | null
  shipment: any
  paymentId: string
  nextStatus: string
  outstanding?: number
  location: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    createdAt: string
    updatedAt: string
    companyName?: string
    isVatRegistered?: string
    tin?: string
    birEnabled?: boolean
  }
  cashRegister?: {
    id: string
    name: string
    serialNo: string
    ptu: string
    ptuDateIssued: string
    ptuValidUntil: string
    min: string
  }
  refund?: {
    id: string
    amount: number
    shippingCost: number
    isReturned: boolean
    isRefunded: boolean
    notes: string
    createdAt: string
    updatedAt: string
    acceptedDate: string
    type: string
    status: RefundStatus
    refundItem: Array<{
      id: string
      isPartialRefund: boolean
      createdAt: string
      updatedAt: string
      reason: string
      quantity: number
      quantityDamaged: number
      quantityReceived: number
      orderedProduct: {
        id: string
        totalPrice: number
        sellingPrice: number
        quantity: number
        quantityReceived: number
        downloadCount: number
        discount: number
        orderDate: string
        createdAt: string
        updatedAt: string
        product: {
          id: string
          isReturnable: boolean
        }
      }
    }>
  }
  trackingStatus?: string
  customerLocation?: {
    id: string
    addressLine1: string
    name: string
    addressLine2: string
    city: string
    province: string
    zip: string
    latitude: string | null
    longitude: string | null
    isPrimaryLocation: boolean
    phone: string
  }
  invoice?: {
    id: string
    totalPrice: number | null
    shippingCost: number
    otherCost: number
    otherCostDetails: string
    status: "DRAFT" | "PAID"
    notes: string
    invoiceNumber: number
    referenceNumber: string
    currency: string
    termsAndCondition: string
    memo: string
    percentageDiscount: number
    fixedDiscount: number
    createdAt: string
    updatedAt: string
    dueDate: string
    invoiceDate: string
    termType: string
    tax: number
    paymentId: string | null
    paymentMethod: string
    attachment: any | null
    receiptUrls?: string
  }
  taxes?: Array<{
    amount: number
    name: string
    rate: number
    type: "ADDED" | "INCLUDED"
  }>
  taxExemptions?: Array<{
    amount: number
    name: string
    rate: number
    type: "ADDED" | "INCLUDED"
  }>
  rewardedPoints?: number
  rewardedCashback?: number
  cashbackApplied?: number
  isB2B?: boolean
  paymentTerm?: {
    id: string
    name: string
    days: number
    isDefault: boolean
    isEnabled: boolean
    createdAt: string
    updatedAt: string
  }
  customerName?: string
  payments?: Payment[]
  splitPaymentDetails?: Array<{
    id: string
    paymentMethodCode: "CASH" | "CREDIT_CARD" | "EWALLET" | "ONLINE"
    amount: number
    status: "PENDING" | "PAID"
    paymentId?: string
  }>
  isRefundable?: boolean
  isVoidable?: boolean
  nonTaxDiscounts?: number

  // Deliverect fields
  deliverectOrderType?: 1 | 2 | 3
  deliverectOrderId?: string
  deliverectChannelOrderDisplayId?: string
  deliverectDeliveryTime?: string
  deliverectPickupTime?: string
  isDeliveryOrder?: boolean
  source?: string
  receiptHistory?: Array<{
    id: string
    seriesNumber: string
    ORNumber: string
    type: ReceiptType
    printed: boolean
  }>
  queueNumber?: number | string
  orderType?: string
  tables?: Array<{
    id: string
    name: string
    pax: number
    xAxis: number
    yAxis: number
    createdAt: string // or Date if you plan on converting to a Date object
    updatedAt: string // same note as above
  }>
}

export function getActualCashReceived(ct: GetCartTransactionResponse): number {
  // const { cashReceived, splitPaymentDetails } = ct
  // if (splitPaymentDetails) {
  //   return splitPaymentDetails.reduce((sum, { paymentMethodCode, amount }) => {
  //     return paymentMethodCode == 'CASH' ? sum + amount : sum
  //   }, 0)
  // }
  // return cashReceived
  return ct.cashReceived
}

export function getActualCashChange(ct: GetCartTransactionResponse): number {
  // const { cashChange, cashReceived, grandTotal, splitPaymentDetails } = ct
  // if (cashReceived > grandTotal && splitPaymentDetails && splitPaymentDetails.length > 0) {
  //   for (const { paymentMethodCode } of splitPaymentDetails) {
  //     if (paymentMethodCode == 'CASH') {
  //       return cashReceived - grandTotal
  //     }
  //   }
  // }
  // return cashChange
  return ct.cashChange
}

export interface Payment {
  id: string
  paymentMethodCode: string
  paymentChannel: any
  amount: number
  createdAt: string
  updatedAt: string
  imageUrls?: string
}

export interface OrderedProduct {
  id: string
  created_at: string
  updated_at: string
  cartTransactionsId: string
  productId: string
  totalPrice: number
  quantity: number
  quantityReturned?: number
  discount: number
  discountType?: string
  orderDate: string
  businessId: string
  purchaseOrderId: any
  name: string
  SKU: string
  sellingPrice: number
  unitOfMeasure: string
  imageUrl?: string
  productType: PRODUCT_TYPE
  isReturnable: boolean
  productVariantId?: string
  productOptionValues: Array<{
    id: string
    name: string
  }>
  tax?: number
  serviceCharge?: number
  taxIncluded?: boolean
  orderedProductAddOns?: OrderedProductAddOns[]
  employees?: Array<{
    id: string
    fullName: string
  }>
  variantSKU?: string
  bundleItems?: Array<{
    id: string
    name: string
    productOptionValues: Array<{
      name: string
      productOptionName: string
    }>
    quantity: number
    bundleItem: string
    variant?: string
  }>
  taxable?: boolean
  enableBIR?: string
  notes?: string
  orderedProductDetail?: {
    employeesBundleItems: Array<{
      employee: string
      product?: string
      variant?: string
    }>
  }
  originalSellingPrice?: number
}

export interface OrderedProductAddOns {
  id: string
  name: string
  sellingPrice: number
  quantity: number
  groupName?: string
}

const useCartTransaction = (
  id: string,
  config: SWRConfiguration<GetCartTransactionResponse> = {}
) => {
  const swr = useAPI<GetCartTransactionResponse>(
    `/cartTransaction/${id}`,
    {
      join: ["invoice", "orderedProducts.product", "tables"],
    },
    config
  )

  if (swr.data && !swr.data.outstanding && swr.data.orderStatus !== "PAID" && swr.data.orderStatus !== "DONE") {
    // some old outstanding data was not accurate due to a bug on transaction creation.
    swr.data.outstanding = Math.max(
      Big(swr.data?.grandTotal || 0)
        .minus(swr.data?.cashReceived || 0)
        .round(2)
        .toNumber(),
      swr.data?.outstanding ?? 0
    )
  }

  return swr
}

export const useOfflineCartTransaction = (id: string) => {
  const [data, setData] = useState<GetCartTransactionResponse | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const order = await getOfflineOrder(id)
      if (order) {
        setData(order.cartTransaction)
      }
      setIsLoading(false)
    })()
  }, [id])

  return { data, isLoading }
}

export const mutateCartTransactionById = (
  id: string,
  data: GetCartTransactionResponse
) => {
  return mutate(`/cartTransaction/${id}`, data)
}

export const getOneCartTransaction = async (id: string) => {
  return getAPI<GetCartTransactionResponse>(
    `/cartTransaction/${id}?join=invoice&join=orderedProducts.product`
  )
}

export default useCartTransaction
