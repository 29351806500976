import {
  Cart,
  CartItem,
  Customer,
  KitchenDisplay,
  ManualDiscountType,
  PaymentTerm,
  PRODUCT_TYPE_STRING,
  Promotion,
  PromotionMetadata,
  Reward,
  ServiceCartItem,
} from "./models"
import { produce as immerProduce } from "immer"
import { v4 as uuidv4 } from "uuid"
import { createServiceAppointment } from "./service"
import { patchAPI, postAPI } from "./api"
import Big from "big.js"
import { PostCheckoutResponseBody } from "../hooks/api/use-checkout"
import { GetProductsResponse } from "../hooks/api/use-products"
import { uniq } from "lodash"
import { POS_TYPE } from "../views/Mpos/BusinessCatalog/NewDesign/POSTypeSelect"

export const incrementCartItem = (item: CartItem) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return

    itemInCart.qty++
    if (itemInCart.orderedProductAddOns) {
      itemInCart.orderedProductAddOns?.forEach((addOns) => {
        const addOnGroup = itemInCart.product.productAddOnsGroup?.find((g) =>
          g.productAddOns.some((addOn) => addOn.id === addOns.productAddOns.id)
        )
        //increase quantity for fixed quantity add ons
        if (!addOnGroup?.allowQuantitySelector) {
          addOns.quantity += 1
        }
      })
    }

    computeDeposit(itemInCart)
  })
}

export const decrementCartItem = (item: CartItem) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return

    if (itemInCart.qty > 1) {
      itemInCart.qty--

      if (itemInCart.orderedProductAddOns) {
        itemInCart.orderedProductAddOns?.forEach((addOns) => {
          const addOnGroup = itemInCart.product.productAddOnsGroup?.find((g) =>
            g.productAddOns.some(
              (addOn) => addOn.id === addOns.productAddOns.id
            )
          )
          //decrease quantity for fixed quantity add ons
          if (!addOnGroup?.allowQuantitySelector && addOns.quantity > 1) {
            addOns.quantity -= 1
          }
        })
      }

      computeDeposit(itemInCart)
      return
    }

    cart.items = cart.items.filter((i) => !isItemIdentical(i, itemInCart))
  })
}

export const setCartItemQty = (item: CartItem, qty: number) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return

    itemInCart.qty = qty
    computeDeposit(itemInCart)
  })
}

export const setSellingPrice = (
  item: CartItem,
  sellingPrice: number,
  isCustomPrice = true
) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart || !(sellingPrice >= 0)) return // allow to set selling price to 0

    itemInCart.sellingPrice = sellingPrice
    itemInCart.isCustomPrice = isCustomPrice
    computeDeposit(itemInCart)
  })
}

export const setDiscount = (
  item: CartItem,
  discount: number,
  type: ManualDiscountType
) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return

    itemInCart.discount = discount
    itemInCart.discountType = type
    computeDeposit(itemInCart)
  })
}

export const setEmployees = (
  item: CartItem,
  employees: Array<{
    product?: string
    variant?: string
    employee: string
  }>
) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return

    itemInCart.employees = employees
  })
}

export const setPartialPayment = (item: CartItem, value: boolean) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return
    itemInCart.partialPayment = value
    computeDeposit(itemInCart)
  })
}

export const removeItemFromCart = (item: CartItem) => {
  return p((cart) => {
    cart.items = cart.items.filter((i) => i.id !== item.id)
  })
}

export const setAddOnQuantity = (
  item: CartItem,
  addOnId: string,
  qty: number
) => {
  return p((cart) => {
    if (qty < 1) return

    const index = cart.items.findIndex((el) => el.id === item.id)
    const addOn = cart.items[index].orderedProductAddOns?.find(
      (el) => el.productAddOns.id === addOnId
    )

    if (addOn) {
      addOn.quantity = qty
    }
  })
}

export const updateItemNotes = (item: CartItem, notes: string) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item)
    if (!itemInCart) return

    itemInCart.notes = notes
  })
}

export const updateItemDetails = (item: ServiceCartItem, details: any) => {
  return p((cart) => {
    const itemInCart = findItemByCartItemId(cart, item) as ServiceCartItem
    if (!itemInCart) return

    itemInCart.details = details
  })
}

export const addItemToCart = (
  originalItem: CartItem,
  useWholeSalePrice?: boolean,
  customSellingPrice?: number,
  manualDiscount?: number
) => {
  return p((cart) => {
    const item = { ...originalItem }
    item.sellingPrice =
      originalItem.productLocation.sellingPrice ??
      originalItem.product.sellingPrice

    if (useWholeSalePrice && originalItem.productLocation.wholesalePrice) {
      item.sellingPrice = originalItem.productLocation.wholesalePrice
    }
    if (customSellingPrice) {
      item.sellingPrice = customSellingPrice
      item.isCustomPrice = true
    }
    if (manualDiscount) {
      item.discount = manualDiscount
      item.discountType = item.discountType ?? "PERCENTAGE"
    }
    if (customSellingPrice || manualDiscount) {
      const subtotal = item.sellingPrice * item.qty
      const deposit =
        item.productLocation.depositUnitOfMeasurement === "NUMBER"
          ? (item.productLocation?.deposit || 0) * item.qty
          : ((item.sellingPrice * (item.productLocation?.deposit || 0)) / 100) *
            item.qty

      item.deposit = item.partialPayment ? deposit : 0
      item.outstanding = item.partialPayment ? subtotal - deposit : 0
    }

    // if (originalItem.product.taxIncluded) {
    //   item.sellingPrice =
    //     item.sellingPrice - (item.sellingPrice * (taxRate ?? 0)) / 100
    // }
    if (!isLocationIdentical(cart.items, item.location)) {
      cart.items = [item]
      return
    }

    if (item.type === "SERVICE") {
      cart.items.push(item)
      return
    }

    const itemInCart = findItemByProductLocationId(cart, item)
    if (
      itemInCart === undefined ||
      (item.orderedProductAddOns &&
        JSON.stringify(itemInCart.orderedProductAddOns) !==
          JSON.stringify(item.orderedProductAddOns))
    ) {
      cart.items.push(item)
      return
    }

    // const itemInCart = findItemByProductId(cart, item)
    // if (itemInCart === undefined) {
    //   cart.items.push(item)
    //   return
    // }

    itemInCart.qty += item.qty
  })
}

export const sync = (
  products: GetProductsResponse,
  useWholesalePrice: boolean
) => {
  return p((cart) => {
    cart.items.forEach((item) => {
      if (item.isCustomPrice) return

      const product = products.find((el) => el.id === item.product.id)
      if (product) {
        const pl = product?.productLocation?.find(
          (l) =>
            l.locationId === item.location &&
            (item.productVariant === l.productVariantId || !item.productVariant)
        )
        if (!pl) return

        item.sellingPrice = pl.sellingPrice ?? product.sellingPrice
        if (useWholesalePrice && pl.wholesalePrice) {
          item.sellingPrice = pl.wholesalePrice
        }
        item.product = {
          ...item.product,
          ...(product as any),
        }
      }
    })
  })
}

export const cartItemCountSelector = ({ items }: Cart) => {
  return items
    .reduce((acc, item) => acc.plus(item.qty), Big(0))
    .round(2)
    .toNumber()
}

export const cartTotalAmountSelector = ({ items }: Cart) => {
  return items.reduce(
    (acc, item) =>
      item.product.taxIncluded == true
        ? acc +
          item.sellingPrice -
          ((item.sellingPrice *
            parseFloat(localStorage.getItem("mposSettingTax") ?? "0")) /
            100) *
            item.qty *
            (item.type === "SERVICE" ? item.details.slots.length : 1)
        : acc +
          item.sellingPrice *
            item.qty *
            (item.type === "SERVICE" ? item.details.slots.length : 1),
    0
  )
  // return items.reduce(
  //   (acc, item) =>
  // acc +
  // item.sellingPrice *
  //   item.qty *
  //   (item.type === "SERVICE" ? item.details.slots.length : 1),
  //   0
  // )
}

export const setPromotion = (
  promotion: Promotion | null,
  metadata?: PromotionMetadata
) => {
  return p((cart) => {
    cart.promotion = promotion
    if (promotion && metadata) {
      cart.promotionMetadata = metadata
    } else {
      cart.promotionMetadata = undefined
    }
  })
}

export const discountAmountSelector = (cart: Cart) => {
  const { promotion } = cart
  if (!promotion) return 0

  if (promotion.discountFixedAmount > 0) {
    return promotion.discountFixedAmount
  }

  if (promotion.discountPercentage > 0) {
    const totalAmount = cartTotalAmountSelector(cart)
    return (promotion.discountPercentage * totalAmount) / 100
  }

  return 0
}

export const cartSummarySelector = (cart: Cart) => {
  const totalCartAmount = cartTotalAmountSelector(cart)
  const discount = discountAmountSelector(cart)
  const isDiscountMoreThanTotal = discount > totalCartAmount

  const totalPriceAfterDiscount = isDiscountMoreThanTotal
    ? 0
    : totalCartAmount - discount

  return {
    discount: isDiscountMoreThanTotal ? totalCartAmount : discount,
    totalPriceAfterDiscount,
    totalCartAmount,
    totalItemQuantity: cartItemCountSelector(cart),
  }
}

export const cartTypeSelector = (cart: Cart): PRODUCT_TYPE_STRING | "EMPTY" => {
  // return cart.items?.[0]?.type ?? "EMPTY"
  return cart.items?.[0]?.product != undefined &&
    cart.items?.[0]?.product.productType != undefined
    ? cart.items?.[0]?.product.productType
    : (cart.items?.[0]?.type ?? "EMPTY")
}

export const createCartTransaction = async ({
  cart,
  customer,
  businessId,
  paymentMethodCode,
  dueDate,
  tax,
  serviceCharge,
  productPromotion,
  discount,
  totalOutstanding,
  createdBy,
  totalCartAmount,
  notes,
  isWalkIn,
  isOpenOrder,
  customerName,
  cashReceived,
  cashChange,
  cashRegisterId,
  taxes = [],
  paymentChannel,
  reward,
  cashback,
  isB2B,
  customerLocationId,
  paymentTerm,
  priceList,
  wholesale,
  taxExemptions,
  isPickup,
  pickupTime,
  tables,
  autoPrint,
  posType,
  locationId,
}: {
  cart: Cart
  customer: Customer | undefined
  businessId: string
  paymentMethodCode: string
  dueDate: Date | null
  tax: number
  serviceCharge: number
  productPromotion: Record<string, { discountAmount: number }>
  discount: number
  totalOutstanding: number
  createdBy: string
  totalCartAmount: number
  notes?: any
  isWalkIn?: boolean
  isOpenOrder?: boolean
  customerName?: string
  cashReceived?: number
  cashChange?: number
  cashRegisterId?: string
  taxes: PostCheckoutResponseBody["taxes"]
  paymentChannel?: string
  reward?: Reward | null
  cashback?: number
  isB2B?: boolean
  customerLocationId?: string
  paymentTerm?: PaymentTerm
  priceList?: string
  wholesale?: boolean
  taxExemptions?: Array<{
    id: string
    amount: number
    name: string
    type: string
  }>
  isPickup?: boolean
  pickupTime?: Date | null
  tables?: any[]
  autoPrint?: boolean
  posType?: (typeof POS_TYPE)[number]["key"]
  locationId?: string
}): Promise<
  | {
      orderNumber: any
      orderId: any
      reqId: string
      transaction: CartTransactionDTO
    }
  | undefined
> => {
  const uuid = uuidv4()
  const orderType = cartTypeSelector(cart)
  const { promotion, items } = cart
  const { totalItemQuantity } = cartSummarySelector(cart)

  const location = items[0] ? items[0].location : locationId

  const products = await Promise.all(
    items.map(async (item) => {
      const customProductDiscount = calculateItemDiscountAmount(item)
      const itemCopy: any = { ...item }
      const p = {
        id: item.id,
        product: item.product.id,
        sellingPrice: item.sellingPrice,
        quantity:
          item.type === "SERVICE"
            ? item.details.slots.length * item.qty
            : item.qty,
        productVariant: item.product.productVariant || item.productVariant,
        orderDate: new Date(),
        discount:
          productPromotion?.[item.product.id]?.discountAmount ??
          customProductDiscount ??
          0,
        discountType: customProductDiscount ? item.discountType : null,
        appointmentId: undefined,
        outstanding: item.outstanding,
        taxIncluded: item.product.taxIncluded,
        notes: item.notes,
        employees: item.employees
          ? uniq(item.employees?.map((el) => ({ id: el.employee })))
          : itemCopy.details?.multiEmployee
            ? itemCopy.details.bundleEmployees.map((el: any) => ({
                id: el.employee,
              }))
            : itemCopy?.details?.employee
              ? [{ id: itemCopy.details.employee }]
              : [],
        orderedProductDetail: !!item.employees?.length
          ? { employeesBundleItems: item.employees }
          : undefined,
        orderedProductAddOns:
          item.orderedProductAddOns?.map((el) => {
            return {
              productAddOns: el.productAddOns.id,
              quantity:
                item.type === "SERVICE"
                  ? item.details.slots.length * item.qty * el.quantity
                  : el.quantity,
            }
          }) || undefined,
      }
      if (item.type === "SERVICE") {
        p.appointmentId = await createServiceAppointment(
          customer,
          item,
          businessId,
          paymentMethodCode,
          item.details.slots
        )
      }
      return p
    })
  )

  let transaction = {
    products,
    tax,
    serviceCharge,
    discount,
    totalCartAmount,
    totalItemQuantity,
    cashbookTransactionId: uuid,
    creditbookTransactionId: uuid,
    buxRequestId: uuid,
    customer: isWalkIn == true ? undefined : customer?.id,
    buxPaymentStatus: isOpenOrder
      ? "PENDING"
      : paymentMethodCode === "CASH" || paymentMethodCode === "CUSTOM"
        ? "PAID"
        : "PENDING",
    orderStatus: isOpenOrder ? "PENDING_OPEN_ORDER" : "PENDING",
    customerName: customerName,
    shippingCost: Big(cart.deliveryDetails?.deliveryFee || 0)
      .add(cart.deliveryDetails?.otherFees || 0)
      .round(2)
      .toNumber(),
    paymentMethodCode: paymentMethodCode,
    isMpos: true,
    isServiceOrder: orderType === "SERVICE",
    // promotions: promotion
    //   ? [
    //       {
    //         promotionName: promotion.name,
    //         ...promotion,
    //       },
    //     ]
    //   : undefined,
    promotions: promotion ? [promotion?.id] : undefined,
    promotionMetadata: cart.promotionMetadata,
    dueDate: paymentMethodCode === "PAY LATER" ? dueDate : undefined,
    business: businessId,
    locationId: location,
    outstanding: totalOutstanding,
    notes: notes || "",
    isWalkIn: isWalkIn || false,
    createdBy: createdBy,
    cashReceived: cashReceived,
    cashChange: cashChange,
    cashRegister: cashRegisterId,
    taxes,
    source: "web",
    paymentChannel:
      (paymentMethodCode === "EWALLET" || paymentMethodCode === "CUSTOM") &&
      paymentChannel
        ? paymentChannel
        : undefined,
    reward,
    cashback,
    isB2B,
    customerLocationId,
    paymentTerm,
    priceList: priceList ? priceList : undefined,
    wholesale: wholesale,
    taxExemptions: taxExemptions?.map((el) => ({
      ...el,
      id: undefined,
      tax: el.id,
    })),
    isPickup,
    pickupTime,
    tables: tables || [],
    autoPrintEnabled: autoPrint,
    orderType: posType,
    shipment: cart.deliveryDetails
      ? {
          shipmentType: "POS_MANUAL",
          provider: null,
          customerLocation: cart.deliveryDetails.customerLocationId,
          quoteDetails: cart.deliveryDetails,
        }
      : undefined,
    queueNumber: cart.queueNumber || undefined,
    localTransactionId: cart.localTransactionId,
    paymentId: cart.paymentId || undefined,
    referralCode: cart.referralCode !== "" ? cart.referralCode : undefined,
  }

  const { data, status } = await postAPI<{
    statusCode: number
    message: string
    data: {
      id: string
      isMpos: boolean
      isServiceOrder: boolean
      promotions: any
      buxPaymentStatus: string
      orderStatus: string
      totalCartAmount: number
      discount: number
      cashReceived: number
      cashChange: number
      totalItemQuantity: number
      orderNumber: string
      tax: number
      shippingCost: number
      paymentMethodName: string
      paymentMethodCode: string
      buxRequestId: string
      pickupTime: any
      serviceCharge: number
      createdAt: string
      updatedAt: string
      createdBy: string
      updatedBy: any
      dueDate: any
      grandTotal: number
      orderedProducts: Array<{
        id: string
        deliveryDetails: Record<string, number>
        totalPrice: number
        sellingPrice: number
        quantity: number
        quantityReceived: number
        discount: number
        orderDate: string
        createdAt: string
        updatedAt: string
      }>
      location: {
        birEnabled: boolean
      }
      receiptHistory: Array<{
        id: string
        seriesNumber: string
        ORNumber: string
        type: string
        createdAt: string
        updatedAt: string
        printed: boolean
      }>
    }
  }>("/cartTransaction", transaction)
  if (status !== 201) return

  if (orderType === "SERVICE") {
    sessionStorage.setItem(
      "transaction",
      JSON.stringify({
        id: data.data.id,
        businessId,
        appointmentIds: products.map((p: any) => p.appointmentId),
      })
    )
  }

  return {
    orderId: data.data.id,
    orderNumber: data.data.orderNumber,
    reqId: uuid,
    transaction: {
      ...transaction,
      id: data.data.id,
      orderNumber: data.data.orderNumber,
      location: data.data.location,
      receiptHistory: data.data.receiptHistory,
      products: transaction.products.map((p) => {
        const originalItem = cart.items.find((i) => i.id === p.id)
        return {
          ...p,
          sellingPrice: originalItem?.sellingPrice,
          name: originalItem?.product.name,
        }
      }),
    } as any,
  }
}

export const updateOrderNotes = (id: string, notes: string) => {
  return patchAPI(`/cartTransaction/notes`, id, { notes })
}

export const setReferralCode = (referralCode: string) => {
  return p((cart) => {
    cart.referralCode = referralCode
  })
}

// ====================================
// Some utility functions
// ===================================
const p = (fn: (cart: Cart) => void) => immerProduce(fn)

const isTypeIdentical = (
  items: CartItem[],
  type: "GOODS" | "SERVICE" | "DIGITAL"
) => items.every((i) => i.type === type)

const isLocationIdentical = (items: CartItem[], location: string) =>
  items.every((i) => i.location === location)

const isDetailsIdentical = (
  a: ServiceCartItem["details"],
  b: ServiceCartItem["details"]
) =>
  a.startTime === b.startTime &&
  a.startDate?.getTime() === b.startDate?.getTime() &&
  a.location === b.location &&
  a.duration === b.duration &&
  a.employee === b.employee

const isServiceIdentical = (a: CartItem, b: CartItem) =>
  a.type === "SERVICE" &&
  b.type === "SERVICE" &&
  isDetailsIdentical(a.details, b.details)

const isItemIdentical = (a: CartItem, b: CartItem) => {
  const isSameProduct = a.product.id === b.product.id
  if (a.type === "SERVICE" && b.type === "SERVICE") {
    return isSameProduct && isServiceIdentical(a, b)
  }

  return isSameProduct
}

const findItemByProductLocationId = (cart: Cart, item: CartItem) => {
  if (item.productVariant != undefined && item.productVariant.length > 0) {
    return cart.items.find(
      (i) =>
        i.productLocation.locationId === item.productLocation.locationId &&
        i.product.id === item.product.id &&
        i.productVariant === item.productVariant
    )
  } else {
    return cart.items.find(
      (i) =>
        i.productLocation.locationId === item.productLocation.locationId &&
        i.product.id === item.product.id
    )
  }
}

const findItemByProductId = (cart: Cart, item: CartItem) =>
  cart.items.find((i) => i.product.id === item.product.id)

const findItemByCartItemId = (cart: Cart, item: CartItem) =>
  cart.items.find((i) => i.id === item.id)

const computeDeposit = (itemInCart: CartItem) => {
  if (!itemInCart.productLocation.allowPartialPayment) return
  if (
    !itemInCart.productLocation.deposit ||
    !itemInCart.productLocation.depositUnitOfMeasurement
  )
    return
  console.log("qty>>", itemInCart.qty)
  const subtotal = itemInCart.sellingPrice * itemInCart.qty
  const deposit =
    itemInCart.productLocation.depositUnitOfMeasurement === "NUMBER"
      ? itemInCart.productLocation.deposit * itemInCart.qty
      : ((itemInCart.sellingPrice * itemInCart.productLocation.deposit) / 100) *
        itemInCart.qty

  itemInCart.deposit = itemInCart.partialPayment ? deposit : 0
  itemInCart.outstanding = itemInCart.partialPayment ? subtotal - deposit : 0
}

export const cartCheckoutWithPayPal = async (
  transaction: CartTransactionDTO
) => {
  return postAPI<{ orderId: string; redirectUrl: string }>(
    `/cartTransaction/paypal-checkout`,
    transaction
  )
}

export const cartCapturePaypalOrder = (orderId: string) => {
  return postAPI(`/cartTransaction/paypal-capture/${orderId}`)
}

export const cartCheckoutWithIpay = async (
  transaction: CartTransactionDTO & {
    successPage: string
    errorPage: string
    paymentDetails: {
      name?: string
      email?: string
      phone?: string
      location?: {
        city?: string
        state?: string
        country?: string
        zip?: string
        addressLine1?: string
        addressLine2?: string
      }
    }
  }
) => {
  return postAPI<{ name: string }>(
    `/cartTransaction/ipay-checkout`,
    transaction
  )
}

export const cartCheckoutWithIpayQR = async (
  transaction: CartTransactionDTO & {
    successPage: string
    errorPage: string
    paymentId?: string
    paymentDetails: {
      name?: string
      email?: string
      phone?: string
      location?: {
        city?: string
        state?: string
        country?: string
        zip?: string
        addressLine1?: string
        addressLine2?: string
      }
    }
  }
) => {
  const result = await postAPI<ArrayBufferLike>(
    `/cartTransaction/ipay-qr-checkout`,
    transaction,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(result.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${result.headers["content-type"].toLowerCase()};base64,${image}`
}
export const cartCheckoutWithBuxQR = async (
  transaction: CartTransactionDTO & {
    returnUrl: string
  }
) => {
  const response = await postAPI<ArrayBufferLike>(
    `/cartTransaction/bux-qr-checkout`,
    transaction,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const cartCheckoutWithFlutterwaveQR = async (body: {
  amount?: number
  currency?: string
  customerId?: string
  redirectUrl?: string
  orderId?: string
  businessId?: string
}) => {
  const response = await postAPI<ArrayBufferLike>(
    `/flutterwave/flw-cart-checkout-qrcode`,
    body,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const cartCheckoutWithRazerQR = async (body: {
  amount?: string
  currency?: string
  customerId?: string
  redirectUrl?: string
  orderId?: string
  businessId?: string
}) => {
  const response = await postAPI<ArrayBufferLike>(
    `/razer-payment/razer-cart-checkout-qrcode`,
    body,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const cartCheckoutWithDPOQR = async (body: {
  amount?: number
  currency?: string
  customerId?: string
  redirectUrl?: string
  orderId?: string
  businessId?: string
}) => {
  const response = await postAPI<ArrayBufferLike>(
    `/dpo-payment/cart-checkout-qrcode`,
    body,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const cartCheckoutWithCashfreeQR = async (body: {
  orderId?: string
  amount?: number
  currency?: string
  customerId?: string
  redirectUrl?: string
  businessId?: string
}) => {
  const response = await postAPI<ArrayBufferLike>(
    `/cartTransaction/cashfree-qr-checkout`,
    body,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const cartCheckoutWithStripeQR = async (body: {
  amount?: number
  currency?: string
  customerId?: string
  orderId?: string
  businessId?: string
  redirectSuccessUrl?: string
  redirectRefreshUrl?: string
}) => {
  const response = await postAPI<ArrayBufferLike>(
    `/stripe-account/stripe-cart-checkout-qrcode`,
    body,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const cartCheckoutWithIpayAfricaPOS = async (
  transaction: CartTransactionDTO & {
    pushMethod: "mpesa" | "airtel" | "mtn"
    customerEmail: string
    customerPhone: string
    successPage: string
    errorPage: string
  }
) => {
  return await postAPI<{
    header_status: number
    status: number
    text: string
    sid: string
    orderId: string
  }>(`/cartTransaction/ipay-africa-pos-checkout`, transaction)
}

export const cartCheckoutWithIpayAfricaQR = async (
  transaction: CartTransactionDTO & {
    customerEmail: string
    customerPhone: string
    successPage: string
    errorPage: string
  }
) => {
  const response = await postAPI<ArrayBufferLike>(
    `/cartTransaction/ipay-africa-qr-checkout`,
    transaction,
    { responseType: "arraybuffer" }
  )

  let image = window.btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  )
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`
}

export const checkIpayAfricaPOSTransaction = async (body: {
  oid: string
  sid: string
  business: string
}) => {
  return await postAPI<{
    code: number
    message: string
    status: string
  }>(`/cartTransaction/ipay-africa-pos-checkout/check`, body)
}

export type CartTransactionDTO = {
  id: string
  orderNumber: string | number
  paymentMethodCode: string
  totalCartAmount: number
  shippingCost: number
  business: string
  cashbookTransactionId?: string
  buxRequestId: string
  dueDate?: Date | null | undefined | string
  buxPaymentStatus: string
  discount: number
  orderStatus: string
  tax: number
  creditbookTransactionId?: string
  products: Array<{
    product: string
    sellingPrice: number
    quantity: number
    totalPrice: number
    appointmentId?: undefined
    discount: number
    orderDate: Date | string
  }>
  isServiceOrder: boolean
  serviceCharge: number
  promotions: string[] | undefined
  locationId: string
  isMpos: boolean
  totalItemQuantity: number
  customer: string | undefined
}

// TODO: backend currently doesn't need all of CartTransactionDTO,
//  consider making it simpler.
export type IpayQRCheckoutDTO = CartTransactionDTO & {
  successPage: string
  errorPage: string
  paymentId?: string
  paymentDetails: {
    name?: string
    email?: string
    phone?: string
    location?: {
      city?: string
      state?: string
      country?: string
      zip?: string
      addressLine1?: string
      addressLine2?: string
    }
  }
}

export const calculateItemDiscountAmount = (
  item: {
    discount?: number
    discountType?: string
    sellingPrice: number
    qty?: number
  },
  overrideSellingPrice?: number
) => {
  if (item.discountType === "PRICE") return item.discount ?? 0

  return item.discount
    ? Big(overrideSellingPrice ?? item.sellingPrice)
        .times(item.qty || 1)
        .times(item.discount)
        .div(100)
        .round(2)
        .toNumber()
    : 0
}

export const calculateItemSubTotal = (
  item: CartItem,
  overrideSellingPrice?: number
) => {
  const sellingPrice = overrideSellingPrice ?? item.sellingPrice
  let price = Big(sellingPrice)

  let totalAddons = Big(0)
  if (item.orderedProductAddOns) {
    totalAddons = item.orderedProductAddOns.reduce(
      (sum, el) =>
        sum.plus(Big(el.productAddOns.sellingPrice).mul(el.quantity)),
      Big(0)
    )
  }

  let totalPrice = price.times(item.qty || 0).add(totalAddons)

  // if (!item.product.taxIncluded) {
  totalPrice = totalPrice.minus(
    calculateItemDiscountAmount(item, overrideSellingPrice)
  )
  // }

  return totalPrice.round(2).toNumber()
}

export const updateProposeOrderChange = async (
  id: string,
  proposedOrderChange: any
) => {
  return await postAPI(`/cartTransaction/propose-order-change/${id}`, null)
}
