import { deleteAPI, getPlainStringAPI, patchAPI, postAPI } from "./api"
import axios from "axios"
import {
  DepositUnit,
  Ingredient,
  IngredientsLocation,
  Product,
  PRODUCT_TYPE,
  PRODUCT_TYPE_STRING,
  ProductAddOnsGroup,
  ProductDelivery,
} from "./models"
import { v4 } from "uuid"
import { format } from "date-fns"
import { getCategoryByInventoryId } from "./inventory-category"
import { combine, devtools } from "zustand/middleware"
import produce from "immer"
import { nanoid } from "nanoid"
import { t } from "i18next"
import { uniq } from "lodash"
import Big from "big.js"
import { GetProductsResponse } from "../hooks/api/use-products"
import { StockTransfer } from "../hooks/api/use-stock-transfers"

export const createProductImageUploadURL = async (
  productType: string,
  ext = "jpg"
) => {
  return postAPI<{
    key: string
    url: string
    uploadURL: string
  }>(`/products/upload-link?ext=${ext}&productType=${productType}`)
}

export const createCategoryImageUploadURL = async (
  productType: string,
  ext = "jpg"
) => {
  return postAPI<{
    key: string
    url: string
    uploadURL: string
  }>(`/product-category/upload-link?ext=${ext}`)
}

export const uploadCategoryFile = async (file: File) => {
  const uploadURL = await createCategoryImageUploadURL(
    file.name.split(".").pop() || "jpg"
  )

  const result = await axios.put<{ url: string }>(
    uploadURL.data.uploadURL,
    file,
    {
      headers: { "Content-Type": file.type },
    }
  )

  if (result.status !== 200) {
    throw new Error("Upload failed")
  }

  return {
    url: uploadURL.data.url,
    key: uploadURL.data.key,
  }
}

export const uploadProductFile = async (file: File, productType: string) => {
  const uploadURL = await createProductImageUploadURL(
    productType,
    file.name.split(".").pop() || "jpg"
  )

  const result = await axios.put<{ url: string }>(
    uploadURL.data.uploadURL,
    file,
    {
      headers: { "Content-Type": file.type },
    }
  )

  if (result.status !== 200) {
    throw new Error("Upload failed")
  }

  return {
    url: uploadURL.data.url,
    key: uploadURL.data.key,
  }
}

export const postNewProduct = async (
  { productLocation, ...product }: Product,
  masterProductId?: string
) => {
  var category
  if (product.inventory && product.category === "") {
    category = await getCategoryByInventoryId(product.inventory)
    if (!category) return null
  }

  var bundleItems =
    product.bundleItems
      ?.filter((i) => i.id)
      .map((i) => ({
        productId: i.id,
        qty: i.quantity,
        variant: i.variant,
      })) ?? []

  const locations =
    productLocation
      ?.filter((l) => l.locationId)
      ?.map((l) => ({
        ...l,
        id: l.locationId,
        stock: l.beginningStock,
        receivedDate: l.receivedDate || undefined,
        expirationDate: l.expirationDate || undefined,
        sellingPriceCurrency: product.sellingPriceCurrency,
        unitOfMeasure: product.unitOfMeasure,
      })) ?? []

  if (!locations || locations.length === 0) return null

  // if (product.isBundle && bundleItems.length < 2) return null
  if (product.productVariants && product.productVariants.length > 0) {
    product.productVariants = product.productVariants.map((v: any) => {
      return {
        ...v,
        locations: v.locations.map((p: any) => ({
          ...p,
          expirationDate: p.expirationDate || undefined,
        })),
      }
    })
  }

  return await postAPI<GetProductsResponse[number]>("/products", {
    ...product,
    locations,
    sellingPrice: locations[0].sellingPrice,
    expirationDate: product.expirationDate ? product.expirationDate : undefined,
    id: v4(),
    masterProduct: masterProductId,
    bundleItems: product.isBundle ? bundleItems : undefined,
    category: category ? category.id : product.category,
  })
}

export const patchProduct = async (product: Product) => {
  var bundleItems: any = []
  if (product.bundleItems) {
    product.bundleItems?.map((el) => {
      if (el.id !== "")
        bundleItems.push({
          productId: el.id,
          qty: el.quantity,
          variant: el.variant,
        })
    })
  }

  var productLocation: any = []
  if (product.productLocation) {
    product.productLocation?.map((el: any) => {
      if (el.locationId !== "")
        productLocation.push({
          ...el,
          id: el.locationId,
          stock: el.beginningStock,
          sellingPriceCurrency: product.sellingPriceCurrency,
          unitOfMeasure: product.unitOfMeasure,
          expirationDate: el.expirationDate ? el.expirationDate : undefined,
        })
    })
  }

  if (product.productVariants && product.productVariants.length > 0) {
    product.productVariants = product.productVariants.map((v: any) => {
      return {
        ...v,
        reorderQuantity: v.reorderQuantity || 0,
        locations: v.locations?.map((p: any) => ({
          ...p,
          expirationDate: p.expirationDate || undefined,
        })),
      }
    })
  }

  // to do
  let payload = {
    ...product,
    name: product.name ? product.name : undefined,
    SKU: product.SKU ? product.SKU : undefined,
    // barcode: model.qrCode  ? model.qrCode : undefined,
    description: typeof product.description == "string" ? product.description : undefined,
    productType: product.productType ? product.productType : undefined,
    imageUrl: product.imageUrl ? product.imageUrl : undefined,
    expirationDate: product.expirationDate ? product.expirationDate : undefined,
    receivedDate: product.receivedDate
      ? product.receivedDate
      : format(new Date(), "yyyy-MM-dd"),
    supplier: product.supplierId ? product.supplierId : undefined,
    inventory: product.inventory ? product.inventory : undefined,
    category: product.category ? product.category : undefined,
    // status: model.status  ? model.status : "ACTIVE",
    sellingPrice: product.sellingPrice ? product.sellingPrice : 0,
    sellingPriceCurrency: product.sellingPriceCurrency
      ? product.sellingPriceCurrency
      : undefined,
    unitOfMeasure: product.unitOfMeasure ? product.unitOfMeasure : undefined,
    MRPCurrency: product.MRPCurrency
      ? product.MRPCurrency
      : product.sellingPriceCurrency,
    MRP: product.MRP ? product.MRP : 0,
    beginningStock: product.beginningStock ? product.beginningStock : 0,
    stock: 0,
    reorderQuantity: product.reorderQuantity ? product.reorderQuantity : 0, // for stock alert
    serviceDuration: product.serviceDuration ? product.serviceDuration : 0,
    // masterProductId: model.masterProductId  ? model.masterProductId : undefined,
    isVisible: product.isVisible ?? true,
    isVisibleOnEstore: product.isVisibleOnEstore ?? true,
    // itemStatus: model.itemStatus,
    autoGenerateSKU: product.autoGenerateSKU,
    isReturnable: product.isReturnable ?? false,
    locations: product.applyToAllLocation ? undefined : productLocation,
    applyToAllLocation: product.applyToAllLocation,
    isBundle: product.isBundle,
    bundleItems: product.isBundle ? bundleItems : undefined,
    weight: product.weight,
    height: product.height,
    width: product.width,
    length: product.length,
    productToSupplier: product.productToSupplier,
  }

  return patchAPI<GetProductsResponse[number]>("/products", product.id, payload)
}

export const patchLocationsOfProduct = (
  product: GetProductsResponse[number],
  locations: Product["productLocation"]
) => {
  const noneVariantLocations = locations
    ?.filter((l) => !l.productVariantId)
    ?.map((l) => ({
      ...l,
      id: l.locationId,
    }))

  const productVariants = product.productVariants?.map((variant) => {
    const variantLocations =
      variant.productLocations
        ?.filter((l) => l.productVariantId === variant.id)
        .map((p) => ({ ...p, id: p.locationId })) ?? []

    const newLocation = locations?.find(
      (l) => !variantLocations.find((vl) => vl.id === l.locationId)
    )

    if (!newLocation) {
      return {
        ...variant,
        locations: variantLocations,
      }
    }

    return {
      ...variant,
      locations: [
        ...variantLocations,
        {
          ...newLocation,
          productVariantId: variant.id,
          id: newLocation.locationId,
        },
      ],
    }
  })

  return patchAPI<{
    id: string
    name: string
    description: string
    price: number
    imageURL: string
  }>("/products", product.id, {
    ...product,
    locations: noneVariantLocations ?? [],
    productVariants: productVariants ?? [],
  })
}

export type PostProductDeliveryResponse = {
  name: string
  MRP: number
  MRPCurrency: string
  sellingPrice: number
  sellingPriceCurrency: string
  unitOfMeasure: string
  initialStock: number
  sold: number
  stockLost: number
  stockReceived: number
  stockReturned: number
  stockDamaged: number
  stockInHand: number
  isUsedPrice: boolean
  receivedDate: string
  isVisible: boolean
  isVisibleOnEstore: boolean
  stockConsumed: number
  updatedProductVariant: {
    id: string
  }
  updatedProduct: {
    id: string
    name: string
    SKU: string
    isReturnable: boolean
    barcode: string
    qrcode: any
    description: string
    productType: string
    productTypeStatus: any
    paymentOptions: any
    MRP: number
    MRPCurrency: string
    sellingPrice: number
    wholesalePrice: number
    sellingPriceCurrency: string
    unitOfMeasure: string
    unitOfMeasureValue: number
    purchaseUnitOfMeasure: any
    conversionNumber: number
    minimumQuantity: number
    recommendedQuantity: number
    stock: number
    beginningStock: number
    status: string
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    imageUrl: any
    images: any
    expirationDate: string
    receivedDate: string
    isVisible: boolean
    autoGenerateSKU: boolean
    salesReturned: number
    isOrderEnabled: boolean
    isVisibleOnEstore: boolean
    isBundle: boolean
    weight: number
    width: number
    height: number
    length: number
    attachment: any
    serviceType: any
    serviceDuration: any
    serviceSlotTime: any
    gapBetweenAppointmentTime: number
    maxCustomerPerSlot: number
    reminderTime: any
    totalQuantity: number
    termsCondition: any
    isAvailable: boolean
    availableServiceDate: any
    blockServiceDate: any
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: any
    deposit: number
    depositUnitOfMeasurement: string
    allowPartialPayment: boolean
    nonInventory: boolean
    isService: boolean
    priceType: string
    taxable: boolean
    taxIncluded: boolean
    serviceDurationUnit: string
    hasAddOns: boolean
    icon: any
    color: any
    shape: any
    halal: boolean
  }
  business: string
  location: string
  poInvoiceId: any
  description: any
  status: any
  salesReturned: number
  expirationDate: any
  id: string
  createdAt: string
  updatedAt: string
  updatedProductLocation: {
    productId: string
    locationId: string
    productVariantId: string
    sellingPrice: number
    sellingPriceCurrency: string
    unitOfMeasure: string
    unitOfMeasureValue: number
    stock: number
    beginningStock: number
    status: string
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    expirationDate: string
    receivedDate: string
    serviceDuration: any
    isAvailable: boolean
    availableServiceDate: any
    blockServiceDate: any
    totalQuantity: any
    reminderTime: any
    maxCustomerPerSlot: any
    serviceSlotTime: any
    gapBetweenAppointmentTime: any
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: any
    isVisible: boolean
    salesReturned: number
    isOrderEnabled: boolean
    isVisibleOnEstore: boolean
    deposit: number
    depositUnitOfMeasurement: string
    allowPartialPayment: boolean
    termsCondition: any
    nonInventory: boolean
    MRP: number
    MRPCurrency: string
    wholesalePrice: number
  }
}

export const postNewProductDelivery = (
  data: {
    name?: string
    supplier?: string
    description?: string
    MRP: number
    MRPCurrency?: string
    sellingPrice: number
    sellingPriceCurrency: string
    unitOfMeasure?: string
    initialStock: number
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    expirationDate?: string
    receivedDate?: string
    isVisible: boolean
    isVisibleOnEstore: boolean
    product: string
    location?: string
    attachment?: string
    stockConsumed?: number
    productVariant?: string
    stockSpoiled?: number
  },
  action:
    | "STOCK_TAKE"
    | "PURCHASE_ORDER"
    | "ADJUST_STOCK"
    | "STOCK_RETURN" = "ADJUST_STOCK"
) => {
  return postAPI<PostProductDeliveryResponse>(
    `/product-delivery`,
    {
      ...data,
      isUsedPrice: true,
      name: data.name || "Delivery 1",
    },
    {
      params: { action },
    }
  )
}

export const patchProductDelivery = (
  id: string,
  data: Partial<ProductDelivery>
) => {
  return patchAPI(`/product-delivery`, id, {
    ...data,
    product: data.product ? data.product.id : undefined,
    supplier: data.supplier ? data.supplier.id : undefined,
    isUsedPrice: true,
    name: data.name || "Delivery 1",
    location: data.location?.id || undefined,
    productVariant: data.productVariant ? data.productVariant : undefined,
  })
}

export const deleteProduct = (id: string, locationId: string) => {
  return deleteAPI("/products", id, locationId)
}

export const generateSKU = (categoryId: string) => {
  return getPlainStringAPI<number>(`/products/sku/${categoryId}`)
}

const generateEmptyProductLocation = ({
  sellingPriceCurrency,
  locationId,
  productVariantId,
  nonInventory,
  sellingPrice,
  MRP,
  wholesalePrice,
  allowManualCost,
}: {
  sellingPriceCurrency?: string
  locationId?: string
  productVariantId?: string
  nonInventory?: boolean
  sellingPrice?: number
  MRP?: number
  wholesalePrice?: number
  allowManualCost?: boolean
}): {
  beginningStock: number
  sellingPrice: number
  locationId: string
  isVisibleOnEstore?: boolean
  created_at?: string
  stockInHand?: number
  sellingPriceCurrency: string
  tempId: string
  isVisible?: boolean
  receivedDate?: string
  expirationDate?: string
  totalQuantity?: number
  deposit?: number
  depositUnitOfMeasurement?: DepositUnit
  allowPartialPayment?: boolean
  productVariantId?: string
  nonInventory?: boolean
  allowManualCost?: boolean
  isReturnable?: boolean
  MRP?: number
  wholesalePrice?: number
  reorderQuantity?: number
} => ({
  tempId: nanoid(),
  locationId: locationId ?? "",
  stockInHand: 0 as number | undefined,
  beginningStock: 0,
  sellingPrice: sellingPrice ?? 0,
  isVisible: true,
  isVisibleOnEstore: true,
  receivedDate: format(new Date(), "yyyy-MM-dd"),
  sellingPriceCurrency: sellingPriceCurrency ?? "",
  created_at: undefined as undefined | string,
  totalQuantity: 0 as number | undefined,
  deposit: 0,
  depositUnitOfMeasurement: "NUMBER",
  allowPartialPayment: false,
  productVariantId,
  nonInventory: nonInventory || false,
  allowManualCost: allowManualCost ?? false,
  expirationDate: "",
  isReturnable: false,
  MRP: MRP ?? 0,
  wholesalePrice: wholesalePrice ?? 0,
  reorderQuantity: 0,
})

export interface ProductVariant {
  id: string
  variantValues: Array<{
    productOption: {
      id: string
    }
    productOptionValue: {
      id: string
      name?: string
    }
  }>
  MRP: number
  MRPCurrency: string
  sellingPriceCurrency: string
  sellingPrice: number
  beginningStock: number
  stockReceived: number
  stockInHand?: number
  SKU: string
  createdAt?: string
}

interface ProductOption {
  id: string
  name: string
  values: Array<{
    id: string
    name: string
  }>
}

const DEFAULT_PRODUCT_FORM_STATE = {
  id: "",
  name: "",
  SKU: "",
  description: "",
  productType: "GOODS" as PRODUCT_TYPE_STRING,
  stock: 0,
  unitOfMeasure: "Per Item",
  /** @deprecated supplierId replaced by productToSupplier to allow multiple suppliers*/
  supplierId: "",
  category: "",
  inventory: "",
  autoGenerateSKU: false,
  sellingPrice: 0,
  sellingPriceCurrency: "",
  beginningStock: 0,
  MRP: 0,
  MRPCurrency: "",
  reorderQuantity: 0,
  receivedDate: format(new Date(), "yyyy-MM-dd"),
  expirationDate: "",
  serviceDuration: 0,
  isVisible: true,
  isVisibleOnEstore: true,
  enableBIR: false,
  imageUrl: "",
  images: [
    {
      isPrimary: true, // ID for manipulating
      url: "",
    },
  ],
  applyToAllLocation: false,
  isBundle: false,
  bundleItems: [
    {
      tempId: nanoid(), // ID for manipulating
      id: "",
      quantity: 1,
      nonInventory: false,
      productLocation: [] as Array<{
        locationId: string
        MRP?: number
        stockInHand?: number
        productVariantId?: string
      }>,
      variant: undefined as
        | undefined
        | {
            id: string
            name: string
          },
    },
  ],
  productLocation: [generateEmptyProductLocation({})],
  locations: [""],
  weight: "",
  height: "",
  width: "",
  length: "",
  attachment: "",
  deposit: 0,
  depositUnitOfMeasurement: "NUMBER" as DepositUnit,
  allowPartialPayment: false,
  productOptions: [] as ProductOption[],
  productVariants: [] as ProductVariant[],
  nonInventory: false,
  allowManualCost: false, //for tracked inventory
  // isService flag is used for products that can be assigned to Projects
  // its unrelated to the SERVICE product type
  isService: false,
  unitOfMeasureValue: 1,
  ingredientsLocationList: [] as IngredientsLocation[],
  taxable: true,
  wholesalePrice: 0,
  taxIncluded: false,
  isReturnable: false,
  taxes: [] as string[],
  productAddOnsGroup: [] as ProductAddOnsGroup[],
  productToSupplier: [] as Array<{
    supplierId: string
    SKU: string
  }>,
  purchaseUnitOfMeasure: undefined as string | undefined,
  conversionNumber: 1,
  minimumQuantity: 0,
  recommendedQuantity: 0,
  icon: "image",
  color: "",
  shape: "",
  selectedLocationId: "",
  halal: false,
  useProduction: false,
  recipe: [] as Ingredient[],
  totalQuantity: 0,
}

export type ProductFormState = typeof DEFAULT_PRODUCT_FORM_STATE

export const productFormState = devtools(
  combine(DEFAULT_PRODUCT_FORM_STATE, (set, get) => ({
    clear: () =>
      set({
        ...DEFAULT_PRODUCT_FORM_STATE,
        productLocation: [generateEmptyProductLocation({})],
        bundleItems: [
          {
            tempId: nanoid(),
            id: "",
            quantity: 1,
            productLocation: [],
            nonInventory: false,
            variant: undefined,
          },
        ],
      }),
    setAutogenerateSKU: async (
      autoGenerateSKU: boolean,
      oldAutogenerateValue?: boolean,
      oldSKU: string = ""
    ) => {
      if (!autoGenerateSKU) {
        set({ autoGenerateSKU, SKU: oldAutogenerateValue ? "" : oldSKU })
        return
      } else if (autoGenerateSKU && oldAutogenerateValue) {
        set({ autoGenerateSKU, SKU: oldSKU })
        return
      }

      const { category } = get()
      if (category) {
        const SKU = await generateSKU(category)
        if (SKU.status === 200) {
          set({
            autoGenerateSKU: autoGenerateSKU,
            SKU: SKU.data.toString(),
          })
        }
      }
    },
    updateProduct: (product: Partial<ProductFormState>) => {
      set((state) => ({ ...state, ...product }))
    },
    addNewLocation: (locationId?: string) => {
      set(
        produce<ProductFormState>((state) => {
          // add base product location data
          const emptyProductLocation = generateEmptyProductLocation({
            sellingPriceCurrency: state.sellingPriceCurrency,
            locationId: locationId ?? "",
            nonInventory: state.nonInventory,
            allowManualCost: state.allowManualCost,
          })
          state.productLocation?.push(emptyProductLocation)

          // add variants location data
          if (state.productVariants.length > 0) {
            state.productVariants.forEach((variant) => {
              const emptyProductLocation = generateEmptyProductLocation({
                sellingPriceCurrency: state.sellingPriceCurrency,
                locationId: locationId ?? "",
                productVariantId: variant.id,
                nonInventory: state.nonInventory,
                allowManualCost: state.allowManualCost,
              })
              state.productLocation?.push(emptyProductLocation)
            })
          }
        })
      )
    },
    addNewManualLocation: (locationId?: string, variantId?: string) => {
      set(
        produce<ProductFormState>((state) => {
          const otherLocation = state.productLocation?.find(
            (location) => location.productVariantId === variantId || !variantId
          )

          // add base product location data
          state.productLocation?.push(
            generateEmptyProductLocation({
              sellingPriceCurrency: state.sellingPriceCurrency,
              locationId: locationId ?? "",
              productVariantId: variantId,
              nonInventory: state.nonInventory,
              sellingPrice: otherLocation?.sellingPrice,
              MRP: otherLocation?.MRP,
              wholesalePrice: otherLocation?.wholesalePrice,
              allowManualCost: state.allowManualCost,
            })
          )
        })
      )
    },
    removeProductLocation: (locationId: string) => {
      set(
        produce<ProductFormState>((state) => {
          state.productLocation = state.productLocation?.filter(
            (location) => location.locationId !== locationId
          )
        })
      )
    },
    updateProductLocation: (
      tempId: string,
      location: Partial<ProductFormState["productLocation"][number]>
    ) => {
      const index = get().productLocation.findIndex(
        (item) => item.tempId === tempId
      )
      set(
        produce<ProductFormState>((state) => {
          const locationExists = state.productLocation?.find(
            (item) => item.locationId === location.locationId
          )
          if (
            locationExists &&
            locationExists.tempId !== tempId &&
            !location.productVariantId
          ) {
            throw new Error(t("Location has already been added."))
          }

          state.productLocation[index] = {
            ...state.productLocation[index],
            ...location,
          }
        })
      )
    },
    setIsBundle: (isBundle: boolean, locationId?: string) => {
      set(
        produce<ProductFormState>((state) => {
          if (isBundle) {
            state.bundleItems ??= [
              {
                tempId: nanoid(),
                id: "",
                quantity: 1,
                productLocation: [],
                nonInventory: false,
                variant: undefined,
              },
            ]
          }
          state.isBundle = isBundle
        })
      )
    },
    addNewBundleItem: () => {
      set(
        produce<ProductFormState>((state) => {
          state.bundleItems ??= []
          state.bundleItems?.push({
            tempId: nanoid(),
            id: "",
            quantity: 1,
            productLocation: [],
            nonInventory: false,
            variant: undefined,
          })
        })
      )
    },
    removeBundleItem: (tempId: string) => {
      set(
        produce<ProductFormState>((state) => {
          state.bundleItems = state.bundleItems?.filter(
            (item) => item.tempId !== tempId
          )
        })
      )
    },
    updateBundleItem: (
      tempId: string,
      bundleItem: Partial<ProductFormState["bundleItems"][number]>,
      variant?: {
        id: string
        name: string
      }
    ) => {
      const index = get().bundleItems.findIndex(
        (item) => item.tempId === tempId
      )
      set(
        produce<ProductFormState>((state) => {
          state.bundleItems[index] = {
            ...state.bundleItems[index],
            ...bundleItem,
            variant: variant || state.bundleItems[index].variant,
          }
        })
      )
    },
    clearBundleItems: () => {
      set(
        produce<ProductFormState>((state) => {
          state.bundleItems = [
            {
              tempId: nanoid(),
              id: "",
              quantity: 1,
              productLocation: [],
              nonInventory: false,
              variant: undefined,
            },
          ]
        })
      )
    },
    updateOptionName: (tempId: string, name: string) => {
      set(
        produce<ProductFormState>((state) => {
          const index = state.productOptions?.findIndex(
            (item) => item.id === tempId
          )
          state.productOptions[index].name = name
        })
      )
    },
    addOption: () => {
      set(
        produce<ProductFormState>((state) => {
          state.productOptions ??= []
          state.productOptions.push({
            id: v4(),
            name: "",
            values: [
              {
                id: v4(),
                name: "",
              },
            ],
          })
          state.productVariants = generateNewVariants(state)
          state.productLocation = generateProductLocationForAllVariants(state)
        })
      )
    },
    removeOption: (tempId: string) => {
      set(
        produce<ProductFormState>((state) => {
          state.productOptions = state.productOptions?.filter(
            (item) => item.id !== tempId
          )
          state.productVariants = generateNewVariants(state)
          state.productLocation = generateProductLocationForAllVariants(state)
        })
      )
    },
    removeAllOption: () => {
      set(
        produce<ProductFormState>((state) => {
          state.productOptions = []
          state.productVariants = generateNewVariants(state)
          state.productLocation = generateProductLocationForAllVariants(state)
        })
      )
    },

    addOptionValue: (tempId: string) => {
      set(
        produce<ProductFormState>((state) => {
          const index = state.productOptions?.findIndex(
            (item) => item.id === tempId
          )
          state.productOptions[index].values.push({
            id: v4(),
            name: "",
          })
          state.productVariants = generateNewVariants(state)
          state.productLocation = generateProductLocationForAllVariants(state)
        })
      )
    },
    removeOptionValue: (tempId: string, valueTempId: string) => {
      set(
        produce<ProductFormState>((state) => {
          const index = state.productOptions?.findIndex(
            (item) => item.id === tempId
          )
          state.productOptions[index].values = state.productOptions[
            index
          ].values.filter((item) => item.id !== valueTempId)

          // remove option completely when empty
          if (state.productOptions[index].values.length === 0) {
            state.productOptions = state.productOptions?.filter(
              (item) => item.id !== tempId
            )
          }
          state.productVariants = generateNewVariants(state)
          state.productLocation = generateProductLocationForAllVariants(state)
        })
      )
    },
    updateOptionValueName: (
      tempId: string,
      valueTempId: string,
      name: string
    ) => {
      set(
        produce<ProductFormState>((state) => {
          const index = state.productOptions?.findIndex(
            (item) => item.id === tempId
          )
          const valueIndex = state.productOptions[index].values.findIndex(
            (item) => item.id === valueTempId
          )
          state.productOptions[index].values[valueIndex].name = name
        })
      )
    },
    findOptionValueName: (optionId: string, valueId: string) => {
      const state = get()
      const index = state.productOptions?.findIndex(
        (item) => item.id === optionId
      )
      const valueIndex = state.productOptions[index]?.values.findIndex(
        (item) => item.id === valueId
      )
      return state.productOptions[index]?.values[valueIndex]?.name
    },
    updateVariant: (
      tempId: string,
      variant: Partial<ProductFormState["productVariants"][number]>
    ) => {
      const index = get().productVariants.findIndex(
        (item) => item.id === tempId
      )
      set(
        produce<ProductFormState>((state) => {
          state.productVariants[index] = {
            ...state.productVariants[index],
            ...variant,
          }
        })
      )
    },
    setIngredientsLocationList: (list: IngredientsLocation[]) => {
      set(
        produce<ProductFormState>((state) => {
          state.ingredientsLocationList = list
        })
      )
    },
    hasRecipe: () => {
      return false
    },
    hasVariant: () => {
      const state = get()
      return state.productVariants.length > 0
    },
    calculateBundleCost: (locationId: string) => {
      const state = get()

      let bundleCost = Big(0)
      for (const item of state.bundleItems ?? []) {
        const pl = item.productLocation?.find(
          (pl) =>
            pl.locationId === locationId &&
            (pl.productVariantId === item.variant?.id || !item.variant)
        )
        const cost = Big(pl?.MRP ?? 0).mul(item.quantity)
        bundleCost = bundleCost.plus(cost)
      }

      let materialCost = Big(0)
      for (const item of state.recipe ?? []) {
        const cost = Big(item.quantity)
          .mul(item?.MRP ?? 0)
          .div(item.conversionNumber || 1)
        materialCost = materialCost.plus(cost)
      }

      return bundleCost.plus(materialCost).round(2).toNumber()
    },
    calculateBundleStock: (locationId: string) => {
      const state = get()
      return calculateBundleStock(
        state.bundleItems?.map((b) => ({ ...b, variant: b.variant?.id })) ?? [],
        locationId
      )
    },
    addTax: (tax: string) => {
      set(
        produce<ProductFormState>((state) => {
          state.taxes.push(tax)
          state.taxIncluded = true
        })
      )
    },
    removeTax: (tax: string) => {
      set(
        produce<ProductFormState>((state) => {
          const newTaxes = state.taxes.filter((item) => item !== tax)
          state.taxes = newTaxes
          if (newTaxes.length === 0) {
            state.taxIncluded = false
          }
        })
      )
    },
    addSupplier: (supplier: { supplierId: string }) => {
      set(
        produce<ProductFormState>((state) => {
          state.productToSupplier.push({
            SKU: "",
            ...supplier,
          })
        })
      )
    },
    removeSupplier: (supplierId: string) => {
      set(
        produce<ProductFormState>((state) => {
          state.productToSupplier = state.productToSupplier.filter(
            (item) => item.supplierId !== supplierId
          )
        })
      )
    },
    setSelectedLocationId: (locationId: string) => {
      set(
        produce<ProductFormState>((state) => {
          state.selectedLocationId = locationId
        })
      )
    },
    addProductVariant: (selectedOptions: Record<string, string>) => {
      set(
        produce<ProductFormState>(({ productVariants, productOptions }) => {
          const allSelected = productOptions.every((op) => {
            return selectedOptions[op.id]
          })

          if (!allSelected) {
            throw new Error(t("Please select all options"))
          }

          const isExist = productVariants.find((v) => {
            return v.variantValues.every((vv) => {
              return (
                selectedOptions[vv.productOption.id] ===
                vv.productOptionValue.id
              )
            })
          })

          if (isExist) {
            throw new Error(t("Variant already exists."))
          }

          const newVariant = {
            id: v4(),
            sellingPrice: 0,
            MRPCurrency: "",
            sellingPriceCurrency: "",
            MRP: 0,
            beginningStock: 0,
            stockReceived: 0,
            SKU: "",
            variantValues: Object.entries(selectedOptions).map(
              ([optionId, valueId]) => ({
                productOption: { id: optionId },
                productOptionValue: { id: valueId },
              })
            ),
          }

          productVariants.push(newVariant)
        })
      )
    },
    setApplyToAllLocation: (applyToAllLocation: boolean) => {
      set({ applyToAllLocation })
    },
    setValueToAllProductLocation: (key: string, value: any) => {
      set(
        produce<ProductFormState>((state) => {
          state.productLocation?.forEach((pl: any) => {
            pl[key] = value
          })
        })
      )
    },
    setValueToProductLocationByVariantId: (
      variantId: string,
      key: string,
      value: any
    ) => {
      set(
        produce<ProductFormState>((state) => {
          state.productLocation?.forEach((pl: any) => {
            if (pl.productVariantId === variantId) {
              pl[key] = value
            }
          })
        })
      )
    },
  })),
  { name: "product-form" }
)

const generatePossibleVariants = (productOptions: ProductOption[]) => {
  if (productOptions.length === 0) return []
  // @ts-ignore
  return (
    productOptions
      .map((p) =>
        p.values.map((v) => {
          const { values, ...option } = p
          return { option, value: v }
        })
      )
      // @ts-ignore
      .reduce((acc, option) => {
        if (acc.length === 0) {
          return option.map((value) => [value])
        }
        return acc.flatMap((product) =>
          option.map((value) => [product, value].flat())
        )
      })
  )
}

const generateNewVariants = (state: ProductFormState) => {
  const possibleVariants = generatePossibleVariants(state.productOptions)
  return possibleVariants
    .map((v) => {
      if (!Array.isArray(v)) return [v]
      return v
    })
    .map((newVariant) => {
      // when removing an option,
      // we'll pick the first existing variant that have the superset of the new variant's option
      // as the default value for updated variants
      let existing = state.productVariants.find((existingVar) =>
        newVariant.every((newVar) =>
          existingVar.variantValues.some(
            (values) => values.productOptionValue.id === newVar.value.id
          )
        )
      )

      if (!existing) {
        // when we add a new option,
        // we'll pick the first existing variant that have the subset of the new variant's option
        // as the default value for the new variants
        existing = state.productVariants.find((existingVar) =>
          existingVar.variantValues.every((values) =>
            newVariant.some(
              (newVar) => values.productOptionValue.id === newVar.value.id
            )
          )
        )
      }

      return {
        id: v4(),
        sellingPrice: state.sellingPrice,
        MRPCurrency: state.MRPCurrency,
        sellingPriceCurrency: state.sellingPriceCurrency,
        MRP: state.MRP,
        beginningStock: 0,
        stockReceived: 0,
        SKU: "",
        ...existing,
        variantValues: newVariant.map((v) => ({
          productOptionValue: { id: v.value.id },
          productOption: { id: v.option.id },
        })),
      }
    })
}

// const generateProductLocationForNewVariants = (state: ProductFormState) => {
//   const uniqueLocationIds = uniq(state.productLocation.map((l) => l.locationId))
//   const newVariants = state.productVariants.filter((v) => !v.createdAt)
//   const productLocations = newVariants.flatMap((v) =>
//     uniqueLocationIds.map((l) =>
//       generateEmptyProductLocation(v.sellingPriceCurrency, l, v.id)
//     )
//   )
//   return productLocations
// }

const generateProductLocationForAllVariants = (state: ProductFormState) => {
  const uniqueLocationIds = uniq(state.productLocation.map((l) => l.locationId))
  const originalLocations = state.productLocation.filter(
    (l) => !l.productVariantId
  )

  const variantLocations = state.productVariants.flatMap((v) =>
    uniqueLocationIds.map((l) => {
      const existingProductLocation = state.productLocation.find(
        (pl) => pl.productVariantId === v.id && pl.locationId === l
      )

      return {
        ...generateEmptyProductLocation({
          sellingPriceCurrency: v.sellingPriceCurrency,
          locationId: l,
          productVariantId: v.id,
          nonInventory: state.nonInventory,
          allowManualCost: state.allowManualCost,
        }),
        ...existingProductLocation,
      }
    })
  )
  return originalLocations.concat(variantLocations)
}

export type PostStockTransferResponse = {
  originalProductDeliveries: {
    "cd1bb242-2605-4a8b-8cfd-e7a69d9cc354": number
  }
  destinationProductDeliveryId: string
  stockAmount: number
  transferDateTime: string
  status: string
  createdBy: string
  business: string
  originalLocation: string
  destinationLocation: string
  product: string
  unitOfMeasure: any
  transferReason: any
  updatedBy: any
  id: string
  createdAt: string
  updatedAt: string
  updatedOriginalProductLocation: {
    productId: string
    locationId: string
    productVariantId: string
    sellingPrice: number
    sellingPriceCurrency: string
    unitOfMeasure: string
    unitOfMeasureValue: number
    stock: number
    beginningStock: number
    status: string
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    expirationDate: string
    receivedDate: string
    serviceDuration: any
    isAvailable: boolean
    availableServiceDate: any
    blockServiceDate: any
    totalQuantity: any
    reminderTime: any
    maxCustomerPerSlot: any
    serviceSlotTime: any
    gapBetweenAppointmentTime: any
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: any
    isVisible: boolean
    salesReturned: number
    isOrderEnabled: boolean
    isVisibleOnEstore: boolean
    deposit: number
    depositUnitOfMeasurement: string
    allowPartialPayment: boolean
    termsCondition: any
    nonInventory: boolean
    MRP: number
    MRPCurrency: string
    wholesalePrice: number
  }
  updatedDestinationProductLocation: {
    productId: string
    locationId: string
    productVariantId: string
    sellingPrice: number
    sellingPriceCurrency: string
    unitOfMeasure: string
    unitOfMeasureValue: number
    stock: number
    beginningStock: number
    status: string
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    expirationDate: string
    receivedDate: string
    serviceDuration: any
    isAvailable: boolean
    availableServiceDate: any
    blockServiceDate: any
    totalQuantity: any
    reminderTime: any
    maxCustomerPerSlot: any
    serviceSlotTime: any
    gapBetweenAppointmentTime: any
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: any
    isVisible: boolean
    salesReturned: number
    isOrderEnabled: boolean
    isVisibleOnEstore: boolean
    deposit: number
    depositUnitOfMeasurement: string
    allowPartialPayment: boolean
    termsCondition: any
    nonInventory: boolean
    MRP: number
    MRPCurrency: string
    wholesalePrice: number
  }
  updatedProductVariant: {
    id: string
    SKU: string
    MRP: number
    MRPCurrency: string
    sellingPrice: number
    sellingPriceCurrency: string
    beginningStock: number
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    salesReturned: number
    unitOfMeasure: string
    createdAt: string
    updatedAt: string
    createdBy: any
    updatedBy: any
  }
  updatedProduct: {
    id: string
    name: string
    SKU: string
    isReturnable: boolean
    barcode: any
    qrcode: any
    description: string
    productType: string
    productTypeStatus: any
    paymentOptions: any
    MRP: number
    MRPCurrency: string
    sellingPrice: number
    wholesalePrice: number
    sellingPriceCurrency: string
    unitOfMeasure: string
    unitOfMeasureValue: number
    purchaseUnitOfMeasure: any
    conversionNumber: number
    minimumQuantity: number
    recommendedQuantity: number
    stock: number
    beginningStock: number
    status: string
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    imageUrl: any
    images: any
    expirationDate: string
    receivedDate: string
    isVisible: boolean
    autoGenerateSKU: boolean
    salesReturned: number
    isOrderEnabled: boolean
    isVisibleOnEstore: boolean
    isBundle: boolean
    weight: number
    width: number
    height: number
    length: number
    attachment: any
    serviceType: any
    serviceDuration: any
    serviceSlotTime: any
    gapBetweenAppointmentTime: number
    maxCustomerPerSlot: number
    reminderTime: any
    totalQuantity: number
    termsCondition: any
    isAvailable: boolean
    availableServiceDate: any
    blockServiceDate: any
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
    deposit: number
    depositUnitOfMeasurement: string
    allowPartialPayment: boolean
    nonInventory: boolean
    isService: boolean
    priceType: string
    taxable: boolean
    taxIncluded: boolean
    serviceDurationUnit: string
    hasAddOns: boolean
    icon: any
    color: any
    shape: any
    halal: boolean
  }
}

export const postStockTransfer = async (
  items: Array<{
    originalLocation: { id: string }
    destinationLocation: { id: string }
    product: { id: string }
    stockAmount: number
    productVariant?: { id: string }
    transferDate?: any
  }>
) => {
  return await postAPI<PostStockTransferResponse>("/stock-transfer", {
    items,
    notes: "",
    status: "TRANSFERRED",
  })
}

export const postRecipe = async (productId: string, recipes: any) => {
  return await postAPI<any>(`/products/${productId}/recipe`, {
    recipes,
  })
}

export const productTypeOptions = [
  { type: PRODUCT_TYPE.GOODS, name: "Products" },
  { type: PRODUCT_TYPE.SERVICE_GOODS, name: "Services" },
  // { type: PRODUCT_TYPE.FOOD_SERVICE, name: "Food Service" },
  { type: PRODUCT_TYPE.OPERATION_SUPPLIES, name: "Operation Supplies" },
  { type: PRODUCT_TYPE.SERVICE, name: "Appointments" },
  { type: PRODUCT_TYPE.RENTAL_SERVICE, name: "Rental Service" },
  { type: PRODUCT_TYPE.DIGITAL, name: "Digital" },
]

export function calculateSellingPrice(
  product: GetProductsResponse[number],
  locationId: string
) {
  const hasVariants =
    product.productVariants && product.productVariants.length > 0
  var location = product.productLocation?.filter(
    (el: any) =>
      el.locationId === locationId && (!hasVariants || el.productVariantId)
  )
  let sellingPrice =
    location && location[0] ? location[0].sellingPrice : product.sellingPrice
  if (location?.[0] && hasVariants) {
    sellingPrice = location?.reduce(
      (min: any, p: any) => (p.sellingPrice < min ? p.sellingPrice : min),
      location[0].sellingPrice
    )
  }
  return sellingPrice
}

export const calculateBundleStock = (
  bundleItems: GetProductsResponse[number]["bundleItems"],
  locationId: string
) => {
  const availabilities = bundleItems?.map((i) => {
    if (i.nonInventory) {
      return Infinity
    }

    if (
      i.productType === "SERVICE" ||
      i.productType === "RENTAL_SERVICE" ||
      i.productType === "SERVICE_GOODS"
    ) {
      return Infinity
    }

    const pl = i.productLocation?.find(
      (pl) =>
        pl.locationId === locationId &&
        (!i.variant || i.variant === pl.productVariantId)
    )

    if (!pl?.stockInHand || !i.quantity) return 0

    return Big(pl?.stockInHand).div(i.quantity).round(2).toNumber()
  })

  return Math.min(...(availabilities ?? [0]))
}

export const postNewStockTransfer = async (body: any) => {
  return await postAPI<StockTransfer>("/stock-transfer", body)
}

export const patchStockTransfer = async (id: string, body: any) => {
  return await patchAPI<any>("/stock-transfer", id, body)
}

export const deleteStockTransfer = async (id: string) => {
  return await deleteAPI<any>("/stock-transfer", id)
}

export const patchSupplierProduct = async (product: any) => {
  return patchAPI<GetProductsResponse[number]>("/products", product.id, product)
}
