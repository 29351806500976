import { useEffect } from "react"
import {
  deleteOfflineOrder,
  getOfflineOrders,
  OfflineOrder,
} from "../lib/offline-pos/db"
import useOnlineStatus from "./use-online-status"
import { createCartTransaction } from "../lib/cart"
import useBusiness from "./api/use-business"
import { create } from "zustand"
import { combine } from "zustand/middleware"
import { AxiosError } from "axios"
import useOpenOrders from "./api/use-open-orders"

// zustand store to sync isSyncing status
const defaultValue = {
  isSyncing: false,
}

const useOfflineSyncStore = create(
  combine(defaultValue, (set) => ({
    setIsSyncing: (val: boolean) => set({ isSyncing: val }),
  }))
)

// sync offline orders from indexed db when device goes online
const useSyncOfflineOrders = () => {
  const { setIsSyncing } = useOfflineSyncStore()
  const { data: business } = useBusiness()
  // const { mutate: mutateOpenOrders } = useOpenOrders()
  const isOnline = useOnlineStatus()

  useEffect(() => {
    if (isOnline && business?.id) {
      syncOfflineOrders({
        businessId: business?.id,
        onStart: () => setIsSyncing(true),
        onFinish: async () => {
          // await mutateOpenOrders()
          setIsSyncing(false)
        },
      }).catch((e) => console.log(e))
    }
  }, [business?.id, isOnline, setIsSyncing])
}

export const useIsSyncingOfflineOrders = () => {
  const { isSyncing } = useOfflineSyncStore()
  return isSyncing
}

const syncOfflineOrders = async (options?: {
  businessId?: string
  onStart?: () => void
  onFinish?: () => Promise<void>
}) => {
  // lock access so that only one tab can sync at a time
  await navigator.locks.request("sync-orders", async (lock) => {
    if (lock) {
      const orders = await getOfflineOrders()
      if (orders.length) {
        options?.onStart?.()
        for (const order of orders) {
          if (!order.originalData.businessId) {
            order.originalData.businessId = options?.businessId ?? ""
          }
          await sync(order)
        }
        await options?.onFinish?.()
      }
    }
  })
}

const sync = async (order: OfflineOrder) => {
  try {
    const result = await createCartTransaction({
      cart: order.originalData.cart,
      customer: order.originalData.customer,
      businessId: order.originalData.businessId,
      paymentMethodCode: order.originalData.paymentMethodCode
        .replace("-qr", "")
        .toUpperCase(),
      dueDate: order.originalData.dueDate,
      tax: order.originalData.tax,
      serviceCharge: order.originalData.serviceCharge,
      productPromotion: order.originalData.productPromotion,
      discount: order.originalData.discount,
      totalOutstanding: order.originalData.totalOutstanding,
      createdBy: order.originalData.createdBy,
      totalCartAmount: order.originalData.totalCartAmount,
      notes: order.originalData.notes,
      isWalkIn: order.originalData.isWalkIn,
      isOpenOrder: order.originalData.isOpenOrder,
      customerName: order.originalData.customerName,
      cashReceived: order.originalData.cashReceived,
      cashChange: order.originalData.cashChange,
      cashRegisterId: order.originalData.cashRegister,
      taxes: order.originalData.taxes ?? [],
      paymentChannel: order.originalData.paymentChannel,
      reward: order.originalData.reward,
      cashback: order.originalData.cashback,
      isB2B: order.originalData.isB2B,
      customerLocationId: order.originalData.customerLocationId,
      paymentTerm: order.originalData.paymentTerm,
      priceList: order.originalData.priceList,
      wholesale: order.originalData.wholesale,
      taxExemptions: order.originalData.taxExemptions,
      isPickup: order.originalData.isPickup,
      pickupTime: order.originalData.pickupTime,
      tables: order.originalData.tables,
      autoPrint: order.originalData.autoPrint,
      posType: order.originalData.posType,
    })
    await deleteOfflineOrder(order.id)
    return result
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 400) {
        // usually, caused by out-of-stock errors,
        // delete order if it's the case.
        await deleteOfflineOrder(order.id)
      } else if (e.response?.status === 409) {
        // means the cartTrx actually have been saved
        await deleteOfflineOrder(order.id)
      }
    }
    console.log(e)
  }
}

export default useSyncOfflineOrders
