import { formatNumberToCurrency } from "../lib/currency"
import { FC } from "react"
import { Business, Invoice } from "../lib/models"
import {
  Check,
  MailOutlined,
  PhoneOutlined,
  Storefront,
} from "@mui/icons-material"
import Placeholder from "../assets/images/placeholder.png"
import { Trans } from "react-i18next"
import { t } from "i18next"
import PayPalBadge from "./PayPalBadge"
import Barcode from "react-barcode"
import { generateTemplateCSSVar } from "../lib/templates"
import useTemplatePublic from "../hooks/api/use-template-public"
import Big from "big.js"
import { addMonths, format } from "date-fns"
import useInvoiceSettings from "../hooks/api/use-invoice-settings"

const InvoicePreview: FC<{
  invoice: Invoice
  items: Array<{
    id: string
    product: {
      id: string
      name: string
      productVariant?: {
        id: string
        name: string
      }
      description: string
    }
    quantity: number
    sellingPrice: number
  }>
  business: Business
  showOutstanding?: boolean
}> = ({ showOutstanding, invoice, business, items }) => {
  const { data: template } = useTemplatePublic(business.id)
  const { data: invoiceSettings } = useInvoiceSettings()
  const isOverdue = invoice.dueDate < new Date()

  let discountAmount = ""
  if (invoice.percentageDiscount) {
    const calculatedDiscount =
      (invoice.percentageDiscount / 100) * invoice.subtotal
    discountAmount = `${formatNumberToCurrency(
      invoice.currency,
      calculatedDiscount
    )} (${invoice.percentageDiscount}%)`
  } else if (invoice.fixedDiscount) {
    discountAmount = formatNumberToCurrency(
      invoice.currency,
      invoice.fixedDiscount
    )
  }

  const logo = template?.logoUrl || business?.logoUrl

  const totalTax = invoice.taxDetails?.reduce((acc, tax) => acc + tax.amount, 0)

  return (
    <div
      className="w-full overflow-hidden rounded-xl border border-solid border-gray-200 bg-white shadow-sm lg:basis-3/4"
      style={generateTemplateCSSVar(template)}
    >
      {/* use table for fixed header when printing */}
      <table className="w-full">
        <thead>
          <div className={"flex flex-col md:flex-row print:!flex-row"}>
            <div className={"m-8 mb-8"}>
              {logo && (
                <div className="-ml-2 -mt-2 mb-4 flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-lg bg-gray-50 ring-primary-900">
                  <img
                    src={logo ?? Placeholder}
                    className={"h-full w-full rounded-lg object-cover"}
                    alt={""}
                  />
                </div>
              )}
              <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
                {business.name}
              </p>
              {invoice.location && (
                <>
                  <p className={"mx-0 mb-1 mt-0  p-0 text-[14px]"}>
                    {invoice.location.addressLine1}
                  </p>
                  {invoice.location.addressLine2 && (
                    <p className={"mx-0 mb-1 mt-0  p-0 text-[14px]"}>
                      {invoice.location.addressLine2}
                    </p>
                  )}
                  <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
                    {invoice.location.city}, {invoice.location.state},{" "}
                    {invoice.location.zip}
                  </p>
                  {invoice.location.companyName && (
                    <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
                      {invoice.location.companyName}
                    </p>
                  )}
                  {invoice.location.tin && (
                    <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
                      {invoice.location.tin}
                    </p>
                  )}
                </>
              )}
              {invoiceSettings?.qrCode && (
                <img
                  src={invoiceSettings?.qrCode}
                  alt="QR Code"
                  className="w-40 h-40 mt-4"
                />
              )}
            </div>

            <div
              className={
                "m-8 mb-0 lg:ml-auto lg:text-right print:!ml-auto print:!text-right"
              }
            >
              <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
                <Trans>Invoice</Trans> #{invoice.invoiceNumber}
              </p>
              <p className={"mx-0 mb-1 mt-0 p-0 text-[14px] text-gray-500"}>
                <Trans>Issued :</Trans>{" "}
                <span className={"font-medium"}>
                  {invoice.invoiceDate.toDateString()}
                </span>
              </p>
              <p className={"mx-0 mb-1 mt-0 p-0 text-[14px] text-gray-500"}>
                <Trans>Due :</Trans>{" "}
                <span className={"font-medium"}>
                  {invoice.dueDate.toDateString()}
                </span>
              </p>
            </div>
          </div>
        </thead>

        <div
          className={
            "mb-8 mt-8 flex flex-col lg:flex-row lg:items-end print:!flex-row print:!items-end"
          }
        >
          {invoice.customer && (
            <div className={"ml-8"}>
              <p className={"m-0 mb-2 p-0 font-medium"}>Bill To</p>
              <p className={"m-0 mb-1 p-0 text-[14px] text-gray-700"}>
                {invoice.customer.fullName}
              </p>
              {invoice.project && (
                <p className={"m-0 mb-1 p-0 text-[14px] text-gray-700"}>
                  Project {invoice.project.name}
                </p>
              )}
              <p className={"m-0 mb-1 p-0 text-[14px] text-gray-700"}>
                {invoice.customer.phone}
              </p>
              <p className={"m-0 p-0 text-[14px] text-gray-700"}>
                {invoice.customer.email}
              </p>
            </div>
          )}

          <div className={"mx-8 mt-12 flex flex-col lg:ml-auto print:ml-auto"}>
            <p
              className={
                "m-0 mb-2 text-2xl font-bold text-gray-800 lg:ml-auto print:ml-auto"
              }
            >
              {
              formatNumberToCurrency(
                invoice.currency,
                isOverdue && invoice.status !== "PAID" && invoice.status !== "PENDING"
                  ? (invoice.outstanding ?? 0)
                  : (invoice.totalPrice ?? 0)
              )
              }
            </p>
            {isOverdue &&
              invoice.status !== "PAID" &&
              invoice.status !== "PENDING" && (
                <p className="my-0 p-0 text-lg font-bold uppercase text-orange-400 lg:ml-auto print:ml-auto">
                  <Trans>Overdue</Trans>
                </p>
              )}

            {!isOverdue &&
              invoice.status !== "PAID" &&
              invoice.status !== "PENDING" && (
                <p className="my-0 p-0 text-lg font-bold uppercase text-gray-800 lg:ml-auto  print:ml-auto">
                  <Trans>Current</Trans>
                </p>
              )}

            {invoice.status === "PAID" && (
              <div className={"flex items-center"}>
                {invoice.paymentMethod === "PAYPAL" && (
                  <PayPalBadge
                    paymentId={invoice.paymentId}
                    className={"-ml-1 mr-4 "}
                  />
                )}

                <p className="my-0 flex items-center p-0 text-lg font-bold uppercase text-green-600 lg:ml-auto print:ml-auto">
                  <Check className="mr-2 !h-4 !w-4 rounded-full bg-green-100 p-0.5" />
                  <Trans>Paid</Trans>
                </p>
              </div>
            )}

            {invoice.status === "PENDING" && (
              <p className="my-0 flex items-center p-0 text-lg font-bold uppercase text-amber-500 lg:ml-auto print:ml-auto">
                <Trans>Processing payment</Trans>
              </p>
            )}
          </div>
        </div>

        <div className="flex items-center border-y border-template-primary/20 bg-template-primary/10 px-8 py-3">
          {business.phone && (
            <a
              className={"flex items-center text-gray-700 no-underline"}
              href={`tel:${business.phone}`}
            >
              <Storefront className={"mr-2 !h-4 !w-4 opacity-70"} />
              <p className={"m-0 p-0 text-[14px]"}>{business.name}</p>
            </a>
          )}

          {business.email && business.phone && (
            <p className={"mx-4 p-0 text-gray-400"}>•</p>
          )}

          {business.phone && (
            <a
              className={"flex items-center text-gray-700 no-underline"}
              href={`tel:${business.phone}`}
            >
              <PhoneOutlined className={"mr-2 !h-4 !w-4 opacity-70"} />
              <p className={"m-0 p-0 text-[14px]"}>{business.phone}</p>
            </a>
          )}
          {business.email && business.phone && (
            <p className={"mx-4 p-0 text-gray-400"}>•</p>
          )}
          {business.email && (
            <a
              className={"flex items-center text-gray-700 no-underline"}
              href={`mailto:${business.email}`}
            >
              <MailOutlined className={"mr-2 !h-4 !w-4 opacity-70"} />
              <span className={"m-0 p-0 text-[14px]"}>{business.email}</span>
            </a>
          )}
        </div>

        <div className={"m-8"}>
          <p className={"mb-2 p-0 text-lg font-medium"}>
            <Trans>Items</Trans>
          </p>
          <div className={"-mx-1"}>
            {items.map((item) => (
              <div
                key={item.id}
                className="mb-4 rounded-lg border border-solid border-gray-200 p-4"
              >
                <div className={"flex"}>
                  <div>
                    <p className={"m-0 mb-1 p-0 text-sm"}>
                      {item.product.name}
                    </p>
                    {item.product.productVariant && (
                      <p className={"m-0 mb-1 p-0 text-sm text-gray-700"}>
                        {item.product.productVariant?.name}
                      </p>
                    )}
                    <p className={"m-0 mb-1 p-0 text-sm text-gray-700"}>
                      {item.product.description}
                    </p>
                    <p className={"m-0 p-0 text-sm text-gray-700"}>
                      {item.quantity} x{" "}
                      {formatNumberToCurrency(
                        invoice.currency,
                        item.sellingPrice
                      )}
                    </p>
                  </div>

                  <p className={"m-0 ml-auto p-0 text-lg font-medium"}>
                    {formatNumberToCurrency(
                      invoice.currency,
                      item.sellingPrice * item.quantity
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          {invoice.termType === "INSTALLMENT" &&
            invoice.installmentDetail &&
            invoice.installmentDetail.term && (
              <div className="flex flex-col gap-2 px-8 pb-8 text-sm text-gray-600">
                <div className="font-semibold mb-2">
                  {t("Payment Breakdown")}:
                </div>
                {Array.from(
                  { length: invoice.installmentDetail.term },
                  (_, i) => i + 1
                ).map((el) => {
                  const term = invoice.installmentDetail?.term || 0
                  const date = format(
                    addMonths(invoice.invoiceDate, el - 1),
                    "MMMM d, yyyy"
                  )
                  let status =
                    invoice.status === "PAID"
                      ? "PAID"
                      : invoice.lastPaymentDate
                        ? new Date(invoice.lastPaymentDate) < new Date(date) &&
                          new Date() > new Date(date)
                          ? "Overdue"
                          : "Pending"
                        : "Pending"

                  if (
                    invoice.installmentDetail?.amount &&
                    invoice.amountReceived &&
                    invoice.amountReceived >=
                      invoice.installmentDetail?.amount * el
                  )
                    status = "Paid"

                  return (
                    <div className="flex flex-col  gap-2">
                      <div className="flex flex-row gap-8">
                        <div>{t("Due Date")}</div>
                        <div className="ml-auto">{date}</div>
                      </div>
                      <div className="flex flex-row gap-8">
                        <div>{t("Amount")}</div>
                        <div className="ml-auto">
                          {formatNumberToCurrency(
                            invoice.currency,
                            invoice.installmentDetail?.amount || 0
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row gap-8">
                        <div>{t("Status")}</div>
                        <div className="ml-auto">{status}</div>
                      </div>
                      {el < term && <hr></hr>}
                    </div>
                  )
                })}
              </div>
            )}
          <div className={"ml-auto box-border w-full max-w-lg p-8 !pb-2 !pt-0"}>
            <div className={"flex text-gray-500"}>
              <p className={"mb-2 mt-0 p-0"}>
                <Trans>Subtotal</Trans>
              </p>
              <p className={"mb-2 ml-auto mt-0 p-0"}>
                {formatNumberToCurrency(invoice.currency, invoice.subtotal)}
              </p>
            </div>

            <div className={"flex text-gray-500"}>
              <p className={"mb-2 mt-0 p-0"}>
                <Trans>Discount</Trans>
              </p>
              <p className={"mb-2 ml-auto mt-0 p-0"}>
                {discountAmount}
                {invoice.percentageDiscount === 0 &&
                  invoice.fixedDiscount === 0 &&
                  "-"}
              </p>
            </div>

            <div className={"flex text-gray-500"}>
              <p className={"mb-2 mt-0 p-0"}>
                <Trans>Shipping</Trans>
              </p>
              <p className={"mb-2 ml-auto mt-0 p-0"}>
                {formatNumberToCurrency(invoice.currency, invoice.shippingCost)}
              </p>
            </div>

            <div className={"flex text-gray-500"}>
              <p className={"mb-2 mt-0 p-0"}>
                {invoice.otherCostDetails || t("Other costs")}
              </p>
              <p className={"mb-2 ml-auto mt-0 p-0"}>
                {formatNumberToCurrency(invoice.currency, invoice.otherCost)}
              </p>
            </div>

            <div className={"flex text-gray-500"}>
              <p className={"mb-2 mt-0 p-0"}>
                <Trans>Tax</Trans>
              </p>
              <p className={"mb-2 ml-auto mt-0 p-0"}>
                {formatNumberToCurrency(invoice.currency, totalTax)}
              </p>
            </div>
            {invoice.taxDetails?.map((t) => (
              <div className={"flex text-gray-500"}>
                <div className={"mb-2 mt-0 p-0 flex"}>
                  <p>•</p>
                  <p className={"ml-2"}>
                    {t.name} {t.type === "INCLUDED" && "(Included)"}
                  </p>
                </div>

                <p className={"mb-2 ml-auto mt-0 p-0"}>
                  {formatNumberToCurrency(invoice.currency, t.amount)}
                </p>
              </div>
            ))}

            <div className={"my-2 h-[1px] w-full bg-gray-300"} />

            <div className={"flex font-bold text-gray-900"}>
              <p className={"mb-1 mt-0.5 p-0"}>
                <Trans>Total</Trans>
              </p>
              <p className={"mb-1 ml-auto mt-0.5 p-0"}>
                {formatNumberToCurrency(invoice.currency, invoice.totalPrice)}
              </p>
            </div>
            {showOutstanding && (
              <>
                <div className={"flex font-bold text-green-700"}>
                  <p className={"mb-1 mt-0.5 p-0"}>
                    <Trans>Total Deposit</Trans>
                  </p>
                  <p className={"mb-1 ml-auto mt-0.5 p-0"}>
                    {formatNumberToCurrency(
                      invoice.currency,
                      Big(invoice.totalPrice)
                        .minus(invoice.outstanding || 0)
                        .round(2)
                        .toNumber()
                    )}
                  </p>
                </div>
                <div className={"flex font-bold text-red-700"}>
                  <p className={"mb-1 mt-0.5 p-0"}>
                    <Trans>Outstanding</Trans>
                  </p>
                  <p className={"mb-1 ml-auto mt-0.5 p-0"}>
                    {formatNumberToCurrency(
                      invoice.currency,
                      invoice.outstanding
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={"box-border w-full px-8 pb-8"}>
          <div className={"h-[1px] w-full bg-gray-300"} />
        </div>

        {invoice.notes && (
          <>
            <div className={"mx-1 box-border w-full px-8 pb-8"}>
              <p className={"m-0 mb-2 p-0 font-medium"}>
                <Trans>Note to Customer</Trans>
              </p>
              <p className={"m-0 p-0 text-gray-700"}>{invoice.notes}</p>
            </div>

            <div className={"box-border w-full px-8 pb-8"}>
              <div className={"h-[1px] w-full bg-gray-300"} />
            </div>
          </>
        )}

        {invoice.termsAndCondition && (
          <>
            <div className={"mx-1 box-border w-full px-8 pb-8"}>
              <p className={"m-0 mb-2 p-0 font-medium"}>
                <Trans>Terms and Conditions</Trans>
              </p>
              <p className={"m-0 p-0 text-gray-700"}>
                {invoice.termsAndCondition}
              </p>
            </div>

            <div className={"box-border w-full px-8 pb-8"}>
              <div className={"h-[1px] w-full bg-gray-300"} />
            </div>
          </>
        )}

        {invoice.referenceNumber && (
          <>
            <div className={"mx-1 box-border w-full px-8 pb-8"}>
              <p className={"m-0 mb-2 p-0 font-medium"}>
                <Trans>Reference Number</Trans>
              </p>
              <p className={"m-0 p-0 text-gray-700"}>
                {invoice.referenceNumber}
              </p>
            </div>

            <div className={"box-border w-full px-8 pb-8"}>
              <div className={"h-[1px] w-full bg-gray-300"} />
            </div>
          </>
        )}

        {template?.paymentDetails && (
          <>
            <div className={"mx-1 box-border w-full px-8 pb-8"}>
              <p className={"m-0 mb-2 p-0 font-medium"}>
                <Trans>Payment Details</Trans>
              </p>
              {template.paymentDetails.split("\n").map((line) => (
                <p key={line} className={"m-0 p-0 text-gray-700"}>
                  {line || <br />}
                </p>
              ))}
            </div>

            <div className={"box-border w-full px-8 pb-8"}>
              <div className={"h-[1px] w-full bg-gray-300"} />
            </div>
          </>
        )}

        <div className="flex w-full flex-row justify-center pb-8">
          <Barcode
            value={invoice.invoiceNumber}
            height={25}
            width={2}
            format={"CODE39"}
            fontSize={10}
            margin={0}
          />
        </div>

        {template?.footerText && (
          <>
            <div className={"mx-1 box-border w-full px-8 pb-8 text-center"}>
              <p className={"m-0 mb-2 p-0 font-medium"}>
                <Trans></Trans>
              </p>
              <p className={"m-0 p-0 text-gray-700"}>{template?.footerText}</p>
            </div>
          </>
        )}
      </table>
    </div>
  )
}

export default InvoicePreview
