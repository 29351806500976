import { create } from "zustand"
import { combine } from "zustand/middleware"
import axios from "axios"

const fetcher = axios.create({
  baseURL: "http://localhost:8093",
})

const DEFAULT_STATE = {
  status: "UNKNOWN" as
    | "UNKNOWN"
    | "CONNECTED"
    | "CONNECTING"
    | "NOT_AVAILABLE"
    | "CLOSED",
  socket: null as WebSocket | null,
}

const openSocket = async () => {
  return new Promise<WebSocket>((resolve, reject) => {
    const socket = new WebSocket("ws://localhost:8093/comm")

    socket.onopen = () => {
      socket.send(JSON.stringify({ action: "INIT" }))
      resolve(socket)
    }

    socket.onerror = (error) => {
      socket.close()
      reject(new Error("Failed to connect to WebSocket"))
    }
  })
}

interface SyncPrinterPayload {
  action: string
  data: Array<{
    id: number
    name: string
    identifier: string
    label: string
    type: string
    kitchenPrinterGroupId: string
  }>
}

const useCompanionApp = create(
  combine(DEFAULT_STATE, (set, get) => ({
    async connect(options?: {
      syncKitchenPrinters?: (
        printers: Array<{ identifier: string; kitchenPrinterGroupId: string }>
      ) => void
      onDisconnect?: () => void
    }) {
      try {
        if (get().status === "CONNECTED") {
          return get().socket
        }
        const socket = await openSocket()
        set({ socket, status: "CONNECTED" })

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data)
          if (message.action === "SYNC_KITCHEN_PRINTERS") {
            const payload = message as SyncPrinterPayload
            if (options?.syncKitchenPrinters) {
              options.syncKitchenPrinters(payload.data)
            }
          }
        }

        socket.onclose = () => {
          set({ status: "CLOSED" })
          if (options?.onDisconnect) {
            options.onDisconnect()
          }
        }

        return socket
      } catch (error) {
        console.error(error)
        if (get().status === "UNKNOWN") {
          set({ status: "NOT_AVAILABLE" })
        }
        return null
      }
    },
    async printThermal(
      type: "RECEIPT" | "KITCHEN" | "LABEL",
      identifier: string | null,
      data: Uint8Array
    ): Promise<void> {
      let socket = get().socket

      if (!socket || socket.readyState !== WebSocket.OPEN) {
        socket = await this.connect()
        if (!socket) {
          throw new Error("Companion app not available")
        }
      }

      const message = JSON.stringify({
        action: "PRINT",
        type: type,
        identifier: identifier,
        data: Array.from(data),
      })

      socket.send(message)
    },
    ping() {
      get().socket?.send("PING")
    },
  }))
)

export default useCompanionApp
