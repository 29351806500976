import { create } from "zustand"
import { combine, persist } from "zustand/middleware"
import {
  encodeOpenDrawer,
  printFullReceipt,
  printIminCartTransactionReceiptItem,
  printIminCartTransactionReceiptItemHeader,
  printIminCartTransactionReceiptMetadata,
  printIminCartTransactionReceiptTotal,
} from "../lib/thermal-printer"
import {
  CartItem,
  DEFAULT_MPOS_SETTING,
  KitchenPrinterOptions,
  PrinterSetting,
} from "../lib/models"
import { GetCartTransactionResponse } from "./api/use-cart-transaction"
import { InvoiceSettingsResponseBody } from "./api/use-invoice-settings"
import { generateVariantNameFromVariantValues } from "../lib/variants"
import IminPrinter from "../lib/imin-printer"
import {
  encodeFullKitchenPrinter,
  encodeKitchenPrinter,
  KitchenPrinterItem,
} from "../lib/thermal-printer/kitchen-printer"
import { encodeBIRReceipt } from "../lib/thermal-printer/bir-receipts"
import { Business } from "./api/use-business"
import { tableQRCCodes } from "../lib/thermal-printer/table-management"
import { cloneDeep } from "lodash"
import { nanoid } from "nanoid"
import { ThermalPrinterEncoderOption } from "thermal-printer-encoder"
import BluetoothThermalPrinter from "../lib/thermal-printer/bluetooth-thermal-printer"
import UsbThermalPrinter from "../lib/thermal-printer/usb-thermal-printer"
import ThermalPrinter from "../lib/thermal-printer/model"
import * as Sentry from "@sentry/react"
import { sleep } from "../lib/async"
import { GetKitchenPrinterGroupResponse } from "./api/use-kitchen-printer-groups"
import SerialThermalPrinter from "../lib/thermal-printer/serial-thermal-printer"
import { encodeBIRXReport } from "../lib/thermal-printer/bir-x-report"
import { encodeTransferOrder } from "../lib/thermal-printer/transfer-order"
import { StockTransfer } from "./api/use-stock-transfers"
import { encodeShiftReport } from "../lib/thermal-printer/shift-report"
import { encodeItemLabels } from "../lib/thermal-printer/item-labels"
import CompanionAppThermalPrinter from "../lib/thermal-printer/companion-app-thermal-printer"

// UUID from WebBluetoothCG print demo
// https://github.com/WebBluetoothCG/demos/blob/7b7397df596888b51043586a187a5b2e08f14ecd/bluetooth-printer/index.html#L246
const THERMAL_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb"
const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb"
const UART_SERVICE_UUID = "49535343-fe7d-4ae5-8fa9-9fafd205e455"
const UART_RX_CHARACTERISTIC_UUID = "49535343-8841-43f4-a8d4-ecbe34729bb3"

export enum IminPrintWidth {
  WIDTH_58 = 1,
  WIDTH_80 = 0,
}

const DEFAULT_STATE = {
  receipt: {
    printer: null as ThermalPrinter | null,
    connected: false,
    type: "BLUETOOTH" as "BLUETOOTH" | "USB",
    deviceId: null as string | null,
  },
  kitchenPrinters: [] as Array<{
    printer: ThermalPrinter | null
    connected: boolean
    printerGroup: string
    deviceId: string | null // this is UUID returned by BluetoothDevice.id, so we can persist it
    id: string // this is a non-nullable id for the printer data
    type: "BLUETOOTH" | "USB" | "SERIAL"
  }>,
  label: {
    printer: null as ThermalPrinter | null,
    connected: false,
    deviceId: null as string | null,
    type: "BLUETOOTH" as "BLUETOOTH" | "USB",
  },
  imin: {
    printer: null as IminPrinter | null,
    connected: false,
    width: IminPrintWidth.WIDTH_80,
  },
}

export const connectPrinter = async (defaultPrinter?: BluetoothDevice) => {
  let printer = defaultPrinter
  if (!printer) {
    printer = await navigator.bluetooth.requestDevice({
      acceptAllDevices: true,
      optionalServices: [THERMAL_SERVICE_UUID, UART_SERVICE_UUID],
    })
  }
  Sentry.addBreadcrumb({
    category: "thermal-printer",
    message: "Printer selected",
    data: printer,
  })
  if (!printer.gatt) {
    throw new Error("GATT_SERVER_NOT_FOUND")
  }

  const server = await printer.gatt?.connect()
  const services = await server.getPrimaryServices()
  const thermalService = services.find(
    (service) => service.uuid === THERMAL_SERVICE_UUID
  )
  if (thermalService) {
    const thermalCharacteristic = await thermalService.getCharacteristic(
      PRINT_CHARACTERISTIC_UUID
    )
    return {
      printer,
      server,
      service: thermalService,
      characteristic: thermalCharacteristic,
    }
  }

  const uartService = services.find(
    (service) => service.uuid === UART_SERVICE_UUID
  )
  if (!uartService) {
    Sentry.addBreadcrumb({
      category: "thermal-printer",
      message: "Service not found",
      data: services,
    })
    throw new Error("SERVICE_NOT_FOUND")
  }
  const uartCharacteristic = await uartService.getCharacteristic(
    UART_RX_CHARACTERISTIC_UUID
  )
  return {
    printer,
    server,
    service: uartService,
    characteristic: uartCharacteristic,
  }
}

const useThermalPrinter = create(
  persist(
    combine(DEFAULT_STATE, (set, get) => ({
      connectReceipt: async (type: "BLUETOOTH" | "USB" = "BLUETOOTH") => {
        let printer: ThermalPrinter
        if (type === "BLUETOOTH") {
          printer = new BluetoothThermalPrinter()
        } else {
          printer = new UsbThermalPrinter()
        }
        await printer.connect()

        printer.onDisconnect(() => {
          const { receipt } = get()
          set({ receipt: { ...receipt, connected: false } })
        })

        set({
          receipt: {
            printer,
            connected: true,
            type,
            deviceId: printer.getId(),
          },
        })
      },
      connectKitchenPrinter: async (
        id: string,
        type: "BLUETOOTH" | "USB" | "SERIAL" = "BLUETOOTH"
      ) => {
        const kitchenPrinter = get().kitchenPrinters.find((kp) => kp.id === id)
        if (!kitchenPrinter) {
          throw new Error("PRINTER_NOT_FOUND")
        }

        const { printerGroup } = kitchenPrinter

        let printer = await createThermalPrinter(type)

        printer.onDisconnect(() => {
          const { kitchenPrinters } = get()
          const updated = cloneDeep(kitchenPrinters)
          const kp = updated.find((kp) => kp.id === id)
          if (!kp) {
            return
          }
          kp.connected = false
          set({ kitchenPrinters: updated })
        })

        const updated = cloneDeep(get().kitchenPrinters)
        const kp = updated.find((kp) => kp.id === id)
        if (!kp) {
          return
        }
        kp.printer = printer
        kp.connected = true
        kp.deviceId = printer.getId()
        kp.printerGroup = printerGroup
        kp.type = type
        set({ kitchenPrinters: updated })
      },
      setKitchenPrinterGroupName: (id: string, printerGroup: string) => {
        const { kitchenPrinters } = get()
        const updated = cloneDeep(kitchenPrinters)
        const kp = updated.find((kp) => kp.id === id)
        if (!kp) {
          return
        }
        kp.printerGroup = printerGroup
        set({ kitchenPrinters: updated })
      },
      connectImin: async () => {
        const state = get()
        if (state.imin.printer) {
          try {
            const status = await state.imin.printer.getPrinterStatus()
            if (status.value === 0) {
              return {
                text: "Printer is already connected",
                value: 0,
              }
            }
          } catch (e) {}
        }
        set({
          imin: {
            ...DEFAULT_STATE.imin,
            width: state.imin.width,
          },
        })

        const printer = new IminPrinter()
        await printer.connect()

        let status
        for (let attempt = 0; attempt < 3; attempt++) {
          try {
            printer.initPrinter()
            await sleep(1000) // printer status might not always be updated right away
            status = await printer.getPrinterStatus()
            Sentry.addBreadcrumb({
              category: "thermal-printer",
              message: "Imin printer status",
              data: status,
            })

            if (verifyStatus(status.value)) {
              set({
                imin: { printer, connected: true, width: state.imin.width },
              })
              break
            }
          } catch (e) {
            if (attempt === 2) {
              throw e
            }
          }
        }

        return {
          text: status.text as string,
          value: status.value as number,
        }
      },

      reconnectAll: async () => {
        const { receipt, label, kitchenPrinters } = get()
        if (receipt.deviceId) {
          let printer = receipt.printer
          if (!printer || !printer?.reconnect) {
            printer =
              receipt.type === "BLUETOOTH"
                ? new BluetoothThermalPrinter()
                : new UsbThermalPrinter()
          }
          try {
            await printer?.reconnect(receipt.deviceId)
            set({
              receipt: {
                printer,
                connected: true,
                type: receipt.type,
                deviceId: receipt.deviceId,
              },
            })
          } catch (e: any) {
            if (e?.message !== "ALREADY_WATCHING_ADVERTISEMENTS") {
              throw e
            }
          }
        }

        if (label.deviceId) {
          let printer = label.printer
          if (!printer || !printer?.reconnect) {
            printer =
              label.type === "BLUETOOTH"
                ? new BluetoothThermalPrinter()
                : new UsbThermalPrinter()
          }
          try {
            await printer?.reconnect(label.deviceId)
            set({
              label: {
                printer,
                connected: true,
                deviceId: label.deviceId,
                type: label.type,
              },
            })
          } catch (e: any) {
            if (e?.message !== "ALREADY_WATCHING_ADVERTISEMENTS") {
              throw e
            }
          }
        }

        for (const kp of kitchenPrinters) {
          const deviceId = kp.deviceId
          const type = kp.type
          let printer = kp.printer
          if (deviceId) {
            if (!printer || !printer?.reconnect) {
              printer =
                type === "BLUETOOTH"
                  ? new BluetoothThermalPrinter()
                  : new UsbThermalPrinter()
            }
            await printer.reconnect(deviceId)

            const updated = cloneDeep(get().kitchenPrinters)
            const updatedKp = updated.find((kp2) => kp2.id === kp.id)
            if (!updatedKp) {
              return
            }
            updatedKp.printer = printer
            updatedKp.connected = true
            set({ kitchenPrinters: updated })
          }
        }
      },
      setIminPrintWidth: (width: IminPrintWidth) => {
        set({ imin: { ...get().imin, width } })
      },
      connectLabel: async (type: "BLUETOOTH" | "USB" = "BLUETOOTH") => {
        let printer: ThermalPrinter
        if (type === "BLUETOOTH") {
          printer = new BluetoothThermalPrinter()
        } else {
          printer = new UsbThermalPrinter()
        }
        await printer.connect()

        printer.onDisconnect(() => {
          const { label } = get()
          set({ label: { ...label, connected: false } })
        })

        set({
          label: {
            printer,
            connected: true,
            deviceId: printer.getId(),
            type: type,
          },
        })
      },
      write: async (buffer: Uint8Array) => {
        const printer = get().receipt.printer

        if (!printer) {
          throw new Error("NOT_CONNECTED")
        }

        await printer.print(buffer)
      },
      printReceipt: async (
        business: Business,
        trx: GetCartTransactionResponse,
        receiptSetting: InvoiceSettingsResponseBody[number],
        posSetting: typeof DEFAULT_MPOS_SETTING,
        currency: string = "USD",
        options?: {
          triggerOpenDrawer?: boolean
          birReceiptType?: "ORIGINAL" | "DEFAULT"
          birORNumber?: string
          birSeriesNumber?: string
        }
      ) => {
        const state = get()

        const triggerOpenDrawer = options?.triggerOpenDrawer ?? true
        const birReceipt = options?.birReceiptType ?? "DEFAULT"

        // imin inbuilt printer
        if (
          !state.receipt.connected &&
          state.imin.connected &&
          state.imin.printer
        ) {
          try {
            const status = await state.imin.printer.getPrinterStatus()
            if (status.value === -1) {
              // SDK is connected but might not be turned on
              state.imin.printer.reconnect()
              await sleep(1000)
              state.imin.printer.initPrinter()
              await sleep(1000)
            }
          } catch (e) {
            console.log("error", e)
            // usually triggered when getPrinterStatus crashes because no socket connection
            // has been setup yet
            await state.imin.printer.connect()
            state.imin.printer.initPrinter()
            await sleep(1000)
          }

          const newStatus = await state.imin.printer.getPrinterStatus()
          verifyStatus(newStatus.value)

          state.imin.printer.setPageFormat(state.imin.width)
          await printIminCartTransactionReceiptMetadata(
            state.imin.printer,
            "Header",
            business,
            trx,
            receiptSetting,
            posSetting
          )
          printIminCartTransactionReceiptItemHeader(state.imin.printer, trx)
          for (const item of trx.orderedProducts) {
            printIminCartTransactionReceiptItem(
              state.imin.printer,
              item,
              currency
            )
          }
          printIminCartTransactionReceiptTotal(
            state.imin.printer,
            trx,
            currency
          )
          await printIminCartTransactionReceiptMetadata(
            state.imin.printer,
            "Footer",
            business,
            trx,
            receiptSetting,
            posSetting
          )
          state.imin.printer.printAndLineFeed()
          state.imin.printer.printAndLineFeed()
          state.imin.printer.printAndLineFeed()
          state.imin.printer.partialCutPaper()
          if (triggerOpenDrawer) {
            state.imin.printer.openCashBox()
          }

          return
        }

        const encoderOptions: ThermalPrinterEncoderOption = {
          width: 32,
          language: "esc-pos",
        }
        if (receiptSetting.width === 57) {
          encoderOptions.width = 32
        } else if (receiptSetting.width === 76) {
          encoderOptions.width = 42
        } else if (receiptSetting.width === 80) {
          encoderOptions.width = 48
        }

        if (trx.location.birEnabled) {
          const toPrint = await encodeBIRReceipt(
            {
              trx,
              business,
              nextSeriesNumber: options?.birSeriesNumber,
              nextORNumber: options?.birORNumber,
              invoiceSettings: receiptSetting,
              type: birReceipt,
            },
            encoderOptions
          )
          await state.receipt.printer?.print(toPrint)
        } else {
          const header = await printFullReceipt(
            business,
            trx,
            receiptSetting,
            posSetting,
            currency,
            encoderOptions
          )
          await state.receipt.printer?.print(header)
        }

        if (triggerOpenDrawer) {
          const openDrawer = encodeOpenDrawer()
          await state.receipt.printer?.print(openDrawer)
        }
      },
      printLabels: async (
        items: CartItem[],
        customerName?: string,
        settings?: PrinterSetting
      ) => {
        const { label } = get()

        const printerDpi = 203
        const maxWidthInMm = 104
        const labelHeightInMm = 20
        const labelWidthInMm = 50
        const labelSize = 1
        const labelGapInMm = 2

        const leftPadding = 30
        const topPadding = 20
        const textSpacing = 32

        const maxWidthInInch = maxWidthInMm / 25.4
        const ratio = labelWidthInMm / maxWidthInMm
        const dotsWidth = Math.floor(maxWidthInInch * printerDpi)
        const labelDotsWidth = Math.floor(ratio * dotsWidth)
        const originX = dotsWidth / 2 - labelDotsWidth / 2

        for (let item of items.filter((i) => i.printSticker)) {
          let i = 1

          const variant = item.product.productVariants.find(
            (variant: any) => variant.id === item.productVariant
          )

          const variantName = generateVariantNameFromVariantValues(
            variant?.variantValues
          )

          const addOns = item.orderedProductAddOns ?? []

          const tsplCommands = [
            `SIZE ${maxWidthInMm} mm,${labelHeightInMm} mm`,
            `GAP ${labelGapInMm ?? 2},0`,
            "CLS",
            `TEXT ${originX + leftPadding},${topPadding},"1",0,1,2,"${item.product.name}"`,
            `TEXT ${originX + leftPadding},${topPadding},"1",0,1,2,"${item.notes}"`,
            customerName
              ? `TEXT ${originX + leftPadding},${topPadding + textSpacing * i++},"1",0,1,2,"Customer: ${customerName}"`
              : "",
            variantName
              ? `TEXT ${originX + leftPadding},${topPadding + textSpacing * i++},"1",0,1,2,"(${variantName})"`
              : "",
            ...addOns?.map((addOn) => {
              return `TEXT ${originX + leftPadding},${topPadding + textSpacing * i++},"1",0,1,2," - ${addOn.productAddOns.name}"`
            }),
            `PRINT ${Math.max(item.qty, 1)}`,
            "END",
          ]
          const encoder = new TextEncoder()
          const commandBuffer = encoder.encode(tsplCommands.join("\r\n"))

          await label.printer?.print(commandBuffer)
        }
      },
      openDrawer: async () => {
        const openDrawer = encodeOpenDrawer()
        const receipt = get().receipt

        receipt.printer?.print(openDrawer)
      },
      isReceiptConnected: () => get().receipt.connected || get().imin.connected,
      printKitchenReceipt: async (
        id: string,
        trx: KitchenPrinterItem,
        activeUser: string,
        business: Business,
        currency: string = "USD",
        printerGroups?: GetKitchenPrinterGroupResponse,
        optionParam?: KitchenPrinterOptions
      ) => {
        const printers = get().kitchenPrinters
        const kitchenPrinter = printers.find((kp) => kp.id === id)
        if (!kitchenPrinter) {
          throw new Error("PRINTER_NOT_FOUND")
        }
        if (!kitchenPrinter.connected) {
          throw new Error("NOT_CONNECTED")
        }

        const group = printerGroups?.find(
          (kp) => kp.id === kitchenPrinter.printerGroup
        )

        const option = {
          ...optionParam,
          variant: group?.variant,
        }

        const updatedTrx = cloneDeep(trx)
        if (group?.categories?.length) {
          updatedTrx.products = updatedTrx.products.filter((p) => {
            return group.categories?.find((c) => c.id === p.categoryId)
          })
        }

        if (!updatedTrx.products.length) {
          return
        }

        if (group?.grouping === "PER_ITEM") {
          for (const product of updatedTrx.products) {
            let receipt: Uint8Array
            const singleItem = { ...updatedTrx, products: [product] }
            if (option?.format === "FULL") {
              receipt = encodeFullKitchenPrinter(
                singleItem,
                activeUser,
                business,
                currency,
                option
              )
            } else {
              receipt = encodeKitchenPrinter(
                singleItem,
                activeUser,
                business,
                option
              )
            }
            await kitchenPrinter.printer?.print(receipt)
          }
          return
        }

        let receipt: Uint8Array
        if (option?.format === "FULL") {
          receipt = encodeFullKitchenPrinter(
            updatedTrx,
            activeUser,
            business,
            currency,
            option
          )
        } else {
          receipt = encodeKitchenPrinter(
            updatedTrx,
            activeUser,
            business,
            option
          )
        }

        await kitchenPrinter.printer?.print(receipt)
      },
      printBill: async (
        trx: KitchenPrinterItem,
        activeUser: string,
        business: Business,
        currency?: string,
        optionParam?: KitchenPrinterOptions
      ) => {
        const receiptPrinter = get().receipt
        if (!trx.products.length) {
          return
        }
        let receipt = encodeFullKitchenPrinter(
          trx,
          activeUser,
          business,
          currency,
          optionParam
        )
        await receiptPrinter.printer?.print(receipt)
      },
      printTableQRCode: async (details: {
        tableName: string
        url: string
        expiryDate: string
        isStatic: boolean
      }) => {
        const { receipt } = get()
        if (!receipt.connected) {
          throw new Error("NOT_CONNECTED")
        }

        const qrCode = tableQRCCodes(details)
        await receipt.printer?.print(qrCode)
      },
      clearReceipt: async () => {
        const { receipt } = get()
        if (receipt.printer?.disconnect) {
          await receipt.printer?.disconnect()
        }
        set({ receipt: DEFAULT_STATE.receipt })
      },
      clearLabel: () => {
        const { label } = get()
        if (label.printer) {
          label.printer?.disconnect()
        }
        set({ label: DEFAULT_STATE.label })
      },
      addKitchenPrinter: () => {
        const { kitchenPrinters } = get()
        const id = nanoid()
        set({
          kitchenPrinters: [
            ...kitchenPrinters,
            {
              printer: null,
              connected: false,
              printerGroup: "",
              deviceId: null,
              id,
              type: "BLUETOOTH",
            },
          ],
        })
      },
      clearKitchenPrinter: (id: string) => {
        const { kitchenPrinters } = get()
        const updated = cloneDeep(kitchenPrinters)
        const target = updated.find((kp) => kp.id === id)
        if (target?.printer) {
          if (target?.printer?.disconnect) {
            target.printer.disconnect()
          }
          target.connected = false
          target.deviceId = null
        }
        set({ kitchenPrinters: updated })
      },
      disconnectAll: async () => {
        const { receipt, label, kitchenPrinters } = get()
        if (receipt.printer?.disconnect) {
          await receipt.printer?.disconnect()
        }
        if (label.printer) {
          await label.printer?.disconnect()
        }
        kitchenPrinters.forEach((kp) => {
          if (kp.printer?.disconnect) {
            kp.printer?.disconnect()
          }
        })

        set({
          receipt: {
            ...receipt,
            printer: null,
            connected: false,
          },
        })
        set({
          label: {
            ...label,
            printer: null,
            connected: false,
          },
        })
        set({
          kitchenPrinters: kitchenPrinters.map((kp) => ({
            ...kp,
            printer: null,
            connected: false,
          })),
        })
      },
      printXReport: async (
        details: {
          storeName: string
          operatedBy: string
          address: string
          vatRegTin: string
          min: string
          serialNo: string
          reportDate: string
          reportTime: string
          startDate: string
          endDate: string

          cashier: string

          begOR: string
          endOR: string

          openingFund: string

          // ---
          // Payment Received
          paymentReceivedBreakdown: Array<{
            paymentMethodCode: string
            sum: number
          }>
          totalPayments: string

          // ---

          void: string

          // ---

          refund: string

          // ---

          withdrawal: string

          // ---
          // Transaction Summary
          cashInDrawer: string
          transactionSummaryBreakdown: Array<{
            paymentMethodCode: string
            sum: number
          }>
          lessWithdrawal: string
          paymentReceived: string

          // ---
          shortOver: string
          // ---
          country?: string
          settings: InvoiceSettingsResponseBody[number]
          latestItemsSold?: any[]
          latestItemsRefunded?: any[]
        },
        encoderOptions: ThermalPrinterEncoderOption = {
          language: "esc-pos",
          width: 32,
        }
      ) => {
        const charWidth = convertPaperWidthToCharWidth(details.settings?.width)
        const encodedValues = encodeBIRXReport(details, {
          width: charWidth,
        })
        const receipt = get().receipt
        if (!receipt.connected) {
          throw new Error("NOT_CONNECTED")
        }
        await receipt.printer?.print(encodedValues.encode())
      },
      printTransferOrder: async (
        transferOrder: StockTransfer,
        items: Array<{
          itemName: string
          quantity: number
          pricePerQuantity: number
          totalPrice: number
        }>,
        encoderOptions: ThermalPrinterEncoderOption = {
          language: "esc-pos",
          width: 32,
        }
      ) => {
        const receiptPrinter = get().receipt
        let receipt = encodeTransferOrder(transferOrder, items, encoderOptions)
        await receiptPrinter.printer?.print(receipt)
      },
      printShiftReport: async (
        shiftReport: any,
        encoderOptions: ThermalPrinterEncoderOption = {
          language: "esc-pos",
          width: 32,
        }
      ) => {
        const receiptPrinter = get().receipt
        let receipt = encodeShiftReport(shiftReport, encoderOptions)
        await receiptPrinter.printer?.print(receipt)
      },
      printItemLabels: async (
        items: Array<{
          name: string
          price: string
          barcode: string
          quantity: number
        }>,
        printName: boolean = true,
        printPrice: boolean = true,
        encoderOptions: ThermalPrinterEncoderOption = {
          language: "esc-pos",
          width: 32,
        }
      ) => {
        const receiptPrinter = get().receipt
        let receipt = await encodeItemLabels(
          items,
          printName,
          printPrice,
          encoderOptions
        )
        await receiptPrinter.printer?.print(receipt)
      },
      syncWithCompanionApp: async (
        kitchenPrinters: Array<{
          identifier: string
          kitchenPrinterGroupId: string
        }>
      ) => {
        const printer = new CompanionAppThermalPrinter("RECEIPT")
        set({
          receipt: {
            printer,
            connected: true,
            type: "BLUETOOTH",
            deviceId: nanoid(),
          },
        })

        const newKitchenPrinters = kitchenPrinters.map((kp) => {
          const printer = new CompanionAppThermalPrinter(
            "KITCHEN",
            kp.identifier
          )
          return {
            printer,
            connected: true,
            printerGroup: kp.kitchenPrinterGroupId,
            deviceId: kp.identifier,
            id: nanoid(),
            type: "BLUETOOTH" as const,
          }
        })
        set({ kitchenPrinters: newKitchenPrinters })

        return {
          text: "Connected to companion app",
          value: 0,
        }
      },
    })),
    {
      name: "thermal-printer-settings-v2",
      // partialize: (state) => ({
      //   receipt: { id: state.receipt.id },
      //   kitchenPrinter: state.kitchenPrinters.map((kp) => ({
      //     id: kp.id,
      //     deviceId: kp.id,
      //   })),
      //   label: { id: state.label.id },
      //   imin: { width: state.imin.width },
      // }),
    }
  )
)

const verifyStatus = (status: number) => {
  if (status === -1 || status === 1) {
    throw new Error("Printer is not connected or powered on.")
  } else if (status === 3) {
    throw new Error("Printer is opened")
  } else if (status === 7) {
    throw new Error("No paper feed found")
  } else if (status === 8) {
    throw new Error("Printer is running out of paper")
  } else if (status === 99) {
    throw new Error("Printer is in an unknown state")
  } else if (status !== 0) {
    throw new Error("Unknown error")
  }

  return true
}

export const convertPaperWidthToCharWidth = (width?: number) => {
  if (width === 57) {
    return 32
  } else if (width === 76) {
    return 42
  } else if (width === 80) {
    return 48
  }

  return 32
}

export const createThermalPrinter = async (
  type: "BLUETOOTH" | "USB" | "SERIAL"
) => {
  let printer: ThermalPrinter
  if (type === "BLUETOOTH") {
    printer = new BluetoothThermalPrinter()
  } else if (type === "SERIAL") {
    printer = new SerialThermalPrinter()
  } else {
    printer = new UsbThermalPrinter()
  }
  await printer.connect()
  return printer
}

export default useThermalPrinter
