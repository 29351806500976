import React, {
  ChangeEventHandler,
  FC,
  forwardRef,
  MouseEventHandler,
  ReactElement,
} from "react"
import clsx from "clsx"
import ToolTipComponent from "./ToolTipComponent"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

export interface InputProps {
  label: string
  required?: boolean
  value?: string | number
  onChange?: ChangeEventHandler<HTMLInputElement>
  onClick?: MouseEventHandler<HTMLInputElement>
  placeholder?: string
  containerClassName?: string
  inputClassName?: string
  hideLabel?: boolean
  type?: string
  disabled?: boolean
  min?: number | string
  max?: number | string
  step?: string
  pattern?: string
  toolTipContent?: any
  toolTipPosition?: any
  trailingElement?: ReactElement | null
  autocomplete?: string
  onKeyPress?: (e: any) => void
  onKeyDown?: (e: any) => void
  labelClassName?: string
  isError?: boolean
  isValid?: boolean
  errorMessage?: string
  trailingElementClassName?: string
  helperText?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      required,
      label,
      placeholder,
      inputClassName,
      containerClassName,
      hideLabel,
      type,
      disabled,
      max,
      min,
      toolTipContent,
      toolTipPosition,
      step,
      pattern,
      trailingElement,
      autocomplete,
      onKeyPress,
      labelClassName,
      isError,
      isValid,
      errorMessage,
      trailingElementClassName,
      helperText,
      ...props
    },
    ref
  ) => (
    <label
      className={clsx("relative flex flex-col-reverse", containerClassName)}
    >
      {errorMessage && (
        <span
          className={clsx(
            "text-xs font-medium italic text-red-300 opacity-80 peer-disabled:opacity-50"
          )}
        >
          {errorMessage}
        </span>
      )}

      {helperText && (
        <p
          className={clsx(
            "text-gray-500 mt-1 ml-0.5 mb-1 flex flex-row items-center gap-2 text-sm font-medium opacity-80 peer-disabled:opacity-50"
          )}
        >
          {helperText}
        </p>
      )}

      <input
        type={type}
        required={required}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        pattern={pattern}
        className={clsx(
          inputClassName,
          "peer m-0 box-border h-[43px] w-full rounded-lg border border-solid border-gray-600 border-opacity-20  bg-white p-3 font-body shadow-sm placeholder:text-gray-400 focus:outline-primary-900 active:outline-primary-900 disabled:opacity-60",
          trailingElement && "pr-[40px]",
          isError &&
            "border-red-300 !bg-red-50 text-red-300 focus:outline-red-300 active:outline-red-300",
          disabled &&
            "!bg-gray-300 focus:border-gray-600 active:border-gray-600"
        )}
        onWheel={(event) => event.currentTarget.blur()}
        autoComplete={autocomplete}
        onKeyPress={onKeyPress}
        data-intercom-target={label + " input"}
        ref={ref}
        {...props}
      />

      {trailingElement && (
        <div
          className={clsx(
            "absolute bottom-[12px] right-[18px] flex h-[18px] items-center",
            trailingElementClassName
          )}
        >
          {trailingElement}
        </div>
      )}

      {isError && (
        <div className="absolute bottom-[12px] right-[18px] flex h-[18px] items-center">
          <HighlightOffIcon className="text-red-300" />
        </div>
      )}

      {isValid && (
        <div className="absolute bottom-[12px] right-[18px] flex h-[18px] items-center">
          <CheckCircleOutlineIcon className="text-green-300" />
        </div>
      )}

      {!hideLabel && (
        <span
          className={clsx(
            "mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50",
            hideLabel && "!opacity-0",
            labelClassName
          )}
        >
          {label}{" "}
          {required && <span className="font-semibold text-red-300">*</span>}{" "}
          {toolTipContent && (
            <ToolTipComponent
              toolTipContent={toolTipContent}
              toolTipPosition={toolTipPosition}
            />
          )}
        </span>
      )}
    </label>
  )
)

export default Input
