import {
  PromotionMetadata,
  Cart,
  CartItem,
  ManualDiscountType,
  Promotion,
  DeliveryDetails,
  ServiceCartItem,
} from "../../lib/models"
import {
  addItemToCart,
  decrementCartItem,
  incrementCartItem,
  removeItemFromCart,
  setPromotion,
  setCartItemQty,
  setPartialPayment,
  setSellingPrice,
  setDiscount,
  sync,
  setAddOnQuantity,
  updateItemNotes,
  setEmployees,
  updateItemDetails,
  setReferralCode,
} from "../../lib/cart"
import { combine, devtools } from "zustand/middleware"
import { create } from "zustand"
import Big from "big.js"
import { GetProductsResponse } from "../api/use-products"
import { calculateBundleStock } from "../../lib/product"
import { uuidv4 } from "@firebase/util"

const defaultValue: Cart = {
  promotion: null,
  items: [],
  manualDiscount: 0,
  printReceipt: true,
  deliveryDetails: null,
  localTransactionId: uuidv4(),
  referralCode: "",
}

const useCart = create(
  devtools(
    combine(defaultValue, (set, get) => ({
      increment: (item: CartItem) => set(incrementCartItem(item)),
      decrement: (item: CartItem) => set(decrementCartItem(item)),
      addItem: (
        item: CartItem,
        useWholeSalePrice?: boolean,
        customSellingPrice?: number,
        manualDiscount?: number
      ) =>
        set(
          addItemToCart(
            item,
            useWholeSalePrice,
            customSellingPrice,
            manualDiscount
          )
        ),
      updateItemNotes: (item: CartItem, notes: string) => {
        set(updateItemNotes(item, notes))
      },
      removeItem: (item: CartItem) => set(removeItemFromCart(item)),
      setPromotion: (
        promotion: Promotion | null,
        metadata?: PromotionMetadata
      ) => set(setPromotion(promotion, metadata)),
      clear: () => set({ ...defaultValue, localTransactionId: uuidv4() }),
      setQty: (item: CartItem, qty: number) => set(setCartItemQty(item, qty)),
      setSellingPrice: (
        item: CartItem,
        sellingPrice: number,
        isCustomPrice = true
      ) => set(setSellingPrice(item, sellingPrice, isCustomPrice)),
      setItemDiscount: (
        item: CartItem,
        discount: number,
        type: ManualDiscountType
      ) => {
        return set(setDiscount(item, discount, type))
      },
      setEmployees: (
        item: CartItem,
        employees: Array<{
          product?: string
          variant?: string
          employee: string
        }>
      ) => {
        return set(setEmployees(item, employees))
      },
      setPartialPayment: (item: CartItem, value: boolean) =>
        set(setPartialPayment(item, value)),
      setItemPrintSticker: (item: CartItem, value: boolean) =>
        set({
          items: get().items.map((i) =>
            i.id === item.id ? { ...i, printSticker: value } : i
          ),
        }),
      setManualDiscount: (value: number) => set({ manualDiscount: value }),
      calculateManualDiscount: () => {
        const { items, manualDiscount } = get()
        if (!manualDiscount) return 0
        if (!items.length) return 0
        const total = items.reduce(
          (acc, item) => acc.add(Big(item.sellingPrice).times(item.qty)),
          Big(0)
        )
        const discount = Big(manualDiscount).times(total).div(100)
        return discount.toNumber()
      },
      calculateItemDiscountAmount: (item: CartItem) => {
        return item.discount
          ? Big(item.sellingPrice)
              .times(item.discount)
              .div(100)
              .round(2)
              .toNumber()
          : 0
      },
      hasNegativeStockItems: () => {
        const { items } = get()
        let hasNegativeStockItems = false
        items.map((el) => {
          if (el.product.isBundle) {
            const stock = calculateBundleStock(
              el.product.bundleItems,
              el.location
            )
            console.log(stock)
            if (el.qty > stock) {
              hasNegativeStockItems = true
            }
          } else if (
            el.type === "GOODS" &&
            el.productLocation?.stockInHand !== undefined &&
            el.qty > el.productLocation?.stockInHand
          ) {
            hasNegativeStockItems = true
          }
        })
        return hasNegativeStockItems
      },
      sync: (products: GetProductsResponse, useWholesalePrice: boolean) =>
        set(sync(products, useWholesalePrice)),
      setAddOnQuantity: (item: CartItem, addOnId: string, qty: number) =>
        set(setAddOnQuantity(item, addOnId, qty)),
      hasBIRItems: () => {
        return get().items.some((el) => el.product.enableBIR)
      },
      setPrintReceipt: (value: boolean) => set({ printReceipt: value }),
      setDeliveryDetails: (details: DeliveryDetails) =>
        set({ deliveryDetails: details }),
      setQueueNumber: (queueNumber: number) => set({ queueNumber }),
      setPaymentId: (paymentId: string) => set({ paymentId: paymentId }),
      setDetails: (item: ServiceCartItem, details: any) =>
        set(updateItemDetails(item, details)),
      findItem: (productId: string, variantId?: string | null) => {
        return get().items.find(
          (i) =>
            i.product.id === productId &&
            (!variantId || i.productVariant === variantId)
        )
      },
      setReferralCode: (code: string) => set(setReferralCode(code)),
    })),
    { name: "pos-cart" }
  )
)

export default useCart
