import useBusiness from "../hooks/api/use-business"
import { GetEmployeeMeResponseBody } from "../hooks/api/use-employee-me"
import { deleteAPI, getAPI, patchAPI, postAPI } from "./api"

export type UserActivityPayload = {
  actionDetails: string | null
  actionType: string
  activity: string
  business: {
    id: string
  }
  concat: string | null
  created_at: string
  employee: {
    id: string | null
    email: string | null
    fullName: string | null
  }
  employeeId: string | null
  fullName: string | null
  text: string
}

export const deleteUserActivity = async (id: string) => {
  return deleteAPI("/user-activity", id)
}

export const patchUserActivity = (
  id: string,
  data: Partial<UserActivityPayload>
) => {
  return patchAPI<{
    id: string
  }>("/user-activity", id, data)
}

export const postUserActivity = (
  reportName: string,
  dataDate: string,
  businessId: string,
  employee: GetEmployeeMeResponseBody | null
) => {
  let data: UserActivityPayload = {
    actionDetails: null,
    actionType: "PRINT_REPORT",
    activity: `Print report: ${reportName}, date: ${dataDate}`,
    business: {
      id: businessId,
    },
    concat: null,
    created_at: new Date().toISOString(),
    employee: employee
      ? { id: employee.id, email: employee.email, fullName: employee.fullName }
      : { id: null, email: null, fullName: null },
    employeeId: employee ? employee.id : null,
    fullName: employee ? employee.fullName : null,
    text: `Print report: ${reportName}, date: ${dataDate}`,
  }
  return postAPI("/user-activity", data)
}
