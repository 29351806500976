import { ComponentProps, FC, ReactNode } from "react"
import { TextSnippet } from "@mui/icons-material"
import IconButton from "./IconButton"
import clsx from "clsx"
import Button from "./Button"

const DownloadTXTButton: FC<{
  text: string
  filename: string
  className?: string
  children?: ReactNode
  variant?: ComponentProps<typeof Button>["variant"]
  next?: () => void
}> = ({ text, filename, className, children, variant, next }) => {
  let TargetButton = Button
  if (!variant) {
    TargetButton = IconButton
  }

  return (
    <div>
      <TargetButton
        className={clsx(
          className,
          !variant && "h-[43px] w-[43px]",
          "text-primary-900"
        )}
        onClick={() => {
          const element = document.createElement("a")
          const file = new Blob([text], { type: "text/plain" })
          element.href = URL.createObjectURL(file)
          element.download = filename + ".txt"
          document.body.appendChild(element)
          element.click()

          next?.()
        }}
        variant={variant}
      >
        {children || <TextSnippet />}
      </TargetButton>
    </div>
  )
}

export default DownloadTXTButton
