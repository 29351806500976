import { lazy } from "react"
import PurchaseOrderDetails from "../Inventory/StockCatalog/PurchaseOrders/PurchaseOrderDetails"
import EmployeeTimesheet from "../HRMS/Report/EmployeeTimesheet"
import BIRZReading from "../Mpos/Report/BIRZReading"
import BIRZ from "../Mpos/Report/BIRZ"
import BIRJournal from "../Mpos/Report/BIRJournal"
import BIRXReading from "../Mpos/Report/BIRXReading"
import BIRX from "../Mpos/Report/BIRX"
import PeakSalesTimeReport from "../Mpos/Report/PeakSalesTime"

const Template = lazy(() => import("../BusinessSettings/Template"))
const IncomingPO = lazy(() => import("../Suppliers/IncomingPO"))
const PODetails = lazy(() => import("../Suppliers/IncomingPO/PODetails"))
const ManageInvoices = lazy(() => import("../Suppliers/ManageInvoices"))
const CreateSupplierAccountInvoice = lazy(
  () => import("../Suppliers/IncomingPO/PODetails/CreateInvoice")
)
const SupplierAccountPOInvoiceDetails = lazy(
  () => import("../Suppliers/ManageInvoices/InvoiceDetails")
)
const SupplierAccountEditPOInvoice = lazy(
  () => import("../Suppliers/ManageInvoices/InvoiceDetails/EditInvoice")
)
const CustomerPriceList = lazy(
  () => import("../BusinessSettings/Customers/CustomerPriceList")
)
const LoyaltyProgramReport = lazy(
  () => import("../Inventory/Report/LoyaltyProgram")
)
const PaymentTerms = lazy(() => import("../Inventory/PaymentTerms"))
const LeaveApprovals = lazy(
  () => import("../EmployeeManagement/Employee/LeaveApprovals")
)
const ImportEmployees = lazy(
  () => import("../EmployeeManagement/Employee/ImportEmployees")
)
const SalesByCategory = lazy(() => import("../Mpos/Report/SalesByCategory"))
const SalesByEmployees = lazy(() => import("../Mpos/Report/SalesByEmployees"))
const AccountingIntegration = lazy(
  () => import("../BusinessSettings/AccountingIntegration")
)
const ProfitLoss = lazy(() => import("../AccountingReports/ProfitLoss"))
const OperatingCashFlow = lazy(
  () => import("../AccountingReports/OperatingCashFlow")
)
const AgricultureReportList = lazy(
  () => import("../AgricultureInventory/Report")
)
const AgricultureRecordReport = lazy(
  () => import("../AgricultureInventory/Report/Record")
)
const AgricultureManagementReport = lazy(
  () => import("../AgricultureInventory/Report/Management")
)
const AgricultureInputReport = lazy(
  () => import("../AgricultureInventory/Report/Input")
)
const AccountsReceivable = lazy(
  () => import("../AccountingReports/AccountsReceivable")
)
const AccountsPayable = lazy(
  () => import("../AccountingReports/AccountsPayable")
)
const ChartOfAccountMaintenance = lazy(
  () => import("../AccountingReports/ChartOfAccountMaintenance")
)
const BalanceSheet = lazy(() => import("../AccountingReports/BalanceSheet"))
const AccountsReceivableDetail = lazy(
  () => import("../AccountingReports/AccountReceivableDetail")
)
const AccountJournal = lazy(() => import("../AccountingReports/AccountJournal"))
const AccountJournalForm = lazy(
  () => import("../AccountingReports/AccountJournal/JournalForm")
)
const BusinessSnapshot = lazy(() => import("../BusinessSnapshot"))
const BillPayment = lazy(() => import("../AccountingReports/BillPayment"))
const BIRreport = lazy(() => import("../Mpos/Report/BIR"))
const BIRDailyReport = lazy(() => import("../Mpos/Report/BIRDaily"))
const BIRMonthlyReport = lazy(() => import("../Mpos/Report/BIRMonthly"))
const AccountsPayableDetail = lazy(
  () => import("../AccountingReports/AccountsPayableDetail")
)
const FinancialAccounts = lazy(
  () => import("../AccountingReports/FinancialAccounts")
)
const FinancialStatements = lazy(
  () => import("../AccountingReports/FinancialStatements")
)
const FinancialRules = lazy(() => import("../AccountingReports/FinancialRules"))
const Expense = lazy(() => import("../AccountingReports/Expense"))
const SwitchToDizLog = lazy(() => import("../BusinessSettings/SwitchToDizlog"))
const TransactionDetailByTaxCode = lazy(
  () => import("../AccountingReports/TransactionDetailByTaxCode")
)
const TaxSummaryReport = lazy(() => import("../AccountingReports/TaxSummary"))
const TaxDetailReport = lazy(
  () => import("../AccountingReports/TaxDetailReport")
)
const Reconcile = lazy(() => import("../AccountingReports/Reconcile"))
const ProductionPage = lazy(() => import("../Inventory/Production"))
const AuditHistory = lazy(() => import("../Inventory/AuditHistory"))
const Projects = lazy(() => import("../AccountingReports/Projects"))
const TransactionReport = lazy(() => import("../TransactionReport"))
const Transactions = lazy(() => import("../AccountingReports/Transactions"))
const ManageIpayAfrica = lazy(
  () => import("../BusinessSettings/PaymentMethods/ManageIpayAfrica")
)
const ManageFiuu = lazy(
  () => import("../BusinessSettings/PaymentMethods/ManageFiuu")
)

const CustomerDisplaySetup = lazy(
  () => import("../BusinessSettings/Customers/CustomerDIsplaySetup")
)

const AdjustStock = lazy(() => import("../Inventory/AdjustStock"))
const EditPurchaseOrders = lazy(
  () => import("../Inventory/StockCatalog/PurchaseOrders/EditPurchaseOrders")
)
const CashRegisterReportPayInPayOut = lazy(
  () => import("../Mpos/Report/CashRegisterPayInPayOut")
)
const InvoiceReport = lazy(() => import("../Mpos/Report/Invoice"))
const SalesCommission = lazy(() => import("../Mpos/Report/SalesCommission"))
const SalesSummary = lazy(() => import("../Mpos/Report/SalesSummary"))
const SalesOverviewReports = lazy(
  () => import("../AccountingReports/SalesOverview")
)
const SalesDiscounts = lazy(() => import("../Mpos/Report/SalesDiscounts"))
const SalesTaxes = lazy(() => import("../Mpos/Report/SalesTaxes"))
const ServiceCharge = lazy(() => import("../Mpos/Report/ServiceCharge"))
const ShippingCost = lazy(() => import("../Mpos/Report/ShippingCost"))
const ProductsSoldTimeGraph = lazy(
  () => import("../Mpos/Report/ProductsSoldTimeGraph")
)
const LoyaltyProgram = lazy(() => import("../BusinessSettings/LoyaltyProgram"))
const Rewards = lazy(() => import("../BusinessSettings/Rewards"))
const InvoiceReceivingReport = lazy(
  () => import("../Mpos/Report/InvoiceReceiving")
)
const NewInventoryCount = lazy(
  () => import("../Inventory/InventoryCount/NewInventoryCount")
)
const EditInventoryCount = lazy(
  () => import("../Inventory/InventoryCount/EditInventoryCount")
)
const InventoryHistory = lazy(() => import("../Inventory/InventoryHistory"))
const CashRegisterExpenseReport = lazy(
  () => import("../Mpos/Report/CashRegisterExpenseReport")
)
const CountInventoryCount = lazy(
  () => import("../Inventory/InventoryCount/CountInventoryCount")
)
const AddProducts = lazy(() => import("../NewUIPages/AddProduct"))
const MonitorInventoryReport = lazy(
  () => import("../NewUIPages/InventoryReport")
)
const MonitorSalesReport = lazy(() => import("../NewUIPages/SalesReport"))
const ApplicationUsageReport = lazy(
  () => import("../NewUIPages/ApplicationUsageReport")
)
const Categories = lazy(() => import("../Inventory/Category"))

const Receipts = lazy(() => import("../Mpos/Receipt"))
const Taxes = lazy(() => import("../BusinessSettings/Taxes"))
const TransferStocks = lazy(() => import("../Inventory/TransferStock"))
const CashRegisterReport = lazy(() => import("../Mpos/Report/CashRegister"))
const EmployeePayrollReportApprovedClaim = lazy(
  () => import("../EmployeeManagement/PayrollReports/ApprovedClaim/index")
)
const EmployeePayrollReportEmployeeLoan = lazy(
  () => import("../EmployeeManagement/PayrollReports/EmployeeLoan/index")
)
const EmployeePayrollReportEmployeePaySummary = lazy(
  () => import("../EmployeeManagement/PayrollReports/EmployeePaySummary/index")
)
const EmployeePayrollReportOutstandingLoan = lazy(
  () => import("../EmployeeManagement/PayrollReports/OutstandingLoan/index")
)
const EmployeePayrollReportPayrunDetail = lazy(
  () => import("../EmployeeManagement/PayrollReports/PayrunDetail/index")
)
const HRMSReport = lazy(() => import("../HRMS/Report"))
const TimesheetManagement = lazy(
  () => import("../HRMS/Report/TimesheetManagement")
)
const OverTime = lazy(() => import("../HRMS/Report/OverTime"))
const LeaveSummary = lazy(() => import("../HRMS/Report/LeaveSummary"))
const EmployeeOnLeave = lazy(() => import("../HRMS/Report/EmployeeOnLeave"))
const PayrollReport = lazy(() => import("../PayrollManagement/Report"))
const ApprovedClaim = lazy(
  () => import("../PayrollManagement/Report/ApprovedClaim")
)
const EmployeeLoan = lazy(
  () => import("../PayrollManagement/Report/EmployeeLoan")
)
const EmployeePaySummary = lazy(
  () => import("../PayrollManagement/Report/EmployeePaySummary")
)
const OutstandingLoan = lazy(
  () => import("../PayrollManagement/Report/OutstandingLoan")
)
const PayrunDetail = lazy(
  () => import("../PayrollManagement/Report/PayrunDetail")
)
const TaxSummary = lazy(() => import("../PayrollManagement/Report/TaxSummary"))
const PayrunDetailByPayItem = lazy(
  () => import("../PayrollManagement/Report/PayrunDetailByPayItem")
)
const SalesDetailByPaymentMethod = lazy(
  () => import("../Mpos/Report/SalesDetailByPaymentMethod")
)
const SalesByCustomer = lazy(() => import("../Mpos/Report/SalesByCustomer"))
const SalesByItem = lazy(() => import("../Mpos/Report/SalesByItem"))
const SalesByEmployee = lazy(() => import("../Mpos/Report/SalesByEmployee"))
const CustomerBalances = lazy(() => import("../Mpos/Report/CustomerBalances"))
const CreditSalesOrderSummary = lazy(
  () => import("../Mpos/Report/CreditSalesOrderSummary")
)
const CreditSalesOrderDetail = lazy(
  () => import("../Mpos/Report/CreditSalesOrderDetail")
)
const SalesSummaryByPaymentMethod = lazy(
  () => import("../Mpos/Report/SalesSummaryByPaymentMethod")
)
const Payrun = lazy(() => import("../PayrollManagement/Payrun"))
const PayrunEmployee = lazy(
  () => import("../EmployeeManagement/PayrunReport/index")
)
const MyEStore = lazy(() => import("../MyEStore"))
const SystemSettings = lazy(() => import("../SystemSettings"))
const EmployeeManagement = lazy(() => import("../EmployeeManagement"))
const OnlineStore = lazy(() => import("../BusinessSettings/OnlineStore"))
const Customers = lazy(() => import("../BusinessSettings/Customers"))
const PaymentMethods = lazy(() => import("../BusinessSettings/PaymentMethods"))
const SubscriptionAndsBillings = lazy(
  () => import("../BusinessSettings/SubscriptionAndBillings")
)
const ReceiptSettings = lazy(
  () => import("../BusinessSettings/ReceiptSettings")
)
const ShippingMethods = lazy(
  () => import("../BusinessSettings/ShippingMethods")
)
const Promotions = lazy(() => import("../BusinessSettings/Promotions"))
const PayrollSettingsMain = lazy(
  () => import("../PayrollManagement/PayrollSettingsMain")
)
const Invoicing = lazy(() => import("../Invoicing"))
const ManageIpay88 = lazy(
  () => import("../BusinessSettings/PaymentMethods/ManageIpay88")
)
const ManageDiglogPayments = lazy(
  () => import("../BusinessSettings/PaymentMethods/ManageDiglogPayments")
)
const ManageBux = lazy(
  () => import("../BusinessSettings/PaymentMethods/ManageBux")
)
const ManageCashfree = lazy(
  () => import("../BusinessSettings/PaymentMethods/ManageCashfree")
)
const Customization = lazy(() => import("../BusinessSettings/Customization"))
const OrderManagement = lazy(() => import("../Mpos/OrderManagement"))
const TermsAndPrivacyPolicy = lazy(
  () => import("../BusinessSettings/TermsAndPrivacyPolicy")
)
const BuyNewDomain = lazy(
  () => import("../BusinessSettings/OnlineStore/BuyNewDomain")
)
const ConnectExistingDomain = lazy(
  () => import("../BusinessSettings/OnlineStore/ConnectExistingDomain")
)
const OrdersReport = lazy(() => import("../Mpos/Report/OrdersReport"))
const ProductOrdersAndReturns = lazy(
  () => import("../Mpos/Report/ProductOrdersAndReturns")
)
const OrdersOverTime = lazy(() => import("../Mpos/Report/OrdersOverTime"))
const FulfillmentShippingAndDelivery = lazy(
  () => import("../Mpos/Report/FulfillmentShippingAndDelivery")
)
const FulfillmentOverTime = lazy(
  () => import("../Mpos/Report/FulfillmentOverTime")
)
const PosSettings = lazy(() => import("../BusinessSettings/PosSettings"))
const PosLayout = lazy(() => import("../BusinessSettings/PosLayout"))
const EstoreDomain = lazy(
  () => import("../BusinessSettings/OnlineStore/EstoreDomain")
)
const Notifications = lazy(() => import("../Notifications"))
const CustomersOverTime = lazy(() => import("../Mpos/Report/CustomersOverTime"))
const ReturningCustomers = lazy(
  () => import("../Mpos/Report/ReturningCustomers")
)
const OneTimeCustomers = lazy(() => import("../Mpos/Report/OneTimeCustomers"))
const PaymentsFinanceReport = lazy(
  () => import("../Mpos/Report/PaymentsFinanceReport")
)
const AverageInventoryPerDay = lazy(
  () => import("../Mpos/Report/AverageInventoryPerDay")
)
const RetailSalesByProduct = lazy(
  () => import("../Mpos/Report/RetailSalesByProduct")
)
const RetailSalesByProductVendor = lazy(
  () => import("../Mpos/Report/RetailSalesByProductVendor")
)
const RetailSalesByProductType = lazy(
  () => import("../Mpos/Report/RetailSalesByProductType")
)
const RetailSalesByPOSLocation = lazy(
  () => import("../Mpos/Report/RetailSalesByPOSLocation")
)
const SalesOverTime = lazy(() => import("../Mpos/Report/SalesOverTime"))
const SalesByProduct = lazy(() => import("../Mpos/Report/SalesByProduct"))
const SalesByChannel = lazy(() => import("../Mpos/Report/SalesByChannel"))
const SalesByVendor = lazy(() => import("../Mpos/Report/SalesByVendor"))
const SalesByLocation = lazy(() => import("../Mpos/Report/SalesByLocation"))
const ProfitByProduct = lazy(() => import("../Mpos/Report/ProfitByProduct"))
const LifetimeDeals = lazy(() => import("../BusinessSettings/LifetimeDeals"))
const DeleteBusinessAccount = lazy(
  () => import("../BusinessSettings/DeleteBusinessAccount")
)
const DomainPurchaseReceipt = lazy(
  () => import("../BusinessSettings/OnlineStore/DomainPurchaseReceipt")
)
const ProductMix = lazy(() => import("../Mpos/ProductMix"))
const Ingredients = lazy(() => import("../Inventory/Ingredients"))
const InventoryAging = lazy(() => import("../Inventory/InventoryAging"))
const IngredientReport = lazy(() => import("../Inventory/IngredientReport"))
const InventoryValuation = lazy(() => import("../Inventory/InventoryValuation"))
const MySuppliers = lazy(() => import("../Suppliers/MySuppliers"))
const OpenRegister = lazy(() => import("../Mpos/OpenRegister"))
const CloseRegister = lazy(() => import("../Mpos/CloseRegister"))
const POInvoice = lazy(() => import("../Inventory/POInvoice"))
const NewPOInvoice = lazy(() => import("../Inventory/POInvoice/NewPOInvoice"))
const EditPOInvoice = lazy(() => import("../Inventory/POInvoice/EditPOInvoice"))

const POSDashboard = lazy(() => import("../Mpos"))
const InventoryReport = lazy(() => import("../Inventory/Report"))
const InventorySummary = lazy(
  () => import("../Inventory/Report/InventorySummary")
)
const InventoryByCategory = lazy(
  () => import("../Inventory/Report/InventoryByCategory")
)
const InventoryByCategoryDetail = lazy(
  () => import("../Inventory/Report/InventoryByCategory/Details")
)
const OutOfStockItem = lazy(() => import("../Inventory/Report/OutOfStocks"))
const LowOnStockItem = lazy(() => import("../Inventory/Report/LowOnStocks"))
const ReOrderStockItem = lazy(() => import("../Inventory/Report/ReOrderStocks"))
const ExpiringSoonStockItem = lazy(
  () => import("../Inventory/Report/ExpiringSoonSummary")
)
const InventoryItem = lazy(() => import("../Inventory/Report/InventoryItem"))
const MposReport = lazy(() => import("../Mpos/Report"))
// const InvoiceReport = lazy(() => import("../Invoicing/Reports"))
const HRMS = lazy(() => import("../HRMS"))
const EmployeePayrollDashboard = lazy(
  () => import("../EmployeeManagement/PayrollDashboard")
)
const EmployeePayrollReport = lazy(
  () => import("../EmployeeManagement/PayrollReports")
)

const BusinessProfile = lazy(() => import("../BusinessSettings"))
const Payroll = lazy(() => import("../PayrollManagement"))
const Inventory = lazy(() => import("../Inventory"))
const StockCatalog = lazy(() => import("../Inventory/StockCatalog"))
const InventoryCount = lazy(() => import("../Inventory/InventoryCount"))
const PreviewInventoryCount = lazy(
  () => import("../Inventory/InventoryCount/PreviewInventoryCount")
)
const KitchenPrinters = lazy(() => import("../Mpos/KitchenPrinters"))
const PrintLabels = lazy(() => import("../Inventory/PrintLabels"))
const ImportCustomers = lazy(
  () => import("../BusinessSettings/Customers/ImportCustomers")
)
const ImportSuppliers = lazy(
  () => import("../Suppliers/MySuppliers/ImportSuppliers")
)
const MeasurementUnits = lazy(() => import("../Inventory/MeasurementUnits"))
const RegisterReport = lazy(() => import("../Mpos/RegisterReport"))
const Printers = lazy(() => import("../BusinessSettings/Printers"))
const Pricelist = lazy(() => import("../Inventory/Pricelist"))
const KitchenDisplay = lazy(() => import("../Mpos/KitchenDisplay"))
const KitchenDisplayItems = lazy(
  () => import("../Mpos/KitchenDisplay/KitchenDisplayItems")
)
const ClaimsApprovals = lazy(
  () => import("../EmployeeManagement/Employee/ClaimsApprovals")
)
const AgricultureRecord = lazy(() => import("../AgricultureInventory/Record"))
const AgricultureInput = lazy(() => import("../AgricultureInventory/Input"))
const AgriMaintenance = lazy(
  () => import("../AgricultureInventory/Maintenance")
)
const UserActivityReport = lazy(() => import("../Mpos/Report/UserActivity"))
const BIRSeniorCitizenSalesReport = lazy(
  () => import("../Mpos/Report/BIRSeniorCitizen")
)
const BIRPWDReport = lazy(() => import("../Mpos/Report/BIRPWD"))
const BIRNAACReport = lazy(() => import("../Mpos/Report/BIRNAAC"))
const SubscriptionHistory = lazy(
  () => import("../BusinessSettings/SubscriptionHistory")
)
const TransferOrders = lazy(() => import("../Inventory/TransferOrders"))
const TransferOrderForm = lazy(
  () => import("../Inventory/TransferOrders/TransferOrderForm")
)
const ViewTransferOrder = lazy(
  () => import("../Inventory/TransferOrders/ViewTransferOrder")
)
const Attendance = lazy(() => import("../EmployeeManagement/Attendance"))
const Approvals = lazy(() => import("../EmployeeManagement/Employee/Approvals"))
const StockAdjustment = lazy(
  () => import("../Inventory/Report/StockAdjustment")
)
const SalesByEmployeeTransactions = lazy(
  () => import("../Mpos/Report/SalesByEmployees/SalesByEmployeeTransactions")
)

const routes = [
  {
    path: "/dashboard/business-snapshot",
    exact: true,
    component: BusinessSnapshot,
  },
  {
    path: "/dashboard/business-settings/profile",
    exact: true,
    component: BusinessProfile,
  },
  {
    path: "/dashboard/employees/report/timesheet-management",
    exact: true,
    component: TimesheetManagement,
  },
  {
    path: "/dashboard/employees/report/employee-timesheet",
    exact: true,
    component: EmployeeTimesheet,
  },
  {
    path: "/dashboard/payroll/dashboard",
    exact: true,
    component: Payroll,
  },
  {
    path: "/dashboard/inventory/dashboard",
    exact: true,
    component: Inventory,
  },
  {
    path: "/dashboard/mpos/dashboard",
    exact: true,
    component: POSDashboard,
  },
  {
    path: "/dashboard/mpos/receipts",
    exact: true,
    component: Receipts,
    mainWrapperClass: "p-0",
  },
  {
    path: "/dashboard/mpos/open-register",
    exact: true,
    component: OpenRegister,
    disableContainer: true,
  },
  {
    path: "/dashboard/mpos/close-register",
    exact: true,
    component: CloseRegister,
    disableContainer: true,
  },
  {
    path: "/dashboard/inventory/report",
    exact: true,
    component: InventoryReport,
  },
  {
    path: "/dashboard/inventory/report/summary",
    exact: true,
    component: InventorySummary,
  },
  {
    path: "/dashboard/inventory/report/inventory-category",
    exact: true,
    component: InventoryByCategory,
  },
  {
    path: "/dashboard/inventory/report/aging",
    exact: true,
    component: InventoryAging,
  },
  {
    path: "/dashboard/inventory/report/valuation",
    exact: true,
    component: InventoryValuation,
  },
  {
    path: "/dashboard/inventory/report/ingredients",
    exact: true,
    component: IngredientReport,
  },
  {
    path: "/dashboard/inventory/report/inventory-category/:id",
    exact: true,
    component: InventoryByCategoryDetail,
  },
  {
    path: "/dashboard/inventory/report/out-of-stock",
    exact: true,
    component: OutOfStockItem,
  },
  {
    path: "/dashboard/inventory/report/low-on-stock",
    exact: true,
    component: LowOnStockItem,
  },
  {
    path: "/dashboard/customers/loyalty-program",
    exact: true,
    component: LoyaltyProgramReport,
  },
  {
    path: "/dashboard/inventory/report/re-order-stock",
    exact: true,
    component: ReOrderStockItem,
  },
  {
    path: "/dashboard/inventory/report/expire-soon-stock",
    exact: true,
    component: ExpiringSoonStockItem,
  },
  {
    path: "/dashboard/inventory/report/inventory-items",
    exact: true,
    component: InventoryItem,
  },
  {
    path: "/dashboard/mpos/report",
    exact: true,
    component: MposReport,
  },
  // {
  //   path: "/dashboard/invoicing/invoices/reports",
  //   exact: true,
  //   component: InvoiceReport,
  // },
  {
    path: "/dashboard/employees/dashboard",
    exact: true,
    component: HRMS,
  },
  {
    path: "/dashboard/employees/payroll-dashboard",
    exact: true,
    component: EmployeePayrollDashboard,
  },
  {
    path: "/dashboard/employees/report",
    exact: true,
    component: HRMSReport,
  },
  {
    path: "/dashboard/employees/payroll-report",
    exact: true,
    component: EmployeePayrollReport,
  },
  {
    path: "/dashboard/employees/payroll-report/approved-claim",
    exact: true,
    component: EmployeePayrollReportApprovedClaim,
  },
  {
    path: "/dashboard/employees/report/approved-claim",
    exact: true,
    component: EmployeePayrollReportApprovedClaim,
  },
  {
    path: "/dashboard/employees/payroll-report/employee-loan",
    exact: true,
    component: EmployeePayrollReportEmployeeLoan,
  },
  {
    path: "/dashboard/employees/report/employee-loan",
    exact: true,
    component: EmployeePayrollReportEmployeeLoan,
  },
  {
    path: "/dashboard/employees/payroll-report/employee-pay-summary",
    exact: true,
    component: EmployeePayrollReportEmployeePaySummary,
  },
  {
    path: "/dashboard/employees/report/employee-pay-summary",
    exact: true,
    component: EmployeePayrollReportEmployeePaySummary,
  },
  {
    path: "/dashboard/employees/payroll-report/outstanding-loan",
    exact: true,
    component: EmployeePayrollReportOutstandingLoan,
  },
  {
    path: "/dashboard/employees/report/outstanding-loan",
    exact: true,
    component: EmployeePayrollReportOutstandingLoan,
  },
  {
    path: "/dashboard/employees/report/payrun-detail",
    exact: true,
    component: EmployeePayrollReportPayrunDetail,
  },
  {
    path: "/dashboard/employees/payroll-report/payrun-detail",
    exact: true,
    component: EmployeePayrollReportPayrunDetail,
  },
  {
    path: "/dashboard/employees/payroll-report/payrun-detail-by-pay-item",
    exact: true,
    component: PayrunDetailByPayItem,
  },
  {
    path: "/dashboard/employees/report/over-time",
    exact: true,
    component: OverTime,
  },
  {
    path: "/dashboard/employees/report/leave-summary",
    exact: true,
    component: LeaveSummary,
  },
  {
    path: "/dashboard/employees/report/employee-on-leave",
    exact: true,
    component: EmployeeOnLeave,
  },
  {
    path: "/dashboard/payroll/report",
    exact: true,
    component: PayrollReport,
  },
  {
    path: "/dashboard/payroll/report/approved-claim",
    exact: true,
    component: ApprovedClaim,
  },
  {
    path: "/dashboard/payroll/report/employee-loan",
    exact: true,
    component: EmployeeLoan,
  },
  {
    path: "/dashboard/payroll/report/employee-pay-summary",
    exact: true,
    component: EmployeePaySummary,
  },
  {
    path: "/dashboard/payroll/report/outstanding-loan",
    exact: true,
    component: OutstandingLoan,
  },
  {
    path: "/dashboard/payroll/report/payrun-detail",
    exact: true,
    component: PayrunDetail,
  },
  {
    path: "/dashboard/accounting-reports/sales-overview*",
    exact: true,
    component: SalesOverviewReports,
  },
  {
    path: "/dashboard/accounting-reports/profit-loss*",
    exact: true,
    component: ProfitLoss,
  },
  {
    path: "/dashboard/accounting-reports/operating-cash-flow*",
    exact: true,
    component: OperatingCashFlow,
  },
  {
    path: "/dashboard/accounting-reports/chart-of-accounts-maintenance",
    exact: true,
    component: ChartOfAccountMaintenance,
  },
  {
    path: "/dashboard/accounting-reports/accounts-receivable",
    exact: true,
    component: AccountsReceivable,
  },
  {
    path: "/dashboard/accounting-reports/receivable-detail",
    exact: true,
    component: AccountsReceivableDetail,
  },
  {
    path: "/dashboard/accounting-reports/accounts-payable-detail",
    exact: true,
    component: AccountsPayableDetail,
  },
  {
    path: "/dashboard/accounting-reports/accounts-payable*",
    exact: true,
    component: AccountsPayable,
  },
  {
    path: "/dashboard/accounting-reports/balance-sheet",
    exact: true,
    component: BalanceSheet,
  },
  {
    path: "/dashboard/accounting-reports/bill-payments*",
    exact: true,
    component: BillPayment,
  },
  {
    path: "/dashboard/accounting-reports/expense*",
    exact: true,
    component: Expense,
  },
  {
    path: "/dashboard/accounting-reports/financial-accounts*",
    exact: true,
    component: FinancialAccounts,
  },
  {
    path: "/dashboard/accounting-reports/financial-statements*",
    exact: true,
    component: FinancialStatements,
  },
  {
    path: "/dashboard/accounting-reports/financial-rules*",
    exact: true,
    component: FinancialRules,
  },
  {
    path: "/dashboard/accounting-reports/transaction-details*",
    exact: true,
    component: TransactionDetailByTaxCode,
  },
  {
    path: "/dashboard/mpos/report/sales-detail-by-payment-method",
    exact: true,
    component: SalesDetailByPaymentMethod,
  },
  {
    path: "/dashboard/mpos/report/sales-by-customer",
    exact: true,
    component: SalesByCustomer,
  },
  {
    path: "/dashboard/mpos/report/sales-by-customer/:customerId",
    exact: true,
    component: SalesByCustomer,
  },
  {
    path: "/dashboard/mpos/report/peak-sales-time",
    exact: true,
    component: PeakSalesTimeReport,
  },
  {
    path: "/dashboard/mpos/report/sales-by-employees",
    exact: true,
    component: SalesByEmployees,
  },
  {
    path: "/dashboard/mpos/report/sales-by-employees/:employeeId",
    exact: true,
    component: SalesByEmployees,
  },
  {
    path: "/dashboard/mpos/report/sales-by-employees/:locationId/:startDate/:endDate/:employeeId",
    exact: true,
    component: SalesByEmployeeTransactions,
  },
  {
    path: "/dashboard/mpos/report/sales-summary",
    exact: true,
    component: SalesSummary,
  },
  {
    path: "/dashboard/mpos/report/sales-discounts",
    exact: true,
    component: SalesDiscounts,
  },
  {
    path: "/dashboard/mpos/report/sales-taxes",
    exact: true,
    component: SalesTaxes,
  },
  {
    path: "/dashboard/mpos/report/service-charge",
    exact: true,
    component: ServiceCharge,
  },
  {
    path: "/dashboard/mpos/report/shipping-cost",
    exact: true,
    component: ShippingCost,
  },
  {
    path: "/dashboard/mpos/report/products-sold-time",
    exact: true,
    component: ProductsSoldTimeGraph,
  },
  {
    path: "/dashboard/mpos/report/sales-by-item",
    exact: true,
    component: SalesByItem,
  },
  {
    path: "/dashboard/mpos/report/sales-by-employee",
    exact: true,
    component: SalesByEmployee,
  },
  {
    path: "/dashboard/mpos/report/customer-balances",
    exact: true,
    component: CustomerBalances,
  },
  {
    path: "/dashboard/mpos/report/credit-sales-order-summary",
    exact: true,
    component: CreditSalesOrderSummary,
  },
  {
    path: "/dashboard/mpos/report/credit-sales-order-detail",
    exact: true,
    component: CreditSalesOrderDetail,
  },
  {
    path: "/dashboard/mpos/report/sales-summary-by-payment-method",
    exact: true,
    component: SalesSummaryByPaymentMethod,
  },
  {
    path: "/dashboard/payroll/report/tax-summary",
    exact: true,
    component: TaxSummary,
  },
  {
    path: "/dashboard/payroll/report/payrun-detail-by-pay-item",
    exact: true,
    component: PayrunDetailByPayItem,
  },
  {
    path: "/dashboard/business-settings/employee",
    exact: false,
    component: PayrollSettingsMain,
  },
  {
    path: "/dashboard/business-settings/accounting-integration",
    exact: false,
    component: AccountingIntegration,
  },
  {
    path: "/dashboard/business-settings/taxes",
    exact: false,
    component: Taxes,
  },
  {
    path: "/dashboard/payroll/payrun",
    exact: true,
    component: Payrun,
  },
  {
    path: "/dashboard/inventory/recipes",
    exact: true,
    component: Ingredients,
  },
  {
    path: "/dashboard/inventory/category*",
    exact: true,
    component: Categories,
  },
  {
    path: "/dashboard/inventory/supplier-invoice",
    exact: true,
    component: POInvoice,
  },
  {
    path: "/dashboard/inventory/stock-history",
    exact: true,
    component: InventoryHistory,
  },
  {
    path: "/dashboard/inventory/supplier-invoice/new",
    exact: true,
    component: NewPOInvoice,
  },
  {
    path: "/dashboard/inventory/supplier-invoice/:id",
    exact: true,
    component: EditPOInvoice,
  },
  {
    path: "/dashboard/my-estore",
    exact: true,
    component: MyEStore,
  },
  {
    path: "/dashboard/inventory/stock-catalog/purchase-orders/:id",
    exact: true,
    component: EditPurchaseOrders,
  },
  {
    path: "/dashboard/inventory/stock-catalog/purchase-orders/:id/details",
    exact: true,
    component: PurchaseOrderDetails,
  },
  {
    path: "/dashboard/inventory/stock-catalog*",
    exact: true,
    component: StockCatalog,
  },
  {
    path: "/dashboard/inventory/transfer",
    exact: true,
    component: TransferStocks,
    disableContainer: true,
  },
  {
    path: "/dashboard/inventory/adjust-stock",
    exact: true,
    component: AdjustStock,
    disableContainer: true,
  },
  {
    path: "/dashboard/inventory/inventory-count",
    exact: true,
    component: InventoryCount,
    disableContainer: true,
  },
  {
    path: "/dashboard/inventory/inventory-count/new",
    exact: true,
    component: NewInventoryCount,
    disableContainer: true,
  },

  {
    path: "/dashboard/inventory/inventory-count/:id",
    exact: true,
    component: PreviewInventoryCount,
    disableContainer: true,
  },
  {
    path: "/dashboard/inventory/inventory-count/:id/count",
    exact: true,
    component: CountInventoryCount,
    disableContainer: true,
  },
  {
    path: "/dashboard/inventory/inventory-count/:id/edit",
    exact: true,
    component: EditInventoryCount,
    disableContainer: true,
  },
  {
    path: "/dashboard/system-settings*",
    exact: true,
    component: SystemSettings,
  },
  {
    path: "/dashboard/employees/manage-employees",
    exact: false,
    component: EmployeeManagement,
  },
  {
    path: "/dashboard/employees/payrun-report",
    exact: false,
    component: PayrunEmployee,
  },
  {
    path: "/dashboard/business-settings/online-store*",
    exact: true,
    component: OnlineStore,
  },
  {
    path: "/dashboard/customers/list",
    exact: true,
    component: Customers,
  },
  {
    path: "/dashboard/suppliers/my-suppliers",
    exact: true,
    component: MySuppliers,
  },
  {
    path: "/dashboard/suppliers/incoming-po",
    exact: true,
    component: IncomingPO,
  },
  {
    path: "/dashboard/suppliers/incoming-po/:id",
    exact: true,
    component: PODetails,
  },
  {
    path: "/dashboard/suppliers/incoming-po/:id/create-invoice",
    exact: true,
    component: CreateSupplierAccountInvoice,
  },
  {
    path: "/dashboard/suppliers/manage-invoices",
    exact: true,
    component: ManageInvoices,
  },
  {
    path: "/dashboard/suppliers/manage-invoices/:id",
    exact: true,
    component: SupplierAccountPOInvoiceDetails,
  },
  {
    path: "/dashboard/suppliers/manage-invoices/:id/edit",
    exact: true,
    component: SupplierAccountEditPOInvoice,
  },
  {
    path: "/dashboard/business-settings/payment-methods",
    exact: true,
    component: PaymentMethods,
  },
  {
    path: "/dashboard/business-settings/subscription-and-billings",
    exact: true,
    component: SubscriptionAndsBillings,
  },
  {
    path: "/dashboard/business-settings/lifetime-deals",
    exact: true,
    component: LifetimeDeals,
  },
  {
    path: "/dashboard/business-settings/receipt-settings",
    exact: true,
    component: ReceiptSettings,
  },
  {
    path: "/dashboard/business-settings/shipping-methods",
    exact: true,
    component: ShippingMethods,
  },
  {
    path: "/dashboard/business-settings/promotions",
    exact: true,
    component: Promotions,
  },
  {
    path: "/dashboard/business-settings/loyalty-program",
    exact: true,
    component: LoyaltyProgram,
  },
  {
    path: "/dashboard/inventory/customer-price-list",
    exact: true,
    component: CustomerPriceList,
  },
  {
    path: "/dashboard/business-settings/rewards",
    exact: true,
    component: Rewards,
  },
  {
    path: "/dashboard/invoicing*",
    exact: true,
    component: Invoicing,
  },
  // {
  //   path: "/dashboard/business-settings/expense-income-category",
  //   exact: true,
  //   component: ExpenseIncomeCategory,
  // },
  // {
  //   path: "/dashboard/business-settings/expense-income-category/import",
  //   exact: true,
  //   component: ImportAccounts,
  // },
  {
    path: "/dashboard/business-settings/pos-settings*",
    exact: true,
    component: PosSettings,
  },
  {
    path: "/dashboard/business-settings/pos-layout*",
    exact: true,
    component: PosLayout,
  },
  {
    path: "/dashboard/business-settings/payment-methods/ipay88",
    exact: true,
    component: ManageIpay88,
  },
  {
    path: "/dashboard/business-settings/payment-methods/diglog-payments",
    exact: true,
    component: ManageDiglogPayments,
  },
  {
    path: "/dashboard/business-settings/payment-methods/bux",
    exact: true,
    component: ManageBux,
  },
  {
    path: "/dashboard/business-settings/payment-methods/cashfree",
    exact: true,
    component: ManageCashfree,
  },
  {
    path: "/dashboard/business-settings/payment-methods/ipay-africa",
    exact: true,
    component: ManageIpayAfrica,
  },
  {
    path: "/dashboard/business-settings/payment-methods/fiuu",
    exact: true,
    component: ManageFiuu,
  },
  {
    path: "/dashboard/business-settings/customization",
    exact: true,
    component: Customization,
  },
  {
    path: "/dashboard/mpos/order-management*",
    exact: true,
    component: OrderManagement,
  },
  {
    path: "/dashboard/business-settings/terms-and-privacy-policy",
    exact: true,
    component: TermsAndPrivacyPolicy,
  },
  {
    path: "/dashboard/mpos/report/orders-report",
    exact: true,
    component: OrdersReport,
  },
  {
    path: "/dashboard/mpos/report/cash-register",
    exact: true,
    component: CashRegisterReport,
  },
  {
    path: "/dashboard/mpos/report/cash-register/pay-in-pay-out",
    exact: true,
    component: CashRegisterReportPayInPayOut,
  },
  {
    path: "/dashboard/mpos/report/cash-register/expenses",
    exact: true,
    component: CashRegisterExpenseReport,
  },
  {
    path: "/dashboard/mpos/report/product-orders-and-returns",
    exact: true,
    component: ProductOrdersAndReturns,
  },
  {
    path: "/dashboard/mpos/report/orders-over-time",
    exact: true,
    component: OrdersOverTime,
  },
  {
    path: "/dashboard/mpos/report/fulfillment-shipping-and-delivery",
    exact: true,
    component: FulfillmentShippingAndDelivery,
  },
  {
    path: "/dashboard/mpos/report/fulfillment-over-time",
    exact: true,
    component: FulfillmentOverTime,
  },
  {
    path: "/dashboard/notifications",
    exact: true,
    component: Notifications,
  },
  {
    path: "/dashboard/mpos/report/customers-over-time",
    exact: true,
    component: CustomersOverTime,
  },
  {
    path: "/dashboard/mpos/report/returning-customers",
    exact: true,
    component: ReturningCustomers,
  },
  {
    path: "/dashboard/mpos/report/one-time-customers",
    exact: true,
    component: OneTimeCustomers,
  },
  {
    path: "/dashboard/mpos/report/payments-finance-report",
    exact: true,
    component: PaymentsFinanceReport,
  },
  {
    path: "/dashboard/mpos/report/average-inventory-per-day",
    exact: true,
    component: AverageInventoryPerDay,
  },
  {
    path: "/dashboard/mpos/report/retail-sales-by-product",
    exact: true,
    component: RetailSalesByProduct,
  },
  {
    path: "/dashboard/mpos/report/retail-sales-by-product-vendor",
    exact: true,
    component: RetailSalesByProductVendor,
  },
  {
    path: "/dashboard/mpos/report/retail-sales-by-product-type",
    exact: true,
    component: RetailSalesByProductType,
  },
  {
    path: "/dashboard/mpos/report/retail-sales-by-pos-location",
    exact: true,
    component: RetailSalesByPOSLocation,
  },
  {
    path: "/dashboard/mpos/report/sales-over-time",
    exact: true,
    component: SalesOverTime,
  },
  {
    path: "/dashboard/mpos/report/sales-commission",
    exact: true,
    component: SalesCommission,
  },
  {
    path: "/dashboard/mpos/report/invoices",
    exact: true,
    component: InvoiceReport,
  },
  {
    path: "/dashboard/mpos/report/receiving-invoices",
    exact: true,
    component: InvoiceReceivingReport,
  },
  {
    path: "/dashboard/mpos/report/bir-journal",
    exact: true,
    component: BIRJournal,
  },
  {
    path: "/dashboard/mpos/report/bir",
    exact: true,
    component: BIRreport,
  },
  {
    path: "/dashboard/mpos/report/bir-z",
    exact: true,
    component: BIRZReading,
  },
  {
    path: "/dashboard/mpos/report/bir-z/details",
    exact: true,
    component: BIRZ,
  },
  {
    path: "/dashboard/mpos/report/bir-x",
    exact: true,
    component: BIRXReading,
  },
  {
    path: "/dashboard/mpos/report/bir-x/details",
    exact: true,
    component: BIRX,
  },
  {
    path: "/dashboard/mpos/report/bir-daily",
    exact: true,
    component: BIRDailyReport,
  },
  {
    path: "/dashboard/mpos/report/bir-monthly",
    exact: true,
    component: BIRMonthlyReport,
  },
  {
    path: "/dashboard/mpos/report/user-activity",
    exact: true,
    component: UserActivityReport,
  },
  {
    path: "/dashboard/mpos/report/bir-senior-citizen",
    exact: true,
    component: BIRSeniorCitizenSalesReport,
  },
  {
    path: "/dashboard/mpos/report/bir-pwd",
    exact: true,
    component: BIRPWDReport,
  },
  {
    path: "/dashboard/mpos/report/bir-naac",
    exact: true,
    component: BIRNAACReport,
  },
  {
    path: "/dashboard/mpos/report/sales-by-product",
    exact: true,
    component: SalesByProduct,
  },
  {
    path: "/dashboard/mpos/report/sales-by-category",
    exact: true,
    component: SalesByCategory,
  },
  {
    path: "/dashboard/mpos/report/product-mix",
    exact: true,
    component: ProductMix,
  },
  {
    path: "/dashboard/mpos/report/sales-by-vendor",
    exact: true,
    component: SalesByVendor,
  },
  {
    path: "/dashboard/mpos/report/sales-by-channel",
    exact: true,
    component: SalesByChannel,
  },
  {
    path: "/dashboard/mpos/report/sales-by-location",
    exact: true,
    component: SalesByLocation,
  },
  {
    path: "/dashboard/mpos/report/profit-by-product",
    exact: true,
    component: ProfitByProduct,
  },
  {
    path: "/dashboard/business-settings/delete-business-account",
    exact: true,
    component: DeleteBusinessAccount,
  },
  {
    path: "/dashboard/business-settings/templates*",
    exact: true,
    component: Template,
  },
  {
    path: "/dashboard/business-settings/online-store/domain/purchase-receipt",
    exact: true,
    component: DomainPurchaseReceipt,
  },
  {
    path: "/dashboard/monitor/add-products",
    exact: true,
    component: AddProducts,
  },
  {
    path: "/dashboard/monitor/inventory-report",
    exact: true,
    component: MonitorInventoryReport,
  },
  {
    path: "/dashboard/monitor/sales-report",
    exact: true,
    component: MonitorSalesReport,
  },
  {
    path: "/dashboard/monitor/application-usage-report",
    exact: true,
    component: ApplicationUsageReport,
  },
  {
    path: "/dashboard/mpos/kitchen-printers",
    exact: true,
    component: KitchenPrinters,
  },
  {
    path: "/dashboard/inventory/print-labels",
    exact: true,
    component: PrintLabels,
  },
  {
    path: "/dashboard/business-settings/printers",
    exact: true,
    component: Printers,
  },
  {
    path: "/dashboard/business-settings/customer/customer-display*",
    exact: true,
    component: CustomerDisplaySetup,
  },
  {
    path: "/dashboard/employee-management/employee/import",
    exact: true,
    component: ImportEmployees,
  },
  {
    path: "/dashboard/suppliers/import",
    exact: true,
    component: ImportSuppliers,
  },
  {
    path: "/dashboard/customers/import",
    exact: true,
    component: ImportCustomers,
  },
  {
    path: "/dashboard/inventory/measurement-units",
    exact: true,
    component: MeasurementUnits,
  },
  {
    path: "/dashboard/inventory/payment-terms",
    exact: true,
    component: PaymentTerms,
  },
  {
    path: "/dashboard/mpos/register-report",
    exact: true,
    component: RegisterReport,
  },
  {
    path: "/dashboard/inventory/price-list",
    exact: true,
    component: Pricelist,
  },
  {
    path: "/dashboard/mpos/kitchen-display",
    exact: true,
    component: KitchenDisplay,
  },
  {
    path: "/dashboard/mpos/kitchen-display/items/:id",
    exact: true,
    component: KitchenDisplayItems,
  },
  // {
  //   path: "/dashboard/employees/approval/leave",
  //   exact: true,
  //   component: LeaveApprovals,
  // },
  // {
  //   path: "/dashboard/employees/approval/claims",
  //   exact: true,
  //   component: ClaimsApprovals,
  // },
  {
    path: "/dashboard/employees/approval",
    exact: true,
    component: Approvals,
  },
  {
    path: "/dashboard/agriculture/record",
    exact: true,
    component: AgricultureRecord,
  },
  {
    path: "/dashboard/agriculture/input",
    exact: true,
    component: AgricultureInput,
  },
  {
    path: "/dashboard/agriculture/maintenance",
    exact: true,
    component: AgriMaintenance,
  },
  {
    path: "/dashboard/agriculture/reports",
    exact: true,
    component: AgricultureReportList,
  },
  {
    path: "/dashboard/agriculture/reports/record",
    exact: true,
    component: AgricultureRecordReport,
  },
  {
    path: "/dashboard/agriculture/reports/management",
    exact: true,
    component: AgricultureManagementReport,
  },
  {
    path: "/dashboard/agriculture/reports/input",
    exact: true,
    component: AgricultureInputReport,
  },
  {
    path: "/dashboard/accounting-reports/account-journal",
    exact: true,
    component: AccountJournal,
  },
  {
    path: "/dashboard/accounting-reports/projects*",
    exact: true,
    component: Projects,
  },
  {
    path: "/dashboard/accounting-reports/account-journal-form",
    exact: true,
    component: AccountJournalForm,
  },
  {
    path: "/dashboard/accounting-reports/reconcile*",
    exact: true,
    component: Reconcile,
  },
  {
    path: "/dashboard/accounting-reports/transactions*",
    exact: true,
    component: Transactions,
  },
  {
    path: "/dashboard/business-settings/switch-to-dizlog",
    exact: true,
    component: SwitchToDizLog,
  },
  {
    path: "/dashboard/mpos/report/tax-summary",
    exact: true,
    component: TaxSummaryReport,
  },
  {
    path: "/dashboard/mpos/report/tax-detail",
    exact: true,
    component: TaxDetailReport,
  },
  {
    path: "/dashboard/inventory/productions",
    exact: true,
    component: ProductionPage,
  },
  {
    path: "/dashboard/inventory/history",
    exact: true,
    component: AuditHistory,
  },
  {
    path: "/dashboard/mpos/transactions",
    exact: true,
    component: TransactionReport,
  },
  {
    path: "/dashboard/business-settings/subscription-history",
    exact: true,
    component: SubscriptionHistory,
  },
  {
    path: "/dashboard/inventory/transfer-orders",
    exact: true,
    component: TransferOrders,
  },
  {
    path: "/dashboard/inventory/transfer-orders/new",
    exact: true,
    component: TransferOrderForm,
  },
  {
    path: "/dashboard/inventory/transfer-orders/edit",
    exact: true,
    component: TransferOrderForm,
  },
  {
    path: "/dashboard/inventory/transfer-orders/view",
    exact: true,
    component: ViewTransferOrder,
  },
  // {
  //   path: "/dashboard/accounting-reports/time-activity",
  //   exact: true,
  //   component: TimeActivity,
  // },
  {
    path: "/dashboard/employees/attendance",
    exact: true,
    component: Attendance,
  },
  {
    path: "/dashboard/inventory/report/stock-adjustment",
    exact: true,
    component: StockAdjustment,
  },
]

export default routes
