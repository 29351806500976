import { FC, Fragment, useRef } from "react"
import { formatNumberToCurrency } from "../../../../lib/currency"
import Big from "big.js"
import useInvoiceSettings from "../../../../hooks/api/use-invoice-settings"
import useBusinessCurrency from "../../../../hooks/use-business-currency"
import useBirJournal, {
  GetBIRJournalResponse,
} from "../../../../hooks/api/use-bir-journal"
import { connectPrinter } from "../../../../hooks/use-thermal-printer"
import { Tooltip } from "@mui/material"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import IconButton from "../../../../components/IconButton"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import PrintIcon from "@mui/icons-material/Print"
import { format, parseISO } from "date-fns"
import usePrimaryLocation from "../../../../hooks/api/use-primary-location"
import useBusiness, { Business } from "../../../../hooks/api/use-business"
import { BIR_LABEL, BIRJournalFilter } from "../../../../lib/models"
import useEmployees from "../../../../hooks/api/use-employees"
import useCustomers from "../../../../hooks/api/use-customers"
import useCashRegister from "../../../../hooks/api/use-cash-register"
import { useReactToPrint } from "react-to-print"
import html2PDF from "jspdf-html2canvas"
import { encodeBIRJournalHeader } from "../../../../lib/thermal-printer/bir-journal"
import dedent from "dedent"
import { Locations } from "../../../../hooks/api/use-locations"
import DownloadTXTButton from "../../../../components/DownloadTXTButton"
import { BIRZReport } from "../BIRZ"
import { BIRXReport } from "../BIRX"
import { printToThermal } from "../../../../lib/thermal-printer/helper"
import { postUserActivity } from "../../../../lib/user-activity"
import useEmployeeMe from "../../../../hooks/api/use-employee-me"
import { AxiosError } from "axios"
import { useShowSnackbar } from "../../../../components/Snackbar"

const ReportPreview: FC<{
  filter: BIRJournalFilter
}> = ({ filter }) => {
  const { data: primaryLocation } = usePrimaryLocation()
  const { data: business } = useBusiness()
  const { data: employees } = useEmployees()
  const { data: customers } = useCustomers()
  const { data: cashRegisters } = useCashRegister()
  const { data: transactions } = useBirJournal({
    location: filter.location === "all" ? undefined : filter.location,
    endDate: filter.endDate,
    startDate: filter.startDate,
  })
  const { data: me } = useEmployeeMe()
  const showSnackbar = useShowSnackbar()

  const printTarget = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printTarget.current,
  })
  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => printTarget.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument
      if (document) {
        const html = document.getElementsByTagName("html")[0]
        const exporter = await html2PDF(html)
        await exporter.save("bir-journal-report.pdf")
      }
    },
  })

  const currentDate = format(new Date(), "MMM dd, yyyy hh:mm:ss a")
  const startDate = format(new Date(filter.startDate), "MMM dd, yyyy")
  const endDate = format(new Date(filter.endDate), "MMM dd, yyyy")
  const employeeName =
    filter.employee === "all"
      ? "All Employees"
      : employees?.find((e) => e.id === filter.employee)?.fullName
  const customerName =
    filter.customer === "all"
      ? "All Customers"
      : customers?.find((e) => e.id === filter.customer)?.fullName
  const cashRegisterName =
    filter.cashRegister === "all"
      ? "All Cash Registers"
      : cashRegisters?.find((e) => e.id === filter.cashRegister)?.name
  let transactionType =
    filter.transactionType === "all" ? "All Type" : filter.transactionType
  if (filter.transactionType === "DONE") {
    transactionType = "Done"
  } else if (filter.transactionType === "VOID") {
    transactionType = "Void"
  } else if (filter.transactionType === "RETURN_ACCEPTED") {
    transactionType = "Return Accepted"
  } else if (filter.transactionType === "PENDING") {
    transactionType = "Pending"
  }
  const paymentMethod =
    filter.paymentMethod === "all" ? "All Methods" : filter.paymentMethod

  let transaction =
    filter.transaction === "all" ? "All Transaction" : filter.transaction
  if (filter.transaction === "REPRINT") {
    transaction = "Reprint"
  } else if (filter.transaction === "VOID") {
    transaction = "Void"
  } else if (filter.transaction === "REFUND") {
    transaction = "Refund"
  }

  const filteredTransactions = transactions
    ?.filter(
      (t) =>
        (filter.cashRegister === "all" ||
          t.cashRegister?.id === filter.cashRegister) &&
        (filter.employee === "all" || t.createdBy === filter.employee) &&
        (filter.transaction === "all" || t.type === filter.transaction) &&
        (filter.transactionType === "all" ||
          t.orderStatus === filter.transactionType) &&
        (filter.customer === "all" || t.customer?.id === filter.customer) &&
        (filter.paymentMethod === "all" ||
          t.paymentMethodCode === filter.paymentMethod ||
          t.paymentChannel === filter.paymentMethod)
    )
    ?.map(calculateTransactionDetails)

  const handlePrintThermal = async () => {
    const { server, characteristic } = await connectPrinter()
    const c = characteristic

    const encodedValues = encodeBIRJournalHeader({
      storeName: business?.name || "",
      address: primaryLocation?.addressLine1 || "",
      currentDate,
      startDate,
      endDate,
      employeeName: employeeName || "-",
      customerName: customerName || "-",
      cashRegisterName: cashRegisterName || "-",
      transaction,
      transactionType,
      paymentMethod,
      transactions: filteredTransactions || [],
    })

    await printToThermal(c, encodedValues)
    server.disconnect()
  }

  const createUserActivity = async () => {
    try {
      const res = await postUserActivity(
        "BIR Journal",
        `${startDate} - ${endDate}`,
        business?.id || "",
        me?.id ? me : null
      )
    } catch (error) {
      if (error instanceof AxiosError) {
        showSnackbar(error.response?.data.message, "error")
      } else {
        showSnackbar("Error creating user activity log.", "error")
      }
    }
  }

  return (
    <div className="flex-1 w-2/3 flex flex-col items-center justify-center mb-16">
      <div className={"w-full flex justify-end pt-6 pr-6"}>
        <DownloadTXTButton
          text={generateJournalTXTExport(
            {
              currentDate,
              startDate,
              endDate,
              employeeName: employeeName || "",
              customerName: customerName || "",
              cashRegisterName: cashRegisterName || "",
              transactionType,
              paymentMethod,
              transaction,
            },
            primaryLocation,
            filteredTransactions || [],
            business
          )}
          filename={"journal"}
          next={createUserActivity}
        />
        <Tooltip title={"Download"}>
          <IconButton
            className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
            onClick={async () => {
              handleDownload()
              await createUserActivity()
            }}
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Export as PDF"}>
          <IconButton
            className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
            onClick={async () => {
              handlePrint()
              await createUserActivity()
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Print"}>
          <IconButton
            className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
            onClick={async () => {
              handlePrintThermal()
              await createUserActivity()
            }}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className={"w-full max-w-xl"}>
        <div
          className="bg-gray-100 w-full print:max-w-none mt-6 p-6 print:bg-white"
          ref={printTarget}
        >
          <h2 className={"text-center font-bold"}>Account Journal</h2>
          <h2 className={"text-center font-bold mt-6"}>{business?.name}</h2>
          <p className={"text-center capitalize"}>
            {primaryLocation?.addressLine1}, {primaryLocation?.city},{" "}
            {primaryLocation?.state}, {business?.country.name}
          </p>

          <div className="flex border-t-2 border-gray-400 mt-6 border-dashed py-6">
            <div className={"w-1/2"}>
              <p>
                <b>Date:</b> {currentDate}
              </p>
              <p>
                <b>Period covered:</b>{" "}
                <span className={"whitespace-nowrap"}>
                  {startDate} - {endDate}
                </span>
              </p>
              <p>
                <b>Staff:</b> {employeeName}
              </p>
              <p>
                <b>Customer:</b> {customerName}
              </p>
            </div>
            <div className={"w-1/2"}>
              <p>
                <b>Cash Register:</b> {cashRegisterName}
              </p>
              <p>
                <b>Transaction:</b> {transaction}
              </p>
              <p>
                <b>Transaction Type:</b> {transactionType}
              </p>
              <p>
                <b>Payment Method:</b>{" "}
                <span className={"capitalize"}>
                  {paymentMethod.toLowerCase()}
                </span>
              </p>
            </div>
          </div>

          {filteredTransactions?.map((trx) => {
            if (trx.rowType === "TRANSACTION") {
              return <TransactionEntry trx={trx} />
            } else if (trx.rowType === "BIR_Z_REPORT" && trx.birZReport) {
              return (
                <div
                  className={"py-6 border-t-2 border-dashed border-gray-400"}
                >
                  <BIRZReport
                    report={trx.birZReport}
                    currentDate={parseISO(trx.created_at)}
                    disableMaxWidth
                    hideHeader
                    containerClassName={"!p-0"}
                  />
                </div>
              )
            }
            if (trx.rowType === "BIR_X_REPORT" && trx.birXReport) {
              return (
                <div
                  className={"py-6 border-t-2 border-dashed border-gray-400"}
                >
                  <BIRXReport
                    report={trx.birXReport}
                    currentDate={parseISO(trx.created_at)}
                    disableMaxWidth
                    hideHeader
                    containerClassName={"!p-0"}
                  />
                </div>
              )
            }

            return null
          })}

          <div>
            <p className={"font-bold text-center mt-32"}>---- END ----</p>
          </div>
        </div>
      </div>
    </div>
  )
}
const TransactionEntry: FC<{
  trx: ReturnType<typeof calculateTransactionDetails>
}> = ({ trx }) => {
  const currency = useBusinessCurrency()

  return (
    <div className="flex flex-col border-t-2 border-gray-400 border-dashed py-6">
      <div className={"flex gap-4"}>
        <div className={"flex-1"}>
          <p className={"font-bold"}>Invoice No. {trx.ORNumber}</p>
          <p>Date: {trx.date}</p>
          <p>Staff: {trx.cashierName}</p>
          <p>Customer: {trx.customer?.fullName || "-"}</p>
        </div>
        <div className={"flex-1"}>
          <p className={"font-bold"}>Trans No. {trx.seriesNumber}</p>
          <p>Cash Register: {trx.cashRegister?.name}</p>
          <p>Transaction: {trx.receiptType}</p>
          <p>Transaction Type: {trx.orderStatus}</p>
        </div>
      </div>

      <div className={"my-2 flex justify-between font-bold"}>
        <p className={"text-center"}>Item(s)</p>
        <p className={"text-center"}>{trx.orderedProducts.length}</p>
      </div>

      <Items
        totalCartAmount={trx.totalCartAmount}
        orderedProducts={trx.orderedProducts as any}
        tax={0}
      />

      <div className={"flex justify-between mt-6"}>
        <p>Sub Total</p>
        <p>
          {formatNumberToCurrency(
            currency,
            trx.totalCartAmount + trx.totalIncludedTaxes
          )}
        </p>
      </div>

      <div className={"flex justify-between"}>
        <p>Total Sales</p>
        <p>{formatNumberToCurrency(currency, trx.totalCartAmount)}</p>
      </div>

      <ul className={"list-disc pl-4"}>
        {trx.discount > 0 && (
          <li>
            <div className={"mb-1 flex justify-between"}>
              <p>
                {BIR_LABEL[trx.promotionDetails?.[0]?.code || ""] || "Discount"}{" "}
                {trx.promotionDetails?.[0]?.discountPercentage}%
              </p>
              <p>{formatNumberToCurrency(currency, trx.nonTaxDiscounts)}</p>
            </div>
          </li>
        )}
        {trx.taxes?.map((t) => {
          return (
            <li>
              <div key={t.name} className={"mb-1 flex justify-between"}>
                <div className={"flex"}>
                  <p>{t.name}</p>
                  <span className={"ml-1"}>{t.rate}%</span>
                  {t.type === "INCLUDED" && (
                    <span className={"ml-2 capitalize"}>
                      ({t.type.toLowerCase()})
                    </span>
                  )}
                </div>
                <p>{formatNumberToCurrency(currency, t.amount)}</p>
              </div>
            </li>
          )
        })}
      </ul>
      <div className={"mb-1 flex justify-between"}>
        <p>Total amount due</p>
        <p>{formatNumberToCurrency(currency, trx.grandTotal)}</p>
      </div>

      <p className={"mt-6"}>
        <b>Payment Method:</b>{" "}
        <span className={"capitalize"}>
          {trx.paymentMethodCode === "CUSTOM"
            ? trx.paymentChannel?.toLowerCase()
            : trx.paymentMethodCode.toLowerCase()}
        </span>
      </p>
      <div className={"flex justify-between mb-1 mt-4"}>
        <p>Cash Received</p>
        <p>{formatNumberToCurrency(currency, trx.cashReceived)}</p>
      </div>
      <div className={"mb-1 flex justify-between"}>
        <p>Cash Change</p>
        <p>{formatNumberToCurrency(currency, trx.cashChange || 0)}</p>
      </div>

      <div className={"mt-6"}>
        <table className="w-full">
          <tbody>
            <tr>
              <td></td>
              <td className={"text-right font-bold"}>Net.Amt</td>
              <td className={"text-right font-bold"}>VAT</td>
              <td className={"text-right font-bold"}>Amount</td>
            </tr>
            <tr>
              <td>VATABL</td>
              <td className={"text-right"}>{trx.vatSales.toFixed(2)}</td>
              <td className={"text-right"}>{trx.totalTaxApplied.toFixed(2)}</td>
              <td className={"text-right"}>
                {(trx.vatSales + trx.totalTaxApplied).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>EXEMPT</td>
              <td className={"text-right"}>{trx.vatExemptSales.toFixed(2)}</td>
              <td className={"text-right"}>
                {trx.totalTaxExemption.toFixed(2)}
              </td>
              <td className={"text-right"}>
                {(trx.vatExemptSales + trx.totalTaxExemption).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>ZERO-R</td>
              <td className={"text-right"}>{trx.zeroRatesSales.toFixed(2)}</td>
              <td className={"text-right"}>0.00</td>
              <td className={"text-right"}>{trx.zeroRatesSales.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const Items: FC<{
  orderedProducts: GetBIRJournalResponse[number]["orderedProducts"]
  totalCartAmount: number
  tax: number
}> = ({ totalCartAmount, orderedProducts, tax }) => {
  const currency = useBusinessCurrency()
  const { data: settings } = useInvoiceSettings()

  return (
    <div className={"mb-2"}>
      {orderedProducts.map((p) => {
        const variantName = p.productVariants?.map((el) => el.name).join(" / ")

        const priceRatio = totalCartAmount
          ? Big(p.totalPrice).div(totalCartAmount)
          : 0
        const taxForProduct = Big(tax).mul(priceRatio)
        const originalSellingPrice = Big(p.sellingPrice).add(
          taxForProduct.div(p.quantity)
        )

        return (
          <Fragment key={p.id}>
            <div className={"flex justify-between"}>
              <p>
                <span className={"font-bold"}>{p.name} </span>{" "}
                <span>({p.unitOfMeasure})</span> - {p.quantity}x @
                {originalSellingPrice.round(2).toNumber()}
              </p>
              <p>
                {formatNumberToCurrency(
                  currency,
                  originalSellingPrice.mul(p.quantity).round(2).toNumber()
                )}
              </p>
            </div>
            {!!p.addOns?.length && (
              <div>
                <div className="mr-1">Add Ons:</div>
                <ol className="pl-1">
                  {p.addOns.map((el) => (
                    <li className="flex flex-row justify-between">
                      <span>
                        {el.name} - {el.quantity} x @{el.sellingPrice}
                      </span>
                      <span>
                        {formatNumberToCurrency(
                          currency,
                          el.sellingPrice * el.quantity
                        )}
                      </span>
                    </li>
                  ))}
                </ol>
              </div>
            )}

            {settings?.showSKU && <p>SKU {p.SKU}</p>}
            {variantName && <p>Variant: {variantName}</p>}
          </Fragment>
        )
      })}
    </div>
  )
}

const calculateTransactionDetails = (trx: GetBIRJournalResponse[number]) => {
  if (trx.rowType !== "TRANSACTION") {
    return {
      ...trx,
      orderStatus: "",
      receiptType: "",
      totalIncludedTaxes: 0,
      date: "",
      vatSales: 0,
      vatExemptSales: 0,
      totalTax: 0,
      totalTaxExemption: 0,
      totalTaxApplied: 0,
      zeroRatesSales: 0,
    }
  }

  let vatSales = 0
  trx.orderedProducts
    .filter((p) => {
      if (trx.taxExemptions?.length && p.enableBIR) {
        return false
      }
      return p.taxable
    })
    .forEach((p) => {
      vatSales += p.sellingPrice * p.quantity
    })
  vatSales = vatSales
    ? Big(vatSales).minus(trx.discount).round(2).toNumber()
    : 0

  let vatExemptSales = 0
  if (trx.taxExemptions?.length) {
    trx.orderedProducts
      .filter((p) => p.enableBIR)
      .forEach((p) => {
        vatExemptSales += p.sellingPrice * p.quantity
      })
    vatExemptSales = Big(vatExemptSales).minus(trx.discount).round(2).toNumber()
  }

  const totalTax =
    trx?.taxes
      ?.reduce((acc, tax) => acc.add(tax.amount), Big(0))
      .round(2)
      .toNumber() ?? 0
  const totalTaxExemption =
    trx?.taxExemptions?.reduce((acc, tax) => acc + (tax?.amount || 0), 0) ?? 0
  const totalTaxApplied = totalTax - totalTaxExemption

  let zeroRatesSales = 0
  trx.orderedProducts
    .filter((p) => !p.taxable)
    .forEach((p) => {
      zeroRatesSales += p.sellingPrice * p.quantity
    })

  const date = format(new Date(trx.created_at), "dd/MM/yyyy, hh:mm:ss a")

  const totalIncludedTaxes =
    trx.taxes
      ?.filter((t) => t.type === "INCLUDED")
      ?.reduce((acc, tax) => acc + tax.amount, 0) || 0

  let receiptType = "Sale"
  if (trx.type === "REPRINT") {
    receiptType = "Reprint"
  } else if (trx.type === "VOID") {
    receiptType = "Void"
  } else if (trx.type === "REFUND") {
    receiptType = "Refund"
  }

  let orderStatus = trx.orderStatus
  if (trx.orderStatus === "DONE") {
    orderStatus = "Done"
  } else if (trx.orderStatus === "VOID") {
    orderStatus = "Void"
  } else if (trx.orderStatus === "RETURN_ACCEPTED") {
    orderStatus = "Return Accepted"
  } else if (trx.orderStatus === "PENDING") {
    orderStatus = "Pending"
  }

  return {
    ...trx,
    orderStatus,
    receiptType,
    totalIncludedTaxes,
    date,
    vatSales,
    vatExemptSales,
    totalTax,
    totalTaxExemption,
    totalTaxApplied,
    zeroRatesSales,
  }
}

const generateJournalTXTExport = (
  filters: {
    currentDate: string
    startDate: string
    endDate: string
    employeeName: string
    customerName: string
    cashRegisterName: string
    transactionType: string
    paymentMethod: string
    transaction: string
  },
  location: Locations | undefined,
  transactions: ReturnType<typeof calculateTransactionDetails>[],
  business: Business | undefined
) => {
  return dedent`
  Account Journal
  ${business?.name}
  ${location?.addressLine1}, ${location?.city}, ${location?.state}, ${business?.country.name} 
  
  Date: ${filters.currentDate}
  Period covered: ${filters.startDate} - ${filters.endDate}
  Staff: ${filters.employeeName}
  Customer: ${filters.customerName}
  Cash Register: ${filters.cashRegisterName}
  Transaction: ${filters.transaction}
  Transaction Type: ${filters.transactionType}
  Payment Method: ${filters.paymentMethod}
  
  ${transactions
    .map((trx) => {
      if (trx.rowType === "TRANSACTION") {
        return dedent`
        ---
        
        Invoice No. ${trx.ORNumber}
        Trans No. ${trx.seriesNumber}
        Date: ${trx.date}
        Staff: ${trx.cashierName}
        Customer: ${trx.customer?.fullName || "-"}
        Cash Register: ${trx.cashRegister?.name}
        Transaction: ${trx.receiptType}
        Transaction Type: ${trx.orderStatus}
        
        ${trx.orderedProducts.map((p) => {
          const variantName = p.productVariants
            ?.map((el) => el.name)
            .join(" / ")
          return dedent`
            ${p.name} (${p.unitOfMeasure}) - ${p.quantity}x @${p.sellingPrice}
            ${p.addOns?.map((el) => {
              return dedent`
                Add Ons:
                ${el.name} - ${el.quantity} x @${el.sellingPrice}
              `
            })}
            ${variantName ? `Variant: ${variantName}` : ""}
            ${p.SKU ? `SKU: ${p.SKU}` : ""}
          `
        })} 
        
        Sub Total: ${trx.totalCartAmount + trx.totalIncludedTaxes}
        Total Sales: ${trx.totalCartAmount}
        
        ${trx.discount > 0 ? `Discount: ${trx.discount}` : ""}
        ${
          trx.taxes?.length
            ? trx.taxes
                .map((t) => `${t.name} ${t.rate}% :${t.amount}`)
                .join("\n")
            : ""
        }
        ${trx.taxExemptions
          ?.map((t) => `Less ${t?.name} ${t?.rate}% :${t?.amount}`)
          .join("\n")}
        Total amount due: ${trx.grandTotal} 
        Cash received: ${trx.cashReceived} 
        Cash change: ${trx.cashChange}
        
        VATable Sales
        Net amount: ${trx.vatSales}
        VAT: ${trx.totalTaxApplied}
        Amount: ${trx.vatSales + trx.totalTaxApplied}
        
        Exempt Sales
        Net amount: ${trx.vatExemptSales}
        VAT: ${trx.totalTaxExemption}
        Amount: ${trx.vatExemptSales + trx.totalTaxExemption}
        
        Zero Rated Sales
        Net amount: ${trx.zeroRatesSales}
        VAT: 0
        Amount: ${trx.zeroRatesSales}
        `
      }
    })
    .join("\n")}
  `
}

export default ReportPreview
