import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import { Business, DEFAULT_MPOS_SETTING } from "../models"
import { format, parseISO } from "date-fns"
import {
  getActualCashChange,
  getActualCashReceived,
  GetCartTransactionResponse,
} from "../../hooks/api/use-cart-transaction"
import { InvoiceSettingsResponseBody } from "../../hooks/api/use-invoice-settings"
import { DELIVERECT_ORDER_TYPE_LABELS } from "../deliverect"
import IminPrinter from "../imin-printer"
import { printBorder, printSplitTable } from "./helper"
import { formatNumberToCurrency } from "../currency"
import axios from "axios"
import { nanoid } from "nanoid"
import Big from "big.js"

export const encodeCartTransactionReceiptMetadata = async (
  type: "Header" | "Footer",
  business: Business,
  trx: GetCartTransactionResponse,
  receiptSetting: InvoiceSettingsResponseBody[number],
  posSetting: typeof DEFAULT_MPOS_SETTING,
  width: number,
  encoder: ThermalPrinterEncoder
) => {
  const dueDate = (e: ThermalPrinterEncoder) => {
    if (!trx.dueDate) return e

    return e
      .align("left")
      .line(
        `Due Date: ${format(parseISO(trx.dueDate as string), "dd/MM/yyyy")}`
      )
  }

  const vat = (e: ThermalPrinterEncoder) => {
    let updatedVAT = e
      .align("center")
      .line(trx.location.companyName || "")
      .line(`VAT Reg. TIN: ${trx.location.tin}`)
      .line(`${trx.location.addressLine1}`)
      .line(`SN No.: ${trx.cashRegister?.serialNo}`)
      .line(`MIN: ${trx.cashRegister?.min}`)
      .newline()

    if (business.id !== "9e800468-ce7e-4f26-ae19-61656ee51953") {
      updatedVAT = updatedVAT.line("THIS SERVES AS YOUR OFFICIAL RECEIPT")
    }

    return updatedVAT
  }

  const transactionType = (e: ThermalPrinterEncoder) => {
    return e.align("left").line(`Transaction Type: ${trx.paymentMethodCode}`)
  }

  const orderType = (e: ThermalPrinterEncoder) => {
    return e.align("left").line(`Order Type: ${trx.orderType}`)
  }

  const phone = (e: ThermalPrinterEncoder) => {
    return e.align("left").line(business.phone)
  }

  const customer = (e: ThermalPrinterEncoder) => {
    let result = printBorder(e, width, { noSpacing: true })
    if (trx.isWalkIn) {
      result = result.align("left").line("Customer: Walk In")
      result = printBorder(result, width, { noSpacing: true }).line("\n")
      return result
    } else {
      let address = trx.customerLocation?.addressLine1
      if (trx.customerLocation?.addressLine2) {
        address += `, ${trx.customerLocation?.addressLine2}`
      }
      if (trx.customerLocation?.city) {
        address += `, ${trx.customerLocation?.city}`
      }
      if (trx.customerLocation?.province) {
        address += `, ${trx.customerLocation?.province}`
      }
      if (trx.customerLocation?.zip) {
        address += `, ${trx.customerLocation?.zip}`
      }

      result = e
        .align("left")
        .line(`Customer: ${trx.customer?.fullName || "-"}`)
        .line(`Phone: ${trx.customer?.phone || "-"}`)
        .line(`Address: ${address || "-"}`)

      // if (trx.tables?.[0]?.name) {
      //   result = result.line(`Table: ${trx.tables[0]?.name}`)
      // }
      if (!!trx?.rewardedPoints) {
        result = result.line(`Rewarded Points: ${trx.rewardedPoints}`)
      }
      if (posSetting?.enableCashback && !!trx?.rewardedCashback) {
        result = result.line(`Rewarded Cashback: ${trx.rewardedCashback}`)
      }
      result = printBorder(e, width, { noSpacing: true }).line("\n")
      return result
    }
  }

  const note = (e: ThermalPrinterEncoder) => {
    return e
      .align("center")
      .line("Note: " + trx.notes)
      .newline()
  }

  const barcode = (e: ThermalPrinterEncoder) => {
    return e
      .align("center")
      .barcode(trx.orderNumber.toString(), "code39", 64)
      .newline()
      .line(trx.orderNumber.toString())
      .newline()
  }

  const storeName = (e: ThermalPrinterEncoder) => {
    return e.align("center").line(trx.location.name)
  }

  const location = (e: ThermalPrinterEncoder) => {
    return e.align("center").line(trx.location.addressLine1).line("\n")
  }

  const message = (e: ThermalPrinterEncoder) => {
    return e.align("center").line(receiptSetting.message).line("\n")
  }

  const disclaimer = (e: ThermalPrinterEncoder) => {
    return e
      .align("center")
      .line(receiptSetting.disclaimer || "")
      .newline()
      .newline()
  }

  // const orderNumber = (e: ThermalPrinterEncoder) => {
  //   if (trx.queueNumber) {
  //     return e.align("center").line("Order #:").line(`${trx.queueNumber}`)
  //   }
  //   return e
  // }

  const referenceNumber = (e: ThermalPrinterEncoder) => {
    return e.align("center").line("Reference #:").line(`${trx.paymentId}`)
  }

  let result = encoder.initialize()

  if (
    type === "Header" &&
    receiptSetting.showDisclaimer === "Header" &&
    receiptSetting.disclaimer
  ) {
    result = disclaimer(result)
  }

  const logo = receiptSetting?.printLogo || business?.logoUrl || ""
  if (receiptSetting.showLogo && logo && type === "Header") {
    try {
      const loadImage = (src: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          axios
            .get(src + `?v=${nanoid()}`, {
              responseType: "blob",
              headers: {
                "Cache-Control": "no-cache",
              },
            })
            .then((response) => {
              const img = new Image()
              img.crossOrigin = "anonymous"
              img.src = URL.createObjectURL(response.data)

              img.onload = () => {
                URL.revokeObjectURL(img.src) // Clean up
                resolve(img)
              }
              img.onerror = (err) => {
                URL.revokeObjectURL(img.src)
                reject(err)
              }
            })
        })
      }
      const image = await loadImage(logo)
      result = encoder
        .align("center")
        .image(image, 160, 160, "atkinson")
        .newline()
        .newline()
    } catch (e) {
      console.log(e)
    }
  }

  if (type === "Header") {
    result = result
      .align("center")
      .bold(true)
      .line(business.name)
      .bold(false)
      .newline()
  } else {
    result = result.newline().newline()
  }
  if (receiptSetting.storeName === type) {
    result = storeName(result)
  }
  if (receiptSetting.showLocation === type) {
    result = location(result)
  }
  if (receiptSetting.showTaxPIN && receiptSetting.taxPIN && type === "Header") {
    result = result
      .align("center")
      .line(`Tax PIN: ${receiptSetting.taxPIN}`)
      .newline()
  }
  if (
    receiptSetting.showDueDate === type &&
    trx.paymentMethodCode === "PAY LATER"
  ) {
    result = dueDate(result)
  }
  if (trx.location.isVatRegistered === "Yes") {
    result = vat(result)
  }

  if (receiptSetting.transactionType === type) {
    result = transactionType(result)
  }
  if (receiptSetting.showOrderType === type) {
    result = orderType(result)
  }
  if (
    receiptSetting.showReferenceNumber &&
    type === "Header" &&
    trx.paymentId
  ) {
    result = referenceNumber(result)
  }
  if (type === "Header") {
    result = result.align("left").line(new Date(trx.createdAt).toLocaleString())
  }
  if (receiptSetting.showPhone === type) {
    result = phone(result)
  }
  if (type === "Header") {
    result = result
      .align("left")
      .line("Cashier: " + trx.createdBy)
      .newline()
  }
  if (receiptSetting.showCustomerInfo === type) {
    result = customer(result)
  }
  if (receiptSetting.showOrderNote === type && trx.notes) {
    result = note(result)
  }
  if (receiptSetting.showBarcode === type) {
    result = barcode(result)
  }

  if (receiptSetting.showMessage === type && receiptSetting.message) {
    result = message(result)
  }
  if (
    type === "Footer" &&
    receiptSetting.showDisclaimer === "Footer" &&
    receiptSetting.disclaimer
  ) {
    result = disclaimer(result)
  }

  // if (type === "Header") {
  //   result = orderNumber(result).newline()
  // }

  if (trx.shipment?.shipmentType === "POS_MANUAL" && type === "Header") {
    result = printBorder(result, width, { noSpacing: true })
    result = printSplitTable(result, width, width / 2, [
      [
        `Order Number: ${trx.orderNumber}`,
        `Booking Code: ${trx.shipment.quoteDetails.bookingCode}`,
      ],
    ])
      .align("left")
      .line(`Customer: ${trx.shipment.quoteDetails.customerName || "-"}`)
      .line(`Address: ${trx.shipment.quoteDetails.address || "-"}`)
      .line(`City: ${trx.shipment.quoteDetails.city || "-"}`)
      .line(`Phone Number: ${trx.shipment.quoteDetails.phoneNumber || "-"}`)

    result = printBorder(result, width, { noSpacing: true })
  }
  result = result.align("left")
  return result
}

export const encodeCartTransactionReceiptItemHeader = (
  trx: GetCartTransactionResponse,
  width: number,
  encoder: ThermalPrinterEncoder
) => {
  return printSplitTable(encoder, width, 10, [
    ["Item(s)", trx.orderedProducts.length.toString()],
  ])
}

export const encodeCartTransactionReceiptTotal = (
  trx: GetCartTransactionResponse,
  width: number,
  currency: string,
  encoder: ThermalPrinterEncoder
) => {
  const totalIncludedTax =
    trx.taxes
      ?.filter((el) => el.type === "INCLUDED")
      .reduce((acc, el) => acc + el.amount, 0) || 0
  const totalCartAmount = trx.totalCartAmount + totalIncludedTax
  let rows: Array<[string, string]> = [
    ["Subtotal", formatNumberToCurrency(currency, totalCartAmount, "code")],
    ["Tax", formatNumberToCurrency(currency, trx.tax, "code")],
  ]

  if (trx.serviceCharge) {
    rows.push([
      "Service Charge",
      formatNumberToCurrency(currency, trx.serviceCharge, "code"),
    ])
  }

  if (trx.shippingCost) {
    rows.push([
      "Shipping Cost",
      formatNumberToCurrency(currency, trx.shippingCost, "code"),
    ])
  }

  if (trx.discount) {
    rows.push([
      "Discount",
      formatNumberToCurrency(currency, trx.discount, "code"),
    ])
  }

  rows.push(["Total", formatNumberToCurrency(currency, trx.grandTotal, "code")])

  if (["PAY LATER", "INVOICE"].includes(trx.paymentMethodCode)) {
    rows.push([
      "Total Deposit",
      formatNumberToCurrency(
        currency,
        trx.grandTotal - (trx?.outstanding || 0)
      ),
    ])
    rows.push([
      "Total Outstanding",
      formatNumberToCurrency(currency, trx.outstanding || 0, "code"),
    ])
  }

  if (trx.paymentMethodCode === "CASH") {
    rows.push([
      "Cash Received",
      formatNumberToCurrency(currency, trx.cashReceived, "code"),
    ])
    rows.push([
      "Cash Change",
      formatNumberToCurrency(currency, trx.cashChange, "code"),
    ])
  }

  if (trx.pickupTime) {
    rows.push(["Pickup Date", format(parseISO(trx.pickupTime), "dd MMM yyyy")])
  }

  if (trx.deliverectOrderType) {
    rows.push([
      "Order Type",
      DELIVERECT_ORDER_TYPE_LABELS[trx.deliverectOrderType],
    ])
    rows.push([
      "Delivery Channel",
      `${trx.source} (${trx.deliverectChannelOrderDisplayId})`,
    ])
    if (trx.deliverectPickupTime) {
      rows.push([
        "Pickup Time",
        format(parseISO(trx.deliverectPickupTime), "dd MMM yyyy HH:mm"),
      ])
    }
    if (trx.deliverectDeliveryTime) {
      rows.push([
        "Delivery Time",
        format(parseISO(trx.deliverectDeliveryTime), "dd MMM yyyy HH:mm"),
      ])
    }
  }

  let result = encoder.newline()
  return printSplitTable(result, width, width / 2, rows)
    .align("left")
    .newline()
}

export const encodeCartTransactionReceiptItem = (
  product: GetCartTransactionResponse["orderedProducts"][number],
  width: number,
  currency: string,
  encoder: ThermalPrinterEncoder
) => {
  let totalPrice = Big(product.totalPrice)
  if (product.taxIncluded) {
    totalPrice = totalPrice.add(product.tax || 0)
  }
  const unitPrice = totalPrice.div(product.quantity).toNumber()

  let name = `${product.name} - ${product.quantity}x @${formatNumberToCurrency(currency, unitPrice, "code")}`
  encoder = printSplitTable(encoder, width, width - 12, [
    [name, formatNumberToCurrency(currency, totalPrice.toNumber(), "code")],
  ])

  const variantName = product.productOptionValues
    ?.map((el) => el.name)
    .join(" / ")
  if (variantName) {
    encoder = encoder.line(`(${variantName})`)
  }
  if (!!product.orderedProductAddOns?.length) {
    encoder = encoder.line("Add Ons:")
    product.orderedProductAddOns.forEach((el) => {
      const totalPrice = Big(el.quantity).mul(el.sellingPrice).toFixed(2)
      encoder = encoder.table(
        [
          { width: 20 - 1, marginRight: 2, align: "left" },
          { width: width - 20 - 1, align: "right" },
        ],
        [
          [
            `${el.name} - ${el.quantity}x@${formatNumberToCurrency(currency, el.sellingPrice, "code")}`,
            `${currency} ${totalPrice}`,
          ],
        ]
      )
    })
  }
  if (!!product.employees?.length) {
    encoder = encoder.line("Employees:")
    product.employees.forEach((el) => {
      encoder = encoder.line(el.fullName)
    })
  }

  return encoder
}

export const encodeOpenDrawer = () => {
  let encoder = new ThermalPrinterEncoder({ language: "esc-pos" }).initialize()
  encoder = encoder.pulse()
  encoder = encoder.pulse(0)
  encoder = encoder.pulse(1)
  encoder = encoder.raw([0x10, 0x14, 0x00, 0x00, 0x00])
  return encoder.encode()
}

// imin printer helpers
export const printIminCartTransactionReceiptMetadata = async (
  printer: IminPrinter,
  type: "Header" | "Footer",
  business: Business,
  trx: GetCartTransactionResponse,
  receiptSetting: InvoiceSettingsResponseBody[number],
  posSetting: typeof DEFAULT_MPOS_SETTING
) => {
  const dueDate = () => {
    if (!trx.dueDate) return

    printer.setAlignment(1)
    printer.printText(
      `Due Date: ${format(parseISO(trx.dueDate as string), "dd/MM/yyyy")}`
    )
  }

  const vat = () => {
    printer.setAlignment(1)
    printer.printText(trx.location.companyName || "")
    printer.printText(`VAT Reg. TIN: ${trx.location.tin}`)
    printer.printText(`${trx.location.addressLine1}`)
    printer.printText(`SN No.: ${trx.cashRegister?.serialNo}`)
    printer.printText(`MIN: ${trx.cashRegister?.min}`)
    printer.printAndLineFeed()

    if (business.id !== "9e800468-ce7e-4f26-ae19-61656ee51953") {
      printer.printText("THIS SERVES AS YOUR OFFICIAL RECEIPT")
    }
  }

  const transactionType = () => {
    printer.setAlignment(1)
    printer.printText(`Transaction Type: ${trx.paymentMethodCode}`)
  }

  const orderType = () => {
    printer.setAlignment(1)
    printer.printText(`Order Type: ${trx.orderType}`)
  }

  const phone = () => {
    printer.setAlignment(1)
    printer.printText(business.phone)
  }

  const customer = () => {
    if (trx.isWalkIn) {
      printer.setAlignment(1)
      printer.printText("Customer: Walk In")
      printer.printAndLineFeed()
    } else {
      let address = trx.customerLocation?.addressLine1
      if (trx.customerLocation?.addressLine2) {
        address += `, ${trx.customerLocation?.addressLine2}`
      }
      if (trx.customerLocation?.city) {
        address += `, ${trx.customerLocation?.city}`
      }
      if (trx.customerLocation?.province) {
        address += `, ${trx.customerLocation?.province}`
      }
      if (trx.customerLocation?.zip) {
        address += `, ${trx.customerLocation?.zip}`
      }

      printer.setAlignment(1)
      printer.printText(`Customer: ${trx.customer?.fullName}`)
      printer.printText(`Phone: ${trx.customer?.phone}`)
      printer.printText(`Address: ${address}`)
      if (!!trx?.rewardedPoints) {
        printer.printText(`Rewarded Points: ${trx.rewardedPoints}`)
      }
      if (posSetting?.enableCashback && !!trx?.rewardedCashback) {
        printer.printText(`Rewarded Cashback: ${trx.rewardedCashback}`)
      }
      printer.printAndLineFeed()
    }
  }

  const note = () => {
    printer.setAlignment(1)
    printer.printText(`Note: ${trx.notes}`)
    printer.printAndLineFeed()
  }

  const barcode = () => {
    printer.setAlignment(1)
    printer.setBarCodeWidth(4)
    printer.setBarCodeHeight(64)
    printer.printBarCode(5, trx.orderNumber.toString())
    printer.printAndLineFeed()
    printer.printText(trx.orderNumber.toString())
    printer.printAndLineFeed()
  }

  const storeName = () => {
    printer.setAlignment(1)
    printer.printText(trx.location.name)
  }

  const location = () => {
    printer.setAlignment(1)
    printer.printText(trx.location.addressLine1)
    printer.printAndLineFeed()
  }

  const message = () => {
    printer.setAlignment(1)
    printer.printText(receiptSetting.message)
    printer.printAndLineFeed()
  }

  if (type === "Header") {
    printer.setAlignment(1)
    const logo = receiptSetting?.printLogo || business?.logoUrl || ""
    if (logo) {
      const loadImage = (src: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          axios
            .get(src + `?v=${nanoid()}`, {
              responseType: "blob",
              headers: { "Cache-Control": "no-cache" },
            })
            .then((response) => {
              const img = new Image()
              img.crossOrigin = "anonymous"
              img.src = URL.createObjectURL(response.data)

              img.onload = () => {
                URL.revokeObjectURL(img.src) // Clean up
                resolve(img)
              }
              img.onerror = (err) => {
                URL.revokeObjectURL(img.src)
                reject(err)
              }
            })
        })
      }
      const image = await loadImage(logo)

      // Set the maximum width and height
      const maxWidth = 40 // Desired maximum width
      const maxHeight = 40 // Desired maximum height

      // Calculate the scaling factor to maintain aspect ratio
      const ratio = Math.min(maxWidth / image.width, maxHeight / image.height)
      const newWidth = image.width * ratio
      const newHeight = image.height * ratio

      const canvas = document.createElement("canvas")
      canvas.width = newWidth
      canvas.height = newHeight
      const ctx = canvas.getContext("2d")

      // Draw the image with the new dimensions
      ctx?.drawImage(image, 0, 0, newWidth, newHeight)

      const base64Image = canvas.toDataURL("image/png")
      await printer.printSingleBitmap(base64Image)
    }
    printer.printText(business.name)
    printer.printAndLineFeed()
  } else {
    printer.printAndLineFeed()
  }

  if (
    receiptSetting.showDueDate === type &&
    trx.paymentMethodCode === "PAY LATER"
  ) {
    dueDate()
  }
  if (trx.location.isVatRegistered === "Yes") {
    vat()
  }
  if (receiptSetting.showTaxPIN && receiptSetting.taxPIN) {
    printer.setAlignment(1)
    printer.printText(`Tax PIN: ${receiptSetting.taxPIN}`)
    printer.printAndLineFeed()
  }
  if (receiptSetting.transactionType === type) {
    transactionType()
  }
  if (receiptSetting.showOrderType === type) {
    orderType()
  }
  if (type === "Header") {
    printer.printText(new Date(trx.createdAt).toLocaleString())
  }
  if (receiptSetting.showPhone === type) {
    phone()
  }
  if (type === "Header") {
    printer.printText("Cashier: " + trx.createdBy)
    printer.printAndLineFeed()
  }
  if (receiptSetting.showCustomerInfo === type) {
    customer()
  }
  if (receiptSetting.showOrderNote === type && trx.notes) {
    note()
  }
  if (receiptSetting.showBarcode === type) {
    barcode()
  }
  if (receiptSetting.storeName === type) {
    storeName()
  }
  if (receiptSetting.showLocation === type) {
    location()
  }
  if (receiptSetting.showMessage === type && receiptSetting.message) {
    message()
  }
}

export const printIminCartTransactionReceiptItemHeader = (
  printer: IminPrinter,
  trx: GetCartTransactionResponse
) => {
  printer.printAndLineFeed()
  printer.printAndLineFeed()

  printer.printColumnsText(
    ["Item(s)", trx.orderedProducts.length.toString()],
    [1, 2],
    [0, 2],
    [26, 26],
    576
  )
}

export const printIminCartTransactionReceiptTotal = (
  printer: IminPrinter,
  trx: GetCartTransactionResponse,
  currency?: string
) => {
  printer.printColumnsText(
    ["Subtotal", currency + " " + trx.totalCartAmount.toLocaleString()],
    [1, 2],
    [0, 2],
    [26, 26],
    576
  )
  printer.printColumnsText(
    ["Tax", currency + " " + trx.tax.toLocaleString()],
    [1, 2],
    [0, 2],
    [26, 26],
    576
  )
  if (trx.serviceCharge) {
    printer.printColumnsText(
      ["Service Charge", currency + " " + trx.serviceCharge.toLocaleString()],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
  }
  if (trx.shippingCost) {
    printer.printColumnsText(
      ["Shipping Cost", currency + " " + trx.shippingCost.toLocaleString()],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
  }
  if (trx.discount) {
    printer.printColumnsText(
      ["Discount", currency + " " + trx.discount.toLocaleString()],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
  }
  printer.printColumnsText(
    ["Total", currency + " " + trx.grandTotal.toLocaleString()],
    [1, 2],
    [0, 2],
    [26, 26],
    576
  )
  if (["PAY LATER", "INVOICE"].includes(trx.paymentMethodCode)) {
    printer.printColumnsText(
      [
        "Total Deposit",
        currency + " " + (trx.grandTotal - (trx?.outstanding || 0)).toString(),
      ],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
    printer.printColumnsText(
      ["Total Outstanding", currency + " " + (trx.outstanding || 0).toString()],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
  }
  if (trx.paymentMethodCode === "CASH") {
    printer.printColumnsText(
      [
        "Cash Received",
        currency + " " + getActualCashReceived(trx).toLocaleString(),
      ],
      [2, 2],
      [0, 2],
      [26, 26],
      576
    )
    printer.printColumnsText(
      [
        "Cash Change",
        currency + " " + getActualCashChange(trx).toLocaleString(),
      ],
      [2, 2],
      [0, 2],
      [26, 26],
      576
    )
  }

  if (trx.pickupTime) {
    printer.printColumnsText(
      ["Pickup Date", format(parseISO(trx.pickupTime), "dd MMM yyyy")],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
  }

  if (trx.deliverectOrderType) {
    printer.printColumnsText(
      ["Order Type", DELIVERECT_ORDER_TYPE_LABELS[trx.deliverectOrderType]],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
    printer.printColumnsText(
      [
        "Delivery Channel",
        `${trx.source} (${trx.deliverectChannelOrderDisplayId})`,
      ],
      [1, 2],
      [0, 2],
      [26, 26],
      576
    )
    if (trx.deliverectPickupTime) {
      printer.printColumnsText(
        [
          "Pickup Time",
          format(parseISO(trx.deliverectPickupTime), "dd MMM yyyy HH:mm"),
        ],
        [1, 2],
        [0, 2],
        [26, 26],
        576
      )
    }
    if (trx.deliverectDeliveryTime) {
      printer.printColumnsText(
        [
          "Delivery Time",
          format(parseISO(trx.deliverectDeliveryTime), "dd MMM yyyy HH:mm"),
        ],
        [1, 2],
        [0, 2],
        [26, 26],
        576
      )
    }
  }
}

export const printIminCartTransactionReceiptItem = (
  printer: IminPrinter,
  product: GetCartTransactionResponse["orderedProducts"][number],
  currency?: string
) => {
  const variantName = product.productOptionValues
    ?.map((el) => el.name)
    .join(" / ")

  let item =
    product.name +
    ` - ${product.quantity}x@${product.sellingPrice.toLocaleString()}`

  if (variantName) {
    item += `(${variantName})`
  }

  printer.setAlignment(0)
  printer.printText(item)
  // printer.printColumnsText(
  //   [item, product.totalPrice.toLocaleString()],
  //   [1, 1],
  //   [0, 2],
  //   [26, 26],
  //   576
  // )

  if (!!product.orderedProductAddOns?.length) {
    printer.printText("Add Ons:")
    product.orderedProductAddOns.forEach((el) => {
      printer.printText(
        `${el.name} - ${el.quantity}x@${el.sellingPrice.toLocaleString()}`
      )
    })
  }

  if (!!product.employees?.length) {
    printer.printText("Employees:")
    product.employees.forEach((el) => {
      printer.printText(el.fullName)
    })
  }

  printer.setAlignment(2)
  printer.printText(currency + " " + product.totalPrice.toLocaleString())
  printer.printAndLineFeed()
  printer.setAlignment(0)
}

export const printFullReceipt = async (
  business: Business,
  trx: GetCartTransactionResponse,
  receiptSetting: InvoiceSettingsResponseBody[number],
  posSetting: typeof DEFAULT_MPOS_SETTING,
  currency: string,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  const width = encoderOptions?.width || 32
  let encoder = new ThermalPrinterEncoder({
    ...encoderOptions,
    imageMode: "raster",
  }).initialize()
  encoder = await encodeCartTransactionReceiptMetadata(
    "Header",
    business,
    trx,
    receiptSetting,
    posSetting,
    width,
    encoder
  )
  encoder = encodeCartTransactionReceiptItemHeader(trx, width, encoder)
  for (const item of trx.orderedProducts) {
    encoder = encodeCartTransactionReceiptItem(item, width, currency, encoder)
  }
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encodeCartTransactionReceiptTotal(trx, width, currency, encoder)
  encoder = await encodeCartTransactionReceiptMetadata(
    "Footer",
    business,
    trx,
    receiptSetting,
    posSetting,
    width,
    encoder
  )
  encoder = encoder.cut()
  return encoder.encode()
}
