import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./index.css"
import { Redirect, Route, Switch } from "react-router-dom"
import routes from "./routes"
import NotFoundPage from "../../components/NotFoundPage"
import { TopBar } from "../../components/TopBar"
import { ApiContext, ApiProvider, AppConfigProvider } from "./context"
import useSubscription from "../../hooks/api/use-subscription"
import { MobileTopBar } from "../../components/MobileTopBar"
import useIntercomIdVerification from "../../hooks/use-intercom-id-verification"
import { useIntercom } from "react-use-intercom"
import useQueryString from "../../hooks/use-query-string"
import useBusiness from "../../hooks/api/use-business"
import useSubscriptionPlans from "../../hooks/api/use-subscription-plans"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import useSyncOfflineOrders from "../../hooks/use-sync-offline-orders"
import LoadingSpinner from "../../components/LoadingSpinner"
import {
  useExecuteFCMCallbackQueue,
  useShowFCMPushNotification,
} from "../../hooks/use-fcm-on-message"
import useTemplate from "../../hooks/api/use-template"
import { generateTemplateCSSVar } from "../../lib/templates"
import { useSyncCDSCart } from "../../hooks/use-cds-connection"
import { create } from "zustand"
import useThermalPrinter from "../../hooks/use-thermal-printer"
import useCompanionApp from "../../hooks/use-companion-app"
import { useShowSnackbar } from "../../components/Snackbar"

const BusinessCatalog = lazy(() => import("../Mpos/BusinessCatalog"))
const EmployeeManagement = lazy(() => import("../EmployeeManagement"))
const SystemSettings = lazy(() => import("../SystemSettings"))
const SideBar = lazy(() => import("../../components/SideBar"))

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#003CAA",
          fontWeight: "bold",
          backgroundColor: "#F0F0F0",
        },
        arrow: {
          color: "#F0F0F0",
        },
      },
    },
  },
})

export const useSidebarState = create<{
  showSideBar: boolean
  setShowSideBar: (showSideBar: boolean) => void
}>((set) => ({
  showSideBar: false,
  setShowSideBar: (showSideBar: boolean) => set({ showSideBar }),
}))

function Dashboard(): React.ReactElement {
  const context = useContext(ApiContext)
  const subscribed = useQueryString("subscribed")
  const printer = useThermalPrinter()
  const companion = useCompanionApp()
  const initialized = useRef(false)
  const showSnackbar = useShowSnackbar()

  useSyncOfflineOrders()
  useIntercomIdVerification()
  useTrackIntercomOnboardingStart()
  useExecuteFCMCallbackQueue()
  useShowFCMPushNotification()
  useSyncCDSCart()

  const { data: template } = useTemplate()

  const { showSideBar, setShowSideBar } = useSidebarState()

  const toggleSideBar = () => {
    setShowSideBar(!showSideBar)
  }

  useEffect(() => {
    if (initialized.current) return
    ;(async function reconnectPrinter() {
      initialized.current = true
      const socket = await companion.connect({
        syncKitchenPrinters: (printers) => {
          printer.syncWithCompanionApp(printers)
        },
        onDisconnect: () => {
          showSnackbar("Companion app disconnected", "warning")
          printer.disconnectAll()
        },
      })
      if (!socket) {
        await printer.connectImin()
        await printer.reconnectAll()
      } else {
        showSnackbar("Companion app connected")
      }
    })().then(() => {})
  }, [])

  useEffect(() => {
    if (
      companion.status !== "NOT_AVAILABLE" &&
      companion.status !== "UNKNOWN"
    ) {
      const interval = setInterval(async () => {
        if (companion.status === "CLOSED") {
          const socket = await companion.connect({
            syncKitchenPrinters: (printers) => {
              printer.syncWithCompanionApp(printers)
            },
            onDisconnect: () => {
              showSnackbar("Companion app disconnected", "warning")
              printer.disconnectAll()
            },
          })
          if (socket) {
            showSnackbar("Companion app reconnected.")
          }
        }
      }, 3000)

      return () => clearInterval(interval)
    }
  }, [companion.status])

  return (
    <div
      className="dashboard bg-[#f6f6ff]"
      style={generateTemplateCSSVar(template)}
    >
      <ApiProvider key="apiProvider">
        <ThemeProvider theme={theme}>
          <AppConfigProvider>
            <div className="dashboard-container">
              {/**<DashboardHeader toggleSideBar={toggleSideBar}/>**/}
              <div className="dashboard-body">
                <SideBar />
                <div
                  className={
                    showSideBar
                      ? "main-with-sidebar max-h-screen overflow-auto"
                      : "main-without-sidebar"
                  }
                >
                  {!window.location.pathname.includes("business-catalog") && (
                    <TopBar
                      showMenuIcon={!showSideBar}
                      toggleSideBar={toggleSideBar}
                    />
                  )}
                  <MobileTopBar
                    showMenuIcon={!showSideBar}
                    toggleSideBar={toggleSideBar}
                  />
                  <div
                    className={
                      !window.location.pathname.includes("business-catalog")
                        ? "info"
                        : "catalog"
                    }
                  >
                    <Suspense
                      fallback={
                        <div
                          className={
                            "flex h-screen w-full items-center justify-center"
                          }
                        >
                          <LoadingSpinner />
                        </div>
                      }
                    >
                      <Switch>
                        <Route
                          path={"/dashboard/mpos/sales-overview"}
                          render={() => (
                            <Redirect
                              to={
                                "/dashboard/accounting-reports/sales-overview"
                              }
                            />
                          )}
                        />

                        {routes.map(
                          (route): React.ReactElement => (
                            <Route
                              key={route.path}
                              path={route.path}
                              exact={route.exact}
                            >
                              {route.disableContainer ? (
                                <route.component />
                              ) : (
                                <div
                                  className={`${
                                    route.mainWrapperClass
                                      ? `${route.mainWrapperClass}`
                                      : "p-4 md:p-8"
                                  } flex w-full flex-col  `}
                                >
                                  <route.component />
                                </div>
                              )}
                            </Route>
                          )
                        )}
                        <Route
                          key="business-catalog"
                          path={"/dashboard/mpos/business-catalog"}
                          render={() => (
                            <BusinessCatalog
                              showMenuIcon={!showSideBar}
                              toggleSideBar={toggleSideBar}
                            />
                          )}
                        />
                        <Route
                          key="system-settings"
                          path={"/dashboard/system-settings"}
                          render={() => (
                            <div className={"w-full p-4 md:p-8"}>
                              <SystemSettings />
                            </div>
                          )}
                        />
                        <Route
                          key="employee-management"
                          path={"/dashboard/employee-management"}
                          render={() => (
                            <div className={"w-full p-4 md:p-8"}>
                              <EmployeeManagement />
                            </div>
                          )}
                        />
                        <Route component={NotFoundPage} />
                      </Switch>
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </AppConfigProvider>
        </ThemeProvider>
      </ApiProvider>

      {subscribed === "true" && <TrackSuccessfulSubscription />}
      <SubscriptionRedirect />
    </div>
  )
}

const TrackSuccessfulSubscription = () => {
  const subscribed = useQueryString("subscribed")
  const { data: business } = useBusiness()
  const { data } = useSubscription()
  const { data: plans } = useSubscriptionPlans()
  const selectedPlan = plans?.find(
    (p) =>
      p.planId === data?.planId ||
      p.stripePlanId === data?.planId ||
      p.flwPlanId === data?.planId
  )

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      subscribed === "true" &&
      business &&
      selectedPlan
    ) {
      window.fbq("track", "Purchase", {
        currency: "USD",
        value: selectedPlan?.price,
        ecommerce: {
          transaction_id: data?.id,
          value: Number(selectedPlan?.localPrice?.toFixed(2)),
          currency: selectedPlan?.localCurrency,
          payment_method: data?.paymentMethod,
          subscription_type: selectedPlan?.billingFrequency,
          items: [
            {
              item_id: selectedPlan?.id,
              item_name: selectedPlan?.name,
              currency: "USD",
              price: selectedPlan?.price,
            },
          ],
        },
      })

      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: data?.id,
          value: Number(selectedPlan?.localPrice?.toFixed(2)),
          currency: selectedPlan?.localCurrency,
          payment_method: data?.paymentMethod,
          subscription_type: selectedPlan?.billingFrequency,
          items: [
            {
              item_id: selectedPlan?.id,
              item_name: selectedPlan?.name,
              currency: "USD",
              price: selectedPlan?.price,
            },
          ],
        },
      })
    }
  }, [business, subscribed])

  return null
}

const useTrackIntercomOnboardingStart = () => {
  const intercom = useIntercom()
  const onboarding = useQueryString("onboarding")
  const { data: business } = useBusiness()
  useEffect(() => {
    if (onboarding === "true") {
      if (process.env.NODE_ENV === "production") {
        window.fbq("trackCustom", "SignUp", {
          ecommerce: {
            email: business?.email,
            business_type: business?.type,
            name: business?.name,
            phone: business?.phone,
          },
        })
        window.dataLayer.push({
          event: "sign_up",
          ecommerce: {
            email: business?.email,
            business_type: business?.type,
            name: business?.name,
            phone: business?.phone,
          },
        })
      }
      intercom.startTour(417009)
    }
  }, [intercom, onboarding])
}

const SubscriptionRedirect = () => {
  const { data } = useSubscription()

  if (data === null) {
    return <Redirect to={"/subscriptions"} />
  }
  return <></>
}

export default Dashboard
