import React, { FC, forwardRef, useRef } from "react"
import PageHeader from "../../../../components/PageHeader"
import { t } from "i18next"
import { format, parseISO } from "date-fns"
import Select from "../../../../components/Select"
import useBusiness, { Business } from "../../../../hooks/api/use-business"
import useBusinessCurrency from "../../../../hooks/use-business-currency"
import useQueryString from "../../../../hooks/use-query-string"
import DownloadCSVButton from "../../../../components/DownloadCSVButton"
import DownloadPDFButton from "../../../../components/DownloadPDFButton"
import IconButton from "../../../../components/IconButton"
import PrintIcon from "@mui/icons-material/Print"
import Tooltip from "@mui/material/Tooltip"
import moment from "moment"
import useZReport, { ZReportResponse } from "../../../../hooks/api/use-z-report"
import Input from "../../../../components/Input"
import Big from "big.js"
import { connectPrinter } from "../../../../hooks/use-thermal-printer"
import { encodeBIRZReport } from "../../../../lib/thermal-printer/bir-z-report"
import { printToThermal } from "../../../../lib/thermal-printer/helper"
import { useShowSnackbar } from "../../../../components/Snackbar"
import useOneCashRegister, {
  GetCashRegisterResponse,
} from "../../../../hooks/api/use-one-cash-register"
import DownloadTXTButton from "../../../../components/DownloadTXTButton"
import dedent from "dedent"
import { postAPI } from "../../../../lib/api"
import clsx from "clsx"
import { useReactToPrint } from "react-to-print"
import html2PDF from "jspdf-html2canvas"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { postUserActivity } from "../../../../lib/user-activity"
import useEmployeeMe from "../../../../hooks/api/use-employee-me"
import { AxiosError } from "axios"

const logReportPrint = (logId: string) => {
  return postAPI(`/cashRegister/log/${logId}/print-bir-z`)
}

const BIRZ: FC = () => {
  const Title = t("BIR Z-Report")
  const type = useQueryString("type")
  const logId = useQueryString("logId")
  const closedAt = useQueryString("closedAt")
  const cashRegisterId = useQueryString("cashRegisterId")
  const showSnackbar = useShowSnackbar()
  const currentDate = new Date()
  const printTarget = useRef<HTMLDivElement>(null)

  const { data: register } = useOneCashRegister(cashRegisterId)
  const { data: report } = useZReport(cashRegisterId, logId)
  const { data: business } = useBusiness()
  const { data: me } = useEmployeeMe()

  const componentRef = useRef(null)
  const currency = useBusinessCurrency()

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => printTarget.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument
      if (document) {
        const html = document.getElementsByTagName("html")[0]
        const exporter = await html2PDF(html)
        await exporter.save("bir-x-report.pdf")
      }
      await createUserActivity()
    },
  })

  const createUserActivity = async () => {
    try {
      if (report?.openedAt && report?.closedAt) {
        const res = await postUserActivity(
          "BIR Z-Report",
          `${format(parseISO(report?.openedAt), "MMM dd, yyyy")} - ${format(parseISO(report?.closedAt), "MMM dd, yyyy")}`,
          business?.id || "",
          me?.id ? me : null
        )
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        showSnackbar(error.response?.data.message, "error")
      } else {
        showSnackbar("Error creating user activity log.", "error")
      }
    }
  }

  let totalGrossSales = 0
  let totalLessRefund = 0
  let totalLessDiscount = 0
  let totalLessVat = 0
  let totalTransactions = 0
  let totalTotalTendered = 0
  let totalVatableSales = 0
  let vatExemptSales = 0
  let totalTotalTax = 0
  let totalZeroRatedSales = 0
  let totalSeniorCitizenDiscount = 0
  let totalPwdDiscount = 0
  let totalNationalAthleteDiscount = 0
  let totalMedalOfValorDiscount = 0
  let totalRefundsCount = 0
  let totalRefunds = 0
  let begOR = 0
  let endOR = 0
  let startTrxNo = 0
  let endTrxNo = 0

  const netSales = Big(report?.grossSales || 0)
    .minus(report?.discount || 0)
    .minus(report?.refunds || 0)
    .minus(report?.vatAmount || 0)
    .round(2)
    .toNumber()

  let dataReportCsv = [
    [`BIR Report Type ${type}`, ""],
    [business?.name, ""],
    ["191 2nd Flr. 25 D zeta BLG. city of makati", ""],
    ["TIN:600-2323-5435-222", ""],
    ["Dizlog v1.0.57", ""],
    ["S/N: 0977485475", ""],
    ["MIN: 12893792817", ""],
    ["POS Terminal 01", ""],
    [
      `${moment(report?.openedAt).format("MMMM DD, YYYY")}`,
      `${moment(report?.closedAt).format("MMMM DD, YYYY")}`,
    ],
    ["Anne_Cruz", ""],
    ["Name", "Amount"],
    [" Less Discount", totalLessDiscount],
    [" Less Refund", totalLessRefund],
    ["VAT from SC/PWD/Others", totalLessVat],
    ["Net Sales", netSales],
    ["VATable Sales", totalVatableSales],
    ["VAT Amount", totalTotalTax],
    ["VAT Exempt Sales", 0],
    ["Zero Rated Sales", totalZeroRatedSales],
    ["SC Discount", totalSeniorCitizenDiscount],
    ["PWD Discount", totalPwdDiscount],
    ["Regular Discount", 0],
    ["National Athlete / Coaches Discount", totalNationalAthleteDiscount],
    ["Medal of Valor Discount", totalMedalOfValorDiscount],
    ["Diplomats Discount", 0],
    ["Beg. OR", 0],
    ["End. OR", 0],
    ["Start Trx No.", 0],
    ["End Trx No.", 0],
    ["Trx No.", 0],
    ["Sales Trx Count", 0],
    ["Refund Trx Count", 0],
    ["Refund Trx Count", totalRefundsCount],
    ["Refund Amount", totalRefunds],
    ["Change Item Trx", 0],
    ["Change Item Amount", totalRefundsCount + totalRefunds],
    ["Total", 0],
  ]

  const handlePrintThermal = async () => {
    if (!report) {
      showSnackbar("Something went wrong, please try again.", "error")
      return
    }

    const { server, characteristic } = await connectPrinter()

    const now = new Date()
    const encodedValues = encodeBIRZReport({
      storeName: business?.name || "",
      operatedBy: register?.location?.companyName || "",
      address: register?.location?.addressLine1 || "",
      vatRegTin: register?.location?.tin || "",
      min: register?.min || "",
      serialNo: register?.serialNo || "",
      reportDate: format(now, "MMMM dd, yyyy"),
      reportTime: format(now, "hh:mm a"),
      startDate: format(parseISO(report?.openedAt), "dd/MM/yy hh:mm a"),
      endDate: format(parseISO(report?.closedAt), "dd/MM/yy hh:mm a"),

      begSI: report.beginningOR.toString(),
      endSI: report.endingOR.toString(),
      begVoid: report.beginningVoid,
      endVoid: report.endingVoid,
      resetCounterNo: "0",
      zCounterNo: report.zCounter,
      presentAccumulatedSales: "0",
      previousAccumulatedSales: "0",
      salesForTheDay: report.sales.toFixed(2),

      vatableSales: report.vatableSales.toFixed(2),
      vatAmount: report.vatAmount.toFixed(2),
      vatExemptSales: "0",
      zeroRatedSales: report.zeroRatedSales.toFixed(2),

      grossAmount: report.grossSales.toFixed(2),
      lessDiscount: report.discount.toFixed(2),
      lessReturn: report.lessReturn.toFixed(2),
      lessVoid: "0",
      lessVatAdjustment: report.lessVat.toFixed(2),
      netAmount: netSales.toFixed(2),

      scDisc: report.seniorCitizenDiscount.toFixed(2),
      pwdDisc: report.pwdDiscount.toFixed(2),
      naacDisc: report.nationalAthleteDiscount.toFixed(2),
      soloParentDisc: "0",
      otherDisc: report.discount.toFixed(2),

      void: (report.refunds + report.void).toFixed(2),

      scTrans: report.seniorCitizenTrans.toFixed(2),
      pwdTrans: report.pwdTrans.toFixed(2),
      regDiscTrans: report.regularDiscountTrans.toFixed(2),
      zeroRatedTrans: report.zeroRatedTrans.toFixed(2),
      vatOnReturn: report.vatOnReturn?.toFixed(2) || "0",
      otherVatAdjustments: report.otherVATAdjustment.toFixed(2),

      cashInDrawer: "0",
      tenderBreakdown: report.tenderBreakdown,
      openingFund: report.openingFund.toFixed(2),
      lessWithdrawal: report.lessWithdrawal?.toFixed(2) || "0",
      paymentReceived: report.paymentReceived.toFixed(2),

      shortOver: "0",

      country: business?.country?.codeAlpha2,
    })

    await printToThermal(characteristic, encodedValues.encode())
    server.disconnect()
    await logReportPrint(logId)
    await createUserActivity()
  }

  if (!report) {
    return null
  }

  let birDetails = ""
  if (business?.country?.codeAlpha2 === "PH") {
    birDetails = dedent`
      ${register?.location?.isVatRegistered ? `VAT REG TIN: ${register?.location?.tin}` : "NON VAT REG TIN"}
      MIN: ${register?.min}
      S/N: ${register?.serialNo}  
    `
  }

  const textExport = dedent`
  ${business?.name}
  Operated by: ${register?.location?.companyName}
  ${register?.location?.addressLine1}
  ${birDetails}
  
  Z-READING REPORT
  
  Report Date: ${format(currentDate, "MM/dd/yyyy")}
  Report Time: ${format(currentDate, "hh:mm a")}
  Start Date & Time: ${format(parseISO(report?.openedAt), "MM/dd/yyyy hh:mm a")}
  End Date & Time: ${format(parseISO(report?.closedAt), "MM/dd/yyyy hh:mm a")}
  
  Beg. SI: ${report.beginningOR}
  End SI: ${report.endingOR}
  Beg. Void: ${report.beginningVoid}
  End Void: ${report.endingVoid}
  Beg. Return: ${report.beginningReturn}
  End Return: ${report.endingReturn}
  
  Reset Counter No.: 0
  Z Counter No.: 0
  
  Present Accumulated Sales: 0
  Previous Accumulated Sales: 0
  Sales for the Day: ${report.sales.toFixed(2)}
  
  BREAKDOWN OF SALES
  Vatable Sales: ${report.vatableSales.toFixed(2)}
  VAT Amount: ${report.vatAmount.toFixed(2)}
  VAT Exempt Sales: 0
  Zero Rated Sales: ${report.zeroRatedSales.toFixed(2)}
  Gross Amount: ${report.grossSales.toFixed(2)}
  Less Discount: ${report.discount.toFixed(2)}
  Less Return: ${report.lessReturn.toFixed(2)}
  Less Void: 0
  Less VAT Adjustment: ${report.lessVat.toFixed(2)}
  Net Amount: ${netSales.toFixed(2)}
  
  DISCOUNT SUMMARY
  SC Disc: ${report.seniorCitizenDiscount.toFixed(2)}
  PWD Disc: ${report.pwdDiscount.toFixed(2)}
  NAAC Disc: ${report.nationalAthleteDiscount.toFixed(2)}
  Solo Parent Disc: 0
  Other Disc: ${report.discount.toFixed(2)}
  
  SALES ADJUSTMENT
  Void: 0
  Return: ${report.refunds.toFixed(2)}
  
  VAT ADJUSTMENT
  SC Trans. : ${report.seniorCitizenTrans.toFixed(2)}
  PWD Trans. : ${report.pwdTrans.toFixed(2)}
  REG. Disc. Trans. : ${report.regularDiscountTrans.toFixed(2)}
  ZERO-RATED Trans. : ${report.zeroRatedTrans.toFixed(2)}
  VAT on Return: ${report.vatOnReturn?.toFixed(2) || "0"}
  Other VAT Adjustments: ${report.otherVATAdjustment.toFixed(2)}
  
  TRANSACTION SUMMARY
  Cash in Drawer: 0
  ${report.tenderBreakdown.map((item) => `${item.paymentMethodCode}: ${item.sum.toFixed(2)}`).join("\n")}
  Opening Fund: ${report.openingFund.toFixed(2)}
  Less Withdrawal: ${report.lessWithdrawal?.toFixed(2) || "0"}
  Payment Received: ${report.paymentReceived.toFixed(2)}
  SHORT/OVER: 0
  `

  return (
    <div className="flex w-full flex-col p-4" ref={componentRef}>
      <PageHeader title={Title} hasGoBack />
      <div className="flex flex-col w-full bg-white p-6  mt-4 rounded-md">
        <div className="grid grid-cols-1 gap-4 p-3 ">
          <div className="flex flex-col ">
            <div className="flex flex-col">
              <span className="text-2xl text-primary-900 font-bold">
                General
              </span>

              <hr className="my-4" />

              <div className="flex gap-4 py-4 mb-4">
                <Select
                  label={t("Register")}
                  containerClassName="w-[30%]"
                  value={cashRegisterId}
                  // onChange={(e) => setSelectedCashRegister(e.target.value)}
                  disabled
                >
                  <option value="" disabled selected>
                    {t("Select cash register")}
                  </option>
                  <option value={register?.id}>{register?.name}</option>
                </Select>

                <Input
                  label={"Date"}
                  type="date"
                  value={format(parseISO(closedAt), "yyyy-MM-dd")}
                  disabled
                />

                <div className="ml-auto flex w-full md:mb-0 md:w-auto">
                  <DownloadTXTButton
                    text={textExport}
                    filename={"bir-z-report"}
                    next={createUserActivity}
                  />
                  <DownloadCSVButton
                    headers={[]}
                    data={dataReportCsv}
                    fileName={"bir-report"}
                    onClick={createUserActivity}
                  />
                  <Tooltip title={"Download"}>
                    <IconButton
                      className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
                      onClick={handleDownload}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Print"}>
                    <IconButton
                      className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
                      onClick={handlePrintThermal}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <BIRZReport
                report={report}
                business={business}
                register={register}
                currentDate={currentDate}
                ref={printTarget}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const BIRZReport = forwardRef<
  HTMLDivElement,
  {
    report: ZReportResponse[number]
    business?: Business
    register?: GetCashRegisterResponse
    currentDate: Date
    hideHeader?: boolean
    disableMaxWidth?: boolean
    containerClassName?: string
  }
>(
  (
    {
      report,
      business,
      register,
      currentDate,
      hideHeader,
      disableMaxWidth,
      containerClassName,
    },
    ref
  ) => {
    const netSales = Big(report?.grossSales || 0)
      .minus(report?.discount || 0)
      .minus(report?.refunds || 0)
      .minus(report?.vatAmount || 0)
      .round(2)
      .toNumber()

    return (
      <div className={clsx(!disableMaxWidth && "max-w-lg mx-auto")}>
        <div ref={ref} className={clsx("px-6", containerClassName)}>
          <div>
            {!hideHeader && (
              <>
                <h2 className={"text-center font-bold text-lg"}>
                  {business?.name}
                </h2>
                {register?.location?.companyName && (
                  <p className={"text-center font-bold text-lg"}>
                    Operated By: {register?.location?.companyName}
                  </p>
                )}
                <p className={"text-center font-bold text-lg"}>
                  {register?.location?.addressLine1}
                </p>

                {business?.country?.codeAlpha2 === "PH" && (
                  <>
                    {register?.location?.isVatRegistered ? (
                      <p className={"text-center font-bold text-lg"}>
                        VAT REG TIN: {register?.location?.tin}
                      </p>
                    ) : (
                      <p className={"text-center font-bold text-lg"}>
                        NON VAT REG TIN
                      </p>
                    )}
                    <p className={"text-center font-bold text-lg"}>
                      MIN: {register?.min}
                    </p>
                    <p className={"text-center font-bold text-lg"}>
                      S/N: {register?.serialNo}
                    </p>
                  </>
                )}
              </>
            )}

            <p
              className={clsx(
                "text-center font-bold text-lg ",
                !hideHeader && "mt-8"
              )}
            >
              Z-READING REPORT
            </p>
          </div>

          <div className={clsx("w-full mx-auto mt-4")}>
            <table className={"w-full"}>
              <tbody>
                <tr>
                  <td className={"text-left"}>Report Date</td>
                  <td className={"text-right"}>
                    {format(currentDate, "MM/dd/yyyy")}
                  </td>
                </tr>
                <tr className={"border-dashed border-b border-gray-400"}>
                  <td className={"text-left"}>Report Time</td>
                  <td className={"text-right"}>
                    {format(currentDate, "hh:mm a")}
                  </td>
                </tr>
                {report?.openedAt && (
                  <tr>
                    <td className={"text-left"}>Start Date & Time</td>
                    <td className={"text-right"}>
                      {format(parseISO(report?.openedAt), "MM/dd/yyyy hh:mm a")}
                    </td>
                  </tr>
                )}

                {report?.closedAt && (
                  <tr className={"border-b border-dashed border-gray-400"}>
                    <td className={"text-left"}>End Date & Time</td>
                    <td className={"text-right"}>
                      {format(parseISO(report?.closedAt), "MM/dd/yyyy hh:mm a")}
                    </td>
                  </tr>
                )}

                <tr>
                  <td className={"text-left"}>Beg. SI</td>
                  <td className={"text-right"}>{report.beginningOR}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>End SI</td>
                  <td className={"text-right"}>{report.endingOR}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>Beg. Void</td>
                  <td className={"text-right"}>{report.beginningVoid}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>End Void</td>
                  <td className={"text-right"}>{report.endingVoid}</td>
                </tr>
                {/*<tr>*/}
                {/*  <td className={"text-left"}>Beg. Return</td>*/}
                {/*  <td className={"text-right"}>{report.beginningReturn}</td>*/}
                {/*</tr>*/}
                {/*<tr>*/}
                {/*  <td className={"text-left"}>End Return</td>*/}
                {/*  <td className={"text-right"}>{report.endingReturn}</td>*/}
                {/*</tr>*/}
                <tr className={"h-4"} />
                <tr>
                  <td className={"text-left"}>Reset Counter No.</td>
                  <td className={"text-right"}>0</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Z Counter No.</td>
                  <td className={"text-right"}>{report.zCounter}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>Present Accumulated Sales</td>
                  <td className={"text-right"}>0</td>
                </tr>
                <tr>
                  <td className={"text-left"}>Previous Accumulated Sales</td>
                  <td className={"text-right"}>0</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Sales for the Day</td>
                  <td className={"text-right"}>{report.sales.toFixed(2)}</td>
                </tr>

                <tr>
                  <td colSpan={2} className={"text-center"}>
                    BREAKDOWN OF SALES
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Vatable Sales</td>
                  <td className={"text-right"}>
                    {report.vatableSales.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>VAT Amount</td>
                  <td className={"text-right"}>
                    {report.vatAmount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>VAT Exempt Sales</td>
                  <td className={"text-right"}>0</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Zero Rated Sales</td>
                  <td className={"text-right"}>
                    {report.zeroRatedSales.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Gross Amount</td>
                  <td className={"text-right"}>
                    {report.grossSales.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Less Discount</td>
                  <td className={"text-right"}>{report.discount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>Less Return</td>
                  <td className={"text-right"}>
                    {report.lessReturn.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Less Void</td>
                  <td className={"text-right"}>0</td>
                </tr>
                <tr>
                  <td className={"text-left"}>Less VAT Adjustment</td>
                  <td className={"text-right"}>{report.lessVat.toFixed(2)}</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Net Amount</td>
                  <td className={"text-right"}>{netSales.toFixed(2)}</td>
                </tr>

                <tr>
                  <td colSpan={2} className={"text-center"}>
                    DISCOUNT SUMMARY
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>SC Disc</td>
                  <td className={"text-right"}>
                    {report.seniorCitizenDiscount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>PWD Disc</td>
                  <td className={"text-right"}>
                    {report.pwdDiscount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>NAAC Disc</td>
                  <td className={"text-right"}>
                    {report.nationalAthleteDiscount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Solo Parent Disc</td>
                  <td className={"text-right"}>0</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Other Disc</td>
                  <td className={"text-right"}>{report.discount.toFixed(2)}</td>
                </tr>

                <tr>
                  <td colSpan={2} className={"text-center"}>
                    SALES ADJUSTMENT
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Void</td>
                  <td className={"text-right"}>
                    {(report.refunds + report.void).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} className={"text-center"}>
                    VAT ADJUSTMENT
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>SC TRANS. :</td>
                  <td className={"text-right"}>
                    {report.seniorCitizenTrans.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>PWD TRANS. :</td>
                  <td className={"text-right"}>{report.pwdTrans.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>REG. DISC. TRANS. :</td>
                  <td className={"text-right"}>
                    {report.regularDiscountTrans.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>ZERO-RATED TRANS. :</td>
                  <td className={"text-right"}>
                    {report.zeroRatedTrans.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>VAT on Return:</td>
                  <td className={"text-right"}>
                    {report.vatOnReturn?.toFixed(2)}
                  </td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Other VAT Adjustments:</td>
                  <td className={"text-right"}>
                    {report.otherVATAdjustment.toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} className={"text-center"}>
                    TRANSACTION SUMMARY
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Cash in Drawer</td>
                  <td className={"text-right"}>0</td>
                </tr>
                {report.tenderBreakdown.map((item) => (
                  <tr>
                    <td className={"text-left"}>{item.paymentMethodCode}</td>
                    <td className={"text-right"}>{item.sum.toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td className={"text-left"}>Opening Fund</td>
                  <td className={"text-right"}>
                    {report.openingFund.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Less Withdrawal</td>
                  <td className={"text-right"}>
                    {report.lessWithdrawal?.toFixed(2) || "0"}
                  </td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Payment Received</td>
                  <td className={"text-right"}>
                    {report.paymentReceived.toFixed(2)}
                  </td>
                </tr>

                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>SHORT/OVER</td>
                  <td className={"text-right"}>{0}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
)

export default BIRZ
