import { calculateBIRReceiptDetails } from "../bir-receipt"
import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import {
  encodeBirFooter,
  encodeBirHeader,
  printBorder,
  printSplitTable,
} from "./helper"
import { format, parseISO } from "date-fns"
import { BIR_LABEL, BIRReceiptDetails } from "../models"
import axios from "axios"
import Big from "big.js"
import {
  getActualCashChange,
  getActualCashReceived,
} from "../../hooks/api/use-cart-transaction"

export const encodeBIRReceipt = (
  details: BIRReceiptDetails,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  if (details.type === "DEFAULT") {
    if (details.trx.orderStatus === "VOID") {
      return encodeBIRVoidReceipt(details, encoderOptions)
    } else if (details.trx.orderStatus === "RETURN_ACCEPTED") {
      return encodeBIRRefundReceipt(details, encoderOptions)
    }
  }

  return encodeDefaultBIRReceipt(details, encoderOptions)
}

export const encodeDefaultBIRReceipt = async (
  details: BIRReceiptDetails,
  encoderOptions: ThermalPrinterEncoderOption
) => {
  const width = encoderOptions.width || 32

  const {
    customerLocation,
    totalTaxApplied,
    totalTaxExemption,
    vatSales,
    vatExemptSales,
    zeroRatesSales,
    totalIncludedTaxes,
    originalORNumber,
    originalTransNumber,
  } = calculateBIRReceiptDetails(details.trx)

  let encoder = new ThermalPrinterEncoder({
    ...encoderOptions,
    imageMode: "raster",
  }).initialize()

  const logo =
    details.invoiceSettings?.printLogo || details.business?.logoUrl || ""
  if (details.invoiceSettings.showLogo && logo) {
    try {
      const loadImage = (src: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          axios
            .get(src, {
              responseType: "blob",
              headers: {
                "Cache-Control": "no-cache",
              },
            })
            .then((response) => {
              const img = new Image()
              img.crossOrigin = "anonymous"
              img.src = URL.createObjectURL(response.data)

              img.onload = () => {
                URL.revokeObjectURL(img.src) // Clean up
                resolve(img)
              }
              img.onerror = (err) => {
                URL.revokeObjectURL(img.src)
                reject(err)
              }
            })
        })
      }
      const image = await loadImage(logo)
      encoder = encoder
        .align("center")
        .image(image, 160, 160, "atkinson")
        .newline()
        .newline()
    } catch (e) {
      console.log(e)
    }
  }

  encoder = encodeBirHeader(encoder, {
    cashRegister: details.trx.cashRegister,
    location: details.trx.location,
    business: details.business,
  })
    .align("center")
    .newline()
    .newline()

  const isReprint =
    !!details.trx.receiptHistory?.filter((r) => r.printed)?.length &&
    details.type !== "ORIGINAL"
  if (isReprint) {
    encoder = encoder
      .bold(true)
      .line("*****REPRINT*****")
      .bold(false)
      .line(format(new Date(), "MM/dd/yyyy HH:mm:ss"))
  }

  encoder = printBorder(encoder, width, { noSpacing: true })
  const receiptDetails = [
    ["Date:", format(parseISO(details.trx.createdAt), "MM/dd/yyyy HH:mm:ss")],
    ["Invoice No.:", ""],
    [originalORNumber || details.nextORNumber || "", ""],
  ]
  if (
    details.invoiceSettings.showReferenceNumber &&
    details.trx.paymentId &&
    details.trx.paymentMethodCode === "CUSTOM"
  ) {
    receiptDetails.push(["Reference No.:", ""])
    receiptDetails.push([details.trx.paymentId, ""])
  }
  if (
    details.invoiceSettings.showReferenceNumber &&
    details.trx.paymentMethodCode === "SPLIT_PAYMENT"
  ) {
    details.trx.splitPaymentDetails?.map((el) => {
      if (el.paymentId && el.paymentId !== "") {
        receiptDetails.push(["Reference No.:", ""])
        receiptDetails.push([el.paymentId, ""])
      }
    })
  }
  encoder = encoder.table(
    [
      { width: 13 - 1, marginRight: 2, align: "left" },
      { width: width - 13 - 1, align: "right" },
    ],
    receiptDetails
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder.table(
    [
      { width: width - 17 - 1, marginRight: 2, align: "left" },
      { width: 17 - 1, align: "right" },
    ],
    [
      [
        `Staff: ${details.trx.createdBy}`,
        `Trans No. ${originalTransNumber || details.nextSeriesNumber}`,
      ],
      [details.trx.paymentMethodCode, ""],
    ]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  if (details.trx.shipment?.shipmentType === "POS_MANUAL") {
    encoder = printBorder(encoder, width, { noSpacing: true })
    encoder = printSplitTable(encoder, width, width / 2, [
      [
        `Order Number: ${details.trx.orderNumber}`,
        `Booking Code: ${details.trx.shipment.quoteDetails.bookingCode}`,
      ],
    ])
      .align("left")
      .line(
        `Customer: ${details.trx.shipment.quoteDetails.customerName || "-"}`
      )
      .line(`Address: ${details.trx.shipment.quoteDetails.address || "-"}`)
      .line(`City: ${details.trx.shipment.quoteDetails.city || "-"}`)
      .line(
        `Phone Number: ${details.trx.shipment.quoteDetails.phoneNumber || "-"}`
      )

    encoder = printBorder(encoder, width, { noSpacing: true })
  }

  encoder = encoder
    .table(
      [
        { width: 12 - 1, marginRight: 2, align: "left" },
        { width: width - 12 - 1, align: "right" },
      ],
      [
        [`Customer:`, details.trx.customer?.fullName || "Walk-in Customer"],
        ["ID No. :", details.trx.customer?.membershipCode || ""],
        ["ADDRESS:", customerLocation?.addressLine1 || ""],
        ["TIN:", details.trx.customer?.taxId || ""],
        ["SC ID No.:", details.trx.customer?.seniorCitizenId || ""],
        ["Signature:", ""],
      ]
    )
    .newline()
    .newline()
  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder
    .table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      [
        [
          "Item(s)",
          details.trx.orderedProducts
            .reduce((acc, p) => acc + p.quantity, 0)
            .toString(),
        ],
      ]
    )
    .newline()

  encoder = encoder.align("left")
  details.trx.orderedProducts.forEach((p) => {
    encoder = encoder.table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      [
        [
          `${p.name} - ${p.quantity}x @${p.sellingPrice}`,
          p.totalPrice.toFixed(2),
        ],
      ]
    )

    const variantName = p.productOptionValues?.map((el) => el.name).join(" / ")
    if (variantName) {
      encoder = encoder.line(`(${variantName})`)
    }
    if (!!p.orderedProductAddOns?.length) {
      encoder = encoder.line("Add Ons:")

      p.orderedProductAddOns.forEach((el) => {
        const totalPrice = Big(el.quantity).mul(el.sellingPrice).toFixed(2)
        encoder = encoder.table(
          [
            { width: 20 - 1, marginRight: 2, align: "left" },
            { width: width - 20 - 1, align: "right" },
          ],
          [
            [
              `${el.name} - ${el.quantity}x@${el.sellingPrice.toLocaleString()}`,
              totalPrice,
            ],
          ]
        )
      })
    }

    encoder = encoder.newline()
  })

  let summaryDetails = [
    [`Subtotal`, (details.trx.totalCartAmount + totalIncludedTaxes).toFixed(2)],
    ...(
      details.trx.serviceCharge ? [
        [
          "Service Charge", details.trx.serviceCharge.toFixed(2)
        ]
      ] : []
    ),
    [`Total Sales`, details.trx.totalCartAmount.toFixed(2)],
  ]
  if (details.trx.discount) {
    const discountAmount = details.trx.promotionDetails?.[0]?.discountPercentage
      ? `- ${details.trx.promotionDetails?.[0]?.discountPercentage}%`
      : ""
    summaryDetails.push([
      (BIR_LABEL[details.trx.promotionDetails?.[0]?.code || ""] || "Discount") +
        discountAmount,
      details.trx.nonTaxDiscounts?.toFixed(2) || "0",
    ])
  }
  details.trx.taxes?.forEach((t) => {
    summaryDetails.push([
      `- ${t.name} ${t.rate}% ${t.type === "INCLUDED" ? "(Included)" : ""}`,
      t.amount.toFixed(2),
    ])
  })
  summaryDetails.push(["Total amount due", details.trx.grandTotal.toFixed(2)])
  encoder = encoder
    .table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      summaryDetails
    )
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Cash Received", getActualCashReceived(details.trx).toFixed(2)],
        ["Cash Change", getActualCashChange(details.trx).toFixed(2)],
      ]
    )
    .newline()

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 4, align: "left" },
      { width: width / 4, align: "right" },
      { width: width / 4, align: "right" },
      { width: width / 4, align: "right" },
    ],
    [
      ["", "Net.Amt", "VAT", "Amount"],
      [
        "VATABLE",
        vatSales.toFixed(2),
        totalTaxApplied.toFixed(2),
        (vatSales + totalTaxApplied).toFixed(2),
      ],
      [
        "EXEMPT",
        vatExemptSales.toFixed(2),
        totalTaxExemption.toFixed(2),
        (vatExemptSales + totalTaxExemption).toFixed(2),
      ],
      ["ZERO-R", zeroRatesSales.toFixed(2), "0.00", zeroRatesSales.toFixed(2)],
    ]
  )

  // if (details.trx.location.isVatRegistered === "Yes") {
  //   encoder = printBorder(encoder, width, { noSpacing: true })
  //   encoder = encoder
  //     .newline()
  //     .line("THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT TAX")
  //     .newline()
  //   encoder = printBorder(encoder, width, { noSpacing: true })
  // }

  if (details.invoiceSettings.message) {
    encoder = encoder.newline().line(details.invoiceSettings.message).newline()
  }

  encoder = encodeBirFooter(encoder).newline().newline()

  if (details.invoiceSettings.disclaimer) {
    encoder = encoder.align("center").line(details.invoiceSettings.disclaimer)
  }

  return encoder.newline().cut().encode()
}

export const encodeBIRVoidReceipt = (
  details: BIRReceiptDetails,
  encoderOptions: ThermalPrinterEncoderOption
) => {
  const width = encoderOptions.width || 32

  const { originalORNumber, originalTransNumber } = calculateBIRReceiptDetails(
    details.trx
  )

  let encoder = new ThermalPrinterEncoder(encoderOptions).initialize()

  const originalReceipt = details.trx.receiptHistory?.find(
    (el) => el.type === "VOID"
  )

  encoder = encodeBirHeader(encoder, {
    cashRegister: details.trx.cashRegister,
    location: details.trx.location,
    business: details.business,
  })
    .align("center")
    .line("VOID TRANS")
    // .line("Official Receipt Number" + details.trx.orderNumber)
    .line("*****VOID*****")
    .line(
      "Void Trans No. " +
        (originalReceipt?.seriesNumber || details.nextSeriesNumber)
    )
    .newline()
    .newline()

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: 13 - 1, marginRight: 2, align: "left" },
      { width: width - 13 - 1, align: "right" },
    ],
    [
      ["Date:", format(parseISO(details.trx.createdAt), "MM/dd/yyyy HH:mm:ss")],
      ["Invoice No.:", ""],
      [originalORNumber || details.nextORNumber || "", ""],
    ]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder.table(
    [
      { width: width - 17 - 1, marginRight: 2, align: "left" },
      { width: 17 - 1, align: "right" },
    ],
    [
      [`Staff: ${details.trx.createdBy}`, `Trans No. ${originalTransNumber}`],
      [details.trx.paymentMethodCode, ""],
    ]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Cash Received", getActualCashReceived(details.trx).toFixed(2)],
        ["Cash Change", getActualCashChange(details.trx).toFixed(2)],
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 4, align: "left" },
        { width: width / 4, align: "right" },
        { width: width / 4, align: "right" },
        { width: width / 4, align: "right" },
      ],
      [
        ["Item", "QTY", "Price", "Amount"],
        ...details.trx.orderedProducts.map((p) => {
          let name = `${p.name}`

          return [
            name,
            p.quantity.toString(),
            p.sellingPrice.toFixed(2),
            p.totalPrice.toFixed(2),
          ]
        }),
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Subtotal", details.trx.totalCartAmount.toFixed(2)],
        ...(
          details.trx.serviceCharge ? [
            [
              "Service Charge", details.trx.serviceCharge.toFixed(2)
            ]
          ] : []
        ),
        ["Tax", details.trx.tax.toFixed(2)],
        ["Total Amount", details.trx.grandTotal.toFixed(2)],
      ]
    )
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Authorized Signature:", "_________"],
        ["Address:", "_________"],
        ["Phone #:", "_________"],
        ["Reason", "_________"],
      ]
    )
    .newline()

  encoder = encodeBirFooter(encoder)
    .newline()
    .line(
      "THIS RECEIPT SHALL BE VALID FOR FIVE (5) YEARS FROM THE DATE OF THE PERMIT TO USE"
    )

  if (details.invoiceSettings.disclaimer) {
    encoder = encoder
      .newline()
      .newline()
      .line(details.invoiceSettings.disclaimer)
  }

  return encoder.newline().newline().newline().cut().encode()
}

export const encodeBIRRefundReceipt = (
  details: BIRReceiptDetails,
  encoderOptions: ThermalPrinterEncoderOption
) => {
  const width = encoderOptions.width || 32

  const { originalORNumber, originalTransNumber } = calculateBIRReceiptDetails(
    details.trx
  )

  const originalReceipt = details.trx.receiptHistory?.find(
    (el) => el.type === "REFUND"
  )

  let encoder = new ThermalPrinterEncoder(encoderOptions).initialize()
  encoder = encodeBirHeader(encoder, {
    cashRegister: details.trx.cashRegister,
    location: details.trx.location,
    business: details.business,
  })
    .align("center")
    .line("REFUND TRANS" + details.trx.cashRegister?.serialNo)
    .line("Official Receipt Number" + details.trx.orderNumber)
    .line("*****REFUND*****")
    .line(
      "Refund Trans No. " +
        (originalReceipt?.seriesNumber || details.nextSeriesNumber)
    )
    .newline()
    .newline()

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: 13 - 1, marginRight: 2, align: "left" },
      { width: width - 13 - 1, align: "right" },
    ],
    [
      ["Date:", format(parseISO(details.trx.createdAt), "MM/dd/yyyy HH:mm:ss")],
      ["Invoice No.:", ""],
      [originalORNumber || details.nextORNumber || "", ""],
    ]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder.table(
    [
      { width: width - 17 - 1, marginRight: 2, align: "left" },
      { width: 17 - 1, align: "right" },
    ],
    [
      [
        `Staff: ${details.trx.createdBy}`,
        `Trans No. ${originalTransNumber || details.nextSeriesNumber}`,
      ],
      [details.trx.paymentMethodCode, ""],
    ]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Cash Received", getActualCashReceived(details.trx).toFixed(2)],
        ["Cash Change", getActualCashChange(details.trx).toFixed(2)],
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 4, align: "left" },
        { width: width / 4, align: "right" },
        { width: width / 4, align: "right" },
        { width: width / 4, align: "right" },
      ],
      [
        ["Item", "QTY", "Price", "Amount"],
        ...details.trx.orderedProducts.map((p) => {
          let name = `${p.name}`

          return [
            name,
            p.quantity.toString(),
            p.sellingPrice.toFixed(2),
            p.totalPrice.toFixed(2),
          ]
        }),
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Subtotal", details.trx.totalCartAmount.toFixed(2)],
        ["Tax", details.trx.tax.toFixed(2)],
        ["Total Amount", details.trx.grandTotal.toFixed(2)],
      ]
    )
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Authorized Signature:", "_________"],
        ["Address:", "_________"],
        ["Phone #:", "_________"],
        ["Reason", "_________"],
      ]
    )
    .newline()

  encoder = encodeBirFooter(encoder)
    .newline()
    .line(
      "THIS RECEIPT SHALL BE VALID FOR FIVE (5) YEARS FROM THE DATE OF THE PERMIT TO USE"
    )

  if (details.invoiceSettings.disclaimer) {
    encoder = encoder
      .newline()
      .newline()
      .line(details.invoiceSettings.disclaimer)
  }

  return encoder.newline().newline().newline().cut().encode()
}
