// Contains common interfaces used for logics inside `web-portal`. Don't use
// these to type data returned by API endpoints because API changes a lot by its
// own.

import { FC } from "react"
import { Business as GetBusinessResponse } from "../hooks/api/use-business"
import { Locations } from "../hooks/api/use-locations"
import { GetCartTransactionResponse } from "../hooks/api/use-cart-transaction"
import { InvoiceSettingsResponseBody } from "../hooks/api/use-invoice-settings"

export type DeliveryDetails = {
  customerId: string
  customerName: string
  address: string
  city: string
  postalCode: string
  phoneNumber: string
  bookingCode: string
  deliveryFee: number
  courierName: string
  otherFees: number
  deliveryDate: Date
  customerLocationId: string
}

export interface Cart {
  promotion: Promotion | null
  promotionMetadata?: PromotionMetadata
  items: CartItem[]
  manualDiscount: number
  printReceipt: boolean
  deliveryDetails: DeliveryDetails | null
  queueNumber?: number
  paymentId?: string
  localTransactionId: string
  referralCode?: string
}

export type CartItem = GoodsCartItem | ServiceCartItem | DigitalCartItem

export interface GoodsCartItem extends BaseItem {
  type: "GOODS"
}

export interface DigitalCartItem extends BaseItem {
  type: "DIGITAL"
}

export interface ServiceCartItem extends BaseItem {
  type: "SERVICE"
  details: {
    startDate: Date
    startTime: string
    endDate: Date | undefined
    endTime: string | undefined
    employee?: string
    duration: number | undefined
    location: string
    slots: string[]
    multiEmployee: boolean
    // used on appointments
    bundleEmployees: Array<{
      product: string
      variant: string
      employee: string
    }>
  }
}

export type ManualDiscountType = "PERCENTAGE" | "PRICE"

export interface BaseItem {
  id: string
  type: PRODUCT_TYPE_STRING
  qty: number
  product: Product
  location: string
  sellingPrice: number
  discount: number
  discountType?: ManualDiscountType
  partialPayment: boolean
  deposit: number
  outstanding: number
  productLocation: ProductLocation
  productVariant?: string | null
  taxIncluded?: boolean
  orderedProductAddOns?: OrderedProductAddOns[]
  isCustomPrice?: boolean
  notes?: string
  printSticker?: boolean

  // Use for SERVICE_GOODS items that doesn't have appointments
  employees?: Array<{
    employee: string
    product?: string
    variant?: string
  }>
}

export interface OrderedProductAddOns {
  productAddOns: {
    id: string
    name: string
    sellingPrice: number
    sellingPriceCurrency: string
  }
  quantity: number
}

export interface Product {
  useProduction?: boolean
  id: string
  name: string
  SKU: string
  productType: PRODUCT_TYPE_STRING
  sellingPrice: number
  sellingPriceCurrency: string
  description?: string
  stockInHand?: number
  imageUrl?: string
  images?: any
  stock?: number
  unitOfMeasure?: string
  supplierId?: string
  category?: string
  inventory?: string
  autoGenerateSKU?: boolean
  beginningStock?: number
  MRP: number
  MRPCurrency: string
  reorderQuantity?: number
  receivedDate?: string
  expirationDate?: string
  isVisible?: boolean
  isReturnable?: boolean
  productOptionValues?: any
  isVisibleOnEstore?: boolean
  locations?: string[]
  applyToAllLocation?: boolean
  isBundle: boolean
  bundleItems?: BundleItem[]
  productLocation?: ProductLocation[]
  weight: number
  height: number
  quantity?: number
  width: number
  length: number
  serviceType?: string
  serviceDurationUnit?: string
  serviceDuration?: number
  serviceSlotTime?: any
  productVariant?: any

  // original variant returned by API
  productVariants?: any
  productVariantActiveOnCart?: any
  maxCustomerPerSlot?: number
  gapBetweenAppointmentTime?: number
  reminderTime?: number
  totalQuantity?: number
  isAvailable?: boolean
  termsCondition?: string
  deposit?: number
  depositUnitOfMeasurement?: DepositUnit
  allowPartialPayment?: boolean
  productOptions?: Array<{
    id: string
    name: string
    values: Array<{
      id: string
      name: string
    }>
  }>
  nonInventory?: boolean
  taxable?: boolean
  taxIncluded?: boolean
  wholesalePrice?: number
  productAddOnsGroup?: ProductAddOnsGroup[]
  purchaseUnitOfMeasure?: string
  conversionNumber?: number
  minimumQuantity?: number
  recommendedQuantity?: number
  enableBIR?: boolean

  productToSupplier?: any
}

export type DepositUnit = "NUMBER" | "PERCENTAGE"

export interface ProductLocation {
  locationId: string
  beginningStock: number
  sellingPriceCurrency: string
  sellingPrice: number
  wholesalePrice?: number
  stockInHand?: number
  created_at?: string
  isVisible?: boolean
  isVisibleOnEstore?: boolean
  receivedDate?: string
  expirationDate?: string
  totalQuantity?: number
  MRPCurrency?: string
  deposit?: number
  depositUnitOfMeasurement?: DepositUnit
  allowPartialPayment?: boolean
  productVariantId?: string
  nonInventory?: boolean
  MRP?: number
  reorderQuantity?: number
}

export interface BundleItem {
  id: string
  name?: string
  quantity: number
  nonInventory: boolean
  productLocation: Array<{
    locationId: string
    MRP?: number
  }>
  variant?: string
}

export interface Promotion {
  id: string
  name: string
  code: string
  discountPercentage: number
  discountFixedAmount: number
  minItemQuantity: any
  minPurchaseAmount: number
  maxUsesPerOrder: any
  limitTotal: any
  isLimitedToOnePerCustomer: boolean
}

export interface Customer {
  id?: string
  fullName: string
  membershipCode: string
  email: string
  phone: string
  dob: string
  firebaseId?: string
  business?: {
    id: string
  }
  stripeCustomerId?: string
  customerType?: string
  termType?: string
  points?: number
  cashback?: number
  enableCashback?: boolean
  paymentTerm?: {
    id: string
    name: string
    days: number
    isDefault: boolean
    isEnabled: boolean
    createdAt: string
    updatedAt: string
  }
  paymentTerms?: any
  approvalStatus?: string
  totalSpend?: number
  point?: number
  notes?: string
  contactName?: string | null
}

export const PAYMENT_TYPES = ["CASH", "ONLINE", "PAY LATER", "INVOICE"] as const

export type PaymentType = (typeof PAYMENT_TYPES)[number]

export interface Transaction {
  id: string
  buxPaymentStatus: string
  isServiceOrder: boolean
}

export interface CachedTransactionData {
  id: string
  appointmentIds: string[]
  businessId: string
}

export type ORDER_STATUS =
  | "PENDING"
  | "APPROVED"
  | "PREPARING"
  | "READY_FOR_PICKUP"
  | "READY_FOR_DELIVERY"
  | "PICKED_UP"
  | "CANCELLED"
  | "VOID"
  | "PAID"
  | "PARTIALLY_PAID"

export enum PRODUCT_TYPE {
  GOODS = "GOODS",
  SERVICE_GOODS = "SERVICE_GOODS",
  FOOD_SERVICE = "FOOD_SERVICE",
  OPERATION_SUPPLIES = "OPERATION_SUPPLIES",
  SERVICE = "SERVICE",
  RENTAL_SERVICE = "RENTAL_SERVICE",
  DIGITAL = "DIGITAL",
}

export type PRODUCT_TYPE_STRING =
  | "GOODS"
  | "SERVICE_GOODS"
  | "FOOD_SERVICE"
  | "OPERATION_SUPPLIES"
  | "SERVICE"
  | "RENTAL_SERVICE"
  | "DIGITAL"

export interface ProductCategory {
  id: string
  name: string
  productType: PRODUCT_TYPE
  productsCount: number
}

export interface Inventory {
  id: string
  name: string
  productType: PRODUCT_TYPE
  isVisible: boolean
}

export const TYPE_OPTIONS: Record<PRODUCT_TYPE, string> = {
  [PRODUCT_TYPE.GOODS]: "Products",
  [PRODUCT_TYPE.SERVICE_GOODS]: "Services",
  [PRODUCT_TYPE.FOOD_SERVICE]: "Products",
  [PRODUCT_TYPE.SERVICE]: "Appointments",
  [PRODUCT_TYPE.OPERATION_SUPPLIES]: "Operation Supplies",
  [PRODUCT_TYPE.RENTAL_SERVICE]: "Rental Service",
  [PRODUCT_TYPE.DIGITAL]: "Digital",
}

export const SERVICE_DURATIONS = [15, 30, 45, 60, 90, 120] as const

export const UNIT_OF_MEASUREMENTS = [
  "Per Item",
  "Per Inch",
  "Per Foot",
  "Per Yard",
  "Per Mile",
  "Per Ounce (fl oz)",
  "Per Pint",
  "Per Cup",
  "Per Gallon",
  "Per Litre",
  "Per Ounce (oz)",
  "Per Pound",
  "Per Gram",
  "Per Kilogram",
  "Per Hour",
  "Per Metre",
] as const

export type FCWithLoading<P, L = {}> = FC<P> & {
  Loading: FC<L>
}

export interface Supplier {
  id?: string
  contactName: string
  billingInfo: string
  paymentTerms: string
  businessId: string
  companyName: string
  companyPhone: string
  contactEmail: string
  contactPhone: string
  isActive: boolean
  created_at: string
  updated_at: string
  createdAt: string
  productsCount: number
  type: string
  attachments?: string[]
}

export enum SupplierType {
  DISTRIBUTOR = "DISTRIBUTOR",
  MANUFACTURER = "MANUFACTURER",
  SHIPPER = "SHIPPER",
  VENDOR = "VENDOR",
  WHOLESALER = "WHOLESALER",
}

export interface PaymentMethod {
  id: string
  method: string
  credential: string
}

export enum PaymentMethodEnum {
  BUX = "Bux",
  PAYPAL = "Paypal",
  STRIPE = "Stripe",
}

export enum FeatureCategory {
  CASH_BOOK = 1,
  CREDIT_BOOK = 2,
  TIMESHEET = 4,
  INVENTORY,
  POS,
  EASY_LOAN,
  HRMS,
  ONLINE_STORE,

  INVOICING,
}

export const ApplicationIdToFeatureCategory: Record<string, number> = {
  cash: 1,
  credit: 2,
  timeTracking: 4,
  inventory: 5,
  mPos: 6,
  payroll: 4,
  hrms: 8,
}

export enum PurchaseOrderStatus {
  NEW = "NEW",
  RELEASED = "RELEASED",
  RECEIVED = "RECEIVED",
  // CHANGED = "CHANGED",
  CANCELLED = "CANCELLED",
  // CLOSED = "CLOSED",
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",

  // DISPATCHED = "DISPATCHED",
  // PARTIAL_DISPATCHED = "PARTIAL_DISPATCHED",
  // DELIVERED = "DELIVERED",
}

export enum POInvoiceStatus {
  // draft is currently
  DRAFT = "DRAFT",
  HOLD = "HOLD",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAID = "PAID",
  RECEIVED = "RECEIVED",
}

export interface PurchaseOrder {
  orderDate: string
  location: {
    id: string
    name: string
    zip: string
    state: string
    city: string
    addressLine1: string
    addressLine2: string
  }
  PONumber: string
  receivedDate: string
  cancelledDate: string
  closedOrderDate: string
  cancelNotes: string
  companyAddress: string
  companyContactNumber: string
  otherCost: number
  releasedDate: string
  shipmentDate: string
  shippingCost: number
  status: PurchaseOrderStatus
  paymentType: string
  orderedProducts: Array<{
    id: string
    product: {
      id: string
      name: string
      MRP: number
      MRPCurrency: string
      description?: string
    }
    productVariant: {
      variantName: string
    }
    quantity: number
    discount: number
    quantityReceived: number
    totalPrice: number
    MRP: number
  }>
  suppliers: Array<{
    id: string
    companyName: string
    companyPhone: string
    contactEmail: string
    contactName: string
    contactPhone: string
    billingInfo: any
    paymentTerms: any
    isActive: boolean
    createdAt: string
    updatedAt: string
  }>
  project?: {
    id: string
    name: string
  }
  // deliveryNotes: string
}

export enum EmploymentTypeLabel {
  FULL_TIME = "Full-Time",
  PART_TIME = "Part-Time",
  FREELANCE = "Freelance",
}

export enum EmploymentType {
  FULL_TIME = "Full",
  PART_TIME = "Part",
  FREELANCE = "Freelance",
}

export const EMPLOYMENT_TYPES = ["Full", "Part", "Freelance"] as const

export interface AppliedEarning {
  id: string
  value: string
  payPeriod: string
  createdAt: string
  updatedAt: string
  earning: {
    id: string
    name: string
    calculationBasis: string
    percentageBasis: string
    payPeriod: string
    value: string
    overtimeType: any
    default: boolean
    createdAt: string
    updatedAt: string
    isMandatory: boolean
  }
}

export interface AppliedDeduction {
  id: string
  value: string
  payPeriod: string
  createdAt: string
  updatedAt: string
  deduction: {
    id: string
    name: string
    calculationBasis: string
    payPeriod: string
    percentageBasis: string
    value: string
    isMandatory: boolean
    showOnPaySlip: boolean
    default: boolean
    createdAt: string
    updatedAt: string
  }
}

export interface AppliedReimbursement {
  id: string
  value: string
  payPeriod: string
  createdAt: string
  updatedAt: string
  reimbursement: {
    id: string
    name: string
    isMandatory: boolean
    showOnPaySlip: boolean
    calculationBasis: string
    percentageBasis: string
    value: string
    payPeriod: string
    maxLimit: string
    default: boolean
    createdAt: string
    updatedAt: string
  }
}

export interface Employee {
  id: string
  employmentType: string
  addressLine1: string
  addressLine2: string
  city: string
  fullName: string
  zip: string
  payroll: any
  phone: string
  dob: string
  nationalId: string
  pin?: string
  // payroll: string
  isManualAddTimesheet: boolean
  role: Role[]
  email: string
  startDate?: string
  lastDate?: string
  location?: {
    id: string
    name: string
  }
  workLocation?: object
  locations?: Array<{
    id: string
    name: string
  }>
  manager?: {
    id: string
  }
  userId?: string

  earnings?: Array<AppliedEarning>
  reimbursements?: Array<AppliedReimbursement>
  deductions?: Array<AppliedDeduction>
}

export interface Role {
  id: string
  roleName: string
  description?: string
  application?: {
    id: string
  }
  permission?: Array<{
    permission: string
    displayName: string
  }>
}

export interface ProductDelivery {
  id: string
  name: string
  description: string
  MRP: number
  MRPCurrency: string
  sellingPrice: number
  sellingPriceCurrency: string
  unitOfMeasure: string
  initialStock: number
  status: unknown
  sold: number
  stockLost: number
  stockReceived: number
  stockReturned: number
  stockDamaged: number
  stockInHand: number
  salesReturned: number
  isUsedPrice: boolean
  expirationDate?: string
  receivedDate: string
  isVisible: boolean
  createdAt: string
  updatedAt: string
  isVisibleOnEstore: boolean
  supplier?: Partial<Supplier>
  product?: Partial<Product>
  location?: {
    id: string
    name: string
  }
  stockConsumed?: number
  productVariant?: any
  stockSpoiled?: number
}

export interface BusinessType {
  key: string
  name: string
}

export interface StripeDetails {
  accountId: string
  firstName: string
  lastName: string
  phone: string
  email: string
  country: string
  city: string
  state: string
  postalCode: string
  line1: string
  line2: string
  currencyCode: string
  routingNumber: string
  accountNumber: string
  tosAcceptanceAt: string
  ipAddress: string
  day: number
  month: number
  year: number
  status: string
  expressLink: string
  idNumber: string
}

export interface InvoiceSettings {
  id: string
  message: string
  showBarcode: string
  showCustomerInfo: string
  showLocation: string
  showOrderNote: string
  showPhone: string
  location: string
  taxPIN: string
  showTaxPIN: any
  width?: number
  disclaimer?: string
}

export interface CartTransaction {
  id: string
  isMpos: boolean
  isServiceOrder: boolean
  promotions: any
  buxPaymentStatus: string
  orderStatus: string
  totalCartAmount: number
  discount: number
  cashReceived: number
  cashChange: number
  totalItemQuantity: number
  orderNumber: number
  tax: number
  shippingCost: number
  paymentMethodName: string
  paymentMethodCode: string
  buxRequestId: string
  pickupTime: any
  serviceCharge: number
  createdAt: string
  updatedAt: string | null
  createdBy: string | null
  updatedBy: string | null
  source?: string
  notes?: string

  outstanding?: number
  promotion: any
  customer?: {
    id?: string
    fullName: string
    phone: string
    email: string
  }
  grandTotal: number
  orderedProducts: Array<{
    id: string
    created_at: string
    updated_at: string
    cartTransactionsId: string
    productId: string
    totalPrice: number
    quantity: number
    discount: number
    orderDate: string
    businessId: string
    purchaseOrderId: any
    name: string
    SKU: string
    sellingPrice: number
    unitOfMeasure: string
  }>
  shipment: any
  business?: any
  location?: any
  tables?: any[]
  orderType?: string
}

export interface Invoice {
  id: string
  createdAt: Date
  totalPrice: number
  subtotal: number
  dueDate: Date
  invoiceDate: Date
  invoiceNumber: string
  currency: string
  shippingCost: number
  otherCost: number
  tax: number
  otherCostDetails: string
  fixedDiscount: number
  percentageDiscount: number
  memo: string
  notes: string
  termsAndCondition: string
  referenceNumber: string
  status: InvoiceStatus
  receiptUrl?: string
  customer: {
    id: string
    fullName: string
    phone: string
    email: string
  }
  location: Location
  paymentMethod?: "PAYPAL" | ""
  paymentId?: string
  outstanding?: number
  cartTransaction?: {
    id: string
  }
  amountReceived?: number
  project?: {
    id: string
    name: string
  }
  taxDetails: Array<{
    id: string
    name: string
    rate: number
    amount: number
    type: string
  }>
  employee?: Employee
  installmentDetail?: {
    term?: number
    amount?: number
  } | null
  termType?: string | null
  lastPaymentDate?: string | null
}

export interface InvoiceItem {
  id: string
  product: { id: string; name: string }
  quantity: number
  description: string
  sellingPrice: number
}

export interface Location {
  id?: string
  zip: string
  state: string
  city: string
  addressLine1: string
  addressLine2: string
  tin?: string
  companyName?: string
}

export interface Business {
  id: string
  name: string
  phone: string
  email: string
  logoUrl?: string
  businessCurrency?: string
}

export enum TERM_TYPE {
  ON_RECEIPT = "ON_RECEIPT",
  NO_DUE_DATE = "NO_DUE_DATE",
  SPECIFIC_DATE = "SPECIFIC_DATE",
  IN_10_DAYS = "IN_10_DAYS",
  IN_15_DAYS = "IN_15_DAYS",
  IN_30_DAYS = "IN_30_DAYS",
  IN_45_DAYS = "IN_45_DAYS",
  IN_60_DAYS = "IN_60_DAYS",
  IN_90_DAYS = "IN_90_DAYS",
  INSTALLMENT = "INSTALLMENT",
}

export enum INVOICE_STATUS {
  DRAFT = "DRAFT",
  SENT = "SENT",
  PAID = "PAID",
  VOID = "VOID",
}

export interface Shift {
  id: string
  startAt: Date
  endAt: Date
  fromDate: Date
  toDate: Date
  label: string
  color: string
}

export interface Estimate {
  id: string
  currency: string
  customerNote: string
  businessNote: string
  termsAndCondition: string
  referenceNumber: string
  estimateNumber: number
  totalPrice: number
  shippingCost: number
  tax: number
  otherCost: number
  otherCostDetails: any
  customFixedDiscount: number
  customPercentageDiscount: number
  createdAt: string
  updatedAt: string
  estimateDate: string
  status: string
  customer: {
    id: string
    fullName: string
    phone: string
    email: string
  }
  estimateItems: Array<{
    id: string
    quantity: number
    sellingPrice: number
    tax: number
    createdAt: string
    updatedAt: string
    product: { id: string; name: string; description: string }
    productVariant?: { id: string; variantName: string }
  }>
  invoice?: Invoice
  expirationDate?: string
  taxes?: Array<{
    id: string
    name: string
    rate: number
    type: string
  }>
}

export type InvoiceStatus = "DRAFT" | "PAID" | "CANCELLED" | "PENDING" | "SENT"

export interface POSSettings {
  id: string
  enableECatalog: boolean
  customersOptional: boolean
  enableEstoreReg: boolean
  deliveryMethod: string
  useShippingAddress: boolean
  pickup: boolean
  tax: number
  serviceCharge: number
  createdAt: string
  updatedAt: string
  allowPartialPayment: boolean
}

export interface InvoiceReminderSettings {
  id: string
  type: string
  daysBefore: number
  ccToOwner: boolean
  quota: number
  isActive: boolean
  interval: number
  note: string
}

export type PlacesSuggestions = Array<{
  description: string
  matched_substrings: MatchedSubstring[]
  place_id: string
  reference: string
  structured_formatting: StructuredFormatting
  terms: Term[]
  types: string[]
}>

interface MatchedSubstring {
  length: number
  offset: number
}

interface StructuredFormatting {
  main_text: string
  main_text_matched_substrings: MainTextMatchedSubstring[]
  secondary_text: string
}

interface MainTextMatchedSubstring {
  length: number
  offset: number
}

interface Term {
  offset: number
  value: string
}

export interface ShipmentMethod {
  id: string
  carrier?: string
  shippingType: string
  instructions: string
  minOrderPrice: number
  isDefault: boolean
  cashOnDelivery: boolean
  createdAt: string
  updatedAt: string
  shipmentDeliveryZones: ShipmentDeliveryZone[]
}

export interface ShipmentDeliveryZone {
  id: string
  deliveryZoneName: string
  deliveryAreaType: string
  postalCodes?: string
  areaRadius?: number
  areaRadiusUnit: string
  minOrderPrice: number
  deliveryPrice: number
  deliveryInformation: string
  estimatedDeliveryTime: number
}

export enum AppointmentStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  PAID = "PAID",
  DONE = "DONE", //done + paid
  CANCELLED = "CANCELLED",
}

export interface Appointment {
  id: string
  customerName?: string
  customerContact?: string
  isMpos: boolean
  startDate: string
  startTime: string
  endTimeExpected: string
  endDate: any
  endTime: any
  priceExpected: number
  priceFull?: number
  discount?: number
  tax: number
  serviceCharge: number
  priceFinal?: number
  isReminderSent: boolean
  reminderTime: any
  orderNumber: number
  status?: string
  numberOfCustomer: number
  createdAt: string
  updatedAt: string
  paymentType?: string
  servicesProvided: ServicesProvided[]
  location: Location

  cartTransaction?: {
    id: string
    outstanding: number
  }
}

interface ServicesProvided {
  id: string
  status: string
  duration: number
  startDateTime?: string
  endDateTime?: any
  productService: ProductService
  startTime?: string
  endTime?: string
  startDate?: string
  endDate?: string
}

export interface ProductService {
  id: string
  name: string
  SKU: string
  isReturnable?: boolean
  barcode?: string
  qrcode?: string
  description?: string
  productType: string
  productTypeStatus: any
  paymentOptions: any
  MRP: number
  MRPCurrency: string
  sellingPrice: number
  sellingPriceCurrency: string
  unitOfMeasure: string
  stock: number
  beginningStock: number
  status: string
  sold: number
  stockLost: number
  stockReceived: number
  stockReturned: number
  stockDamaged: number
  stockInHand: number
  reorderQuantity?: number
  imageUrl: any
  expirationwDate: any
  receivedDate?: string
  isVisible: boolean
  autoGenerateSKU: boolean
  salesReturned: number
  isOrderEnabled: boolean
  isVisibleOnEstore: boolean
  isBundle: boolean
  weight: number
  width: number
  height: number
  length: number
  attachment: any
  serviceType: any
  serviceDuration: number
  serviceDurationUnit: string
  serviceSlotTime: any
  gapBetweenAppointmentTime: number
  maxCustomerPerSlot: number
  reminderTime: any
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy?: string
}

export interface TimeSlots {
  monday: ServiceTime
  tuesday: ServiceTime
  wednesday: ServiceTime
  thursday: ServiceTime
  friday: ServiceTime
  saturday: ServiceTime
  sunday: ServiceTime
}

export interface ServiceTime {
  startTime: string
  endTime: string
  blockTimes: BlockTime[]
}

export interface BlockTime {
  breakStartTime: string
  breakEndTime: string
}

export const DEFAULT_MPOS_SETTING = {
  id: undefined as string | undefined,
  enableECatalog: false,
  customersOptional: true,
  enableEstoreReg: false,
  deliveryMethod: "inhouse",
  useShippingAddress: true,
  pickup: true,
  tax: 0,
  serviceCharge: 0,
  allowPartialPayment: false,
  requiredPin: false,
  allowManualDiscount: false,
  allowManualPrice: false,
  allowInvoicePayment: false,
  allowNegativeStock: false,
  kitchenPrinters: false,
  kitchenDisplay: false,
  customerDisplay: false,
  invoicing: false,
  enabledPaymentMethods: {
    invoice: true,
    payLater: true,
    online: true,
  },
  defaultCashbackPercentage: null as null | number,
  enableCashback: false,
  enableOpenTables: false,
  useTableManagement: false,
  layout: "grid" as "grid" | "list",
  useQueueNumber: false,
  useSelfieVerification: false,
  assignTicketsOnCheckout: false,
  assignTableNumberOnCheckout: false,
  enableSelfie: false,
  disableCloseRegisterOnPendingOrder: false,
}

export interface SubscriptionPlan {
  id: number
  name: string
  description: string
  features: {
    id: string
    displayName: string
    availableCount?: number
  }[]
  isEnabled: boolean
  billingFrequency: string
  planId: string
  flwPlanId?: string
  stripePlanId?: string
  appleProductId: any
  price: number
  countryGroup: any
  createdAt: string
  updatedAt: string
  maxEmployeesCount?: number
  maxProductsCount?: number
  maxStoresCount?: number
  localCurrency: string
  localPrice: number
}

export interface ServiceQRSetting {
  id: string
  linkUrl: string
  backgroundImageUrl: string
  backgroundColor: string
  textColor: string
  logoImageUrl: string
  titleText: string
  subtitleText: string
  footerText: string
  enableOnlinePayment: boolean
  stationQuantity: number
  stationLabel: string
  placeOrderInstruction: string
  getOrderInstruction: string
}

export interface IngredientsLocation {
  locationId: string
  ingredients: Ingredient[]
}

export interface Ingredient {
  id?: string
  product?: string
  quantity: number
  ingredient: string
  ingredientVariant?: string
  isRequired: boolean
  location?: string
  label?: string
  MRP?: number
  unitOfMeasurement?: string
  createdBy?: string
  conversionNumber?: number
}

export const DEFAULT_SERVICE_DETAILS = {
  serviceType: "HOURLY",
  serviceDurationUnit: "MINUTE",
  serviceDuration: 60,
  serviceSlotTime: {
    monday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
    tuesday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
    wednesday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
    thursday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
    friday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
    saturday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
    sunday: {
      startTime: "08:00:00",
      endTime: "22:00:00",
      blockTimes: [],
    },
  },
  maxCustomerPerSlot: 1,
  gapBetweenAppointmentTime: 0,
  reminderTime: 60,
  termsCondition: "",
  isAvailable: true,
  value: 0,
}

export type RolePermissions =
  | "pos:report:viewReceipts" // done
  | "pos:checkout:applyDiscount" // done
  | "pos:checkout:applyRestrictedPromotion"
  | "pos:checkout:changeTax" // @todo: implement permission (do we have this feature?)
  | "pos:checkout:acceptPayment" // done
  | "pos:orderManager:performReturn" // done
  | "pos:orderManager:cancelReceipt" // done
  | "pos:openOrders:manageOpenTicket" // done
  | "pos:orderManager:voidOpenTicket" // done
  | "pos:cashRegister:viewShiftReport" // @todo: implement permission
  | "pos:cashRegister:openCashDrawer" // done
  | "pos:orderManager:printReceipt" // done
  | "pos:report:viewSalesReport" // done
  | "pos:customer:manageCustomer" // done
  | "pos:dashboard:viewDashboard" // done
  | "pos:pos:multiStoreRegister"
  | "pos:salesOverview:viewSalesOverview" // done
  | "inventory:itemMaintenance:manageItem" // done
  | "inventory:itemMaintenance:manageItemInventory" // done? currently we hide adjust/transfer/receive stock
  | "inventory:itemMaintenance:viewCostItem" // done? should we hide inventory valuation only?
  | "inventory:dashboard:viewDashboard" // done
  | "inventory:report:viewReport" // @done
  | "inventory:report:viewAdvancedReport" // @todo: implement permission
  | "inventory:supplier:manageSupplier" // done
  | "setting:posSetting:changeSetting" // done
  | "setting:discount:manageDiscount" // done
  | "setting:general:editGeneralSetting" // done
  | "setting:subscription:manageBilling" // done
  | "setting:payment:managePayment" // done
  | "setting:loyalty:manageLoyalty" // done
  | "setting:tax:manageTax" // done
  | "setting:cashRegister:managePOSDevice" // done
  | "payroll:payroll:viewPayroll" // done
  | "payroll:payroll:managePayroll" // done
  | "payroll:payroll:dashboard" // done
  | "payroll:payroll:manageSetting" // done
  | "hrms:shift:manageShift" // done
  | "hrms:employee:manageEmployee" // done
  | "hrms:loan:manageLoan" // @todo: implement permission (not implemented on web)
  | "hrms:approval:requestApproval" // @todo: implement permissions (not implemented on web)
  | "hrms:approval:manageApproval" // @todo: implement permission (not implemented on web)
  | "hrms:hrms:dashboard" // done
  | "hrms:hrms:manageSetting" // done
  | "invoice:invoice:manageInvoice" // done
  | "getHelp:getHelp:liveChatSupport" // @todo: implement permission
  | "pos:cashRegister:payInOut" //done
  | "pos:kitchenRegister:kitchenRegister" //done
  | "setting:onlineStoreSetting:changeSetting" //done
  | "inventory:itemMaintenance:manageIngredients" //done
  | "inventory:itemMaintenance:printLabels" //done
  | "inventory:itemMaintenance:manageUnitOfMeasure" //done
  | "inventory:itemMaintenance:managePaymentTerms" //done
  | "inventory:itemMaintenance:managePriceList" //done
  | "setting:printers:printerSetup" //done
  | "setting:customerDisplay:manageCustomerDisplay" //done
  | "setting:onlineStoreSetting:changeCustomTheme"
  | "pos:orderManager:kitchenPrinter"
  | "inventory:report:viewSalesReport"
  | "inventory:report:viewReceipts"
  | "pos:cashback:manageCashback"
  | "inventory:purchaseOrder:managePurchaseOrder"
  | "inventory:xero:xeroIntegration"
  | "invoice:setting:invoiceSetting"
  | "pos:kitchenDisplay:kitchenDisplay"
  | "inventory:inventory:manageUom"
  | "pos:customerDisplay:customerDisplay"
  | "cashbook:cashbook:manageCashbook"
  | "creditbook:creditbook:manageCreditbook"
  | "inventory:inventory:transferStock"
  | "inventory:inventory:receiveStock"
  | "inventory:inventory:ingredient"
  | "inventory:inventory:inventoryCount"
  | "inventory:inventory:adjustStock"
  | "inventory:inventory:production"
  | "receiveStock:receiveStock:setAsHold"
  | "receiveStock:receiveStock:setAsReceived"
  | "receiveStock:receiveStock:updateDelivery"
  | "receiveStock:receiveStock:sendGRN"
  | "receiveStock:receiveStock:recordPayment"
  | "setting:b2b:manageSupplierAccount"
  | "agriculture:record:manage"
  | "agriculture:record:view"
  | "agriculture:input:view"
  | "agriculture:input:manage"
  | "agriculture:maintenance:manage"
  | "agriculture:maintenance:view"
  | "agriculture:report:view"
  | "financialReport:financialReport:salesOverview"
  | "financialReport:financialReport:accountReceiveable"
  | "financialReport:financialReport:balanceSheet"
  | "financialReport:financialReport:accountPayable"
  | "financialReport:financialReport:profitLoss"
  | "financialReport:financialReport:operatingCashFlow"
  | "financialReport:financialReport:manageAccounts"
  | "financialReport:financialReport:accountReceivableDetails"
  | "financialReport:journal:manage"
  | "financialReport:bill:create"
  | "financialReport:bill:approve"
  | "financialReport:bill:pay"
  | "financialReport:ledger:manage"
  | "financialReport:financialReport:businessSnapshot"
  | "invoice:invoice:approveInvoice"
  | "financialReport:expense:approve"
  | "inventory:purchaseOrder:approvePurchaseOrder"
  | "pos:checkout:applyManualDiscount"
  | "pos:checkout:applyManualPrice"
  | "pos:checkout:performRefund"
  | "setting:subscription:manageSwitchToDizlog"
  | "pos:orderManager:viewTransaction"
  | "inventory:inventory:createTransferOrder"
  | "inventory:inventory:approveTransferOrder"
  | "inventory:inventory:editTransferOrder"
  | "inventory:inventory:receiveTransferOrder"
  | "inventory:inventory:viewTransferOrder"
  | "hrms:hrms:hrmsReports"
  | "payroll:payroll:payrollReports"
  | "payroll:payroll:payrunReports"
  | "accounting:accounting:manageAccounting"
  | "invoice:invoice:editInvoice"
  | "invoice:invoice:createInvoice"
  | "invoice:invoice:viewInvoice"
  | "invoice:invoice:deleteInvoice"
  | "invoice:quotation:editQuotation"
  | "invoice:quotation:createQuotation"
  | "invoice:quotation:viewQuotation"
  | "invoice:quotation:deleteQuotation"

export const POS_PERMISSIONS = [
  {
    id: "pos:report:viewReceipts",
    name: "View Receipts",
  },
  {
    id: "pos:checkout:applyDiscount",
    name: "Apply Discount",
  },
  {
    id: "pos:checkout:applyRestrictedPromotion",
    name: "Apply Restricted Discount",
  },
  {
    id: "pos:checkout:changeTax",
    name: "Change Tax",
  },
  {
    id: "pos:checkout:acceptPayment",
    name: "Accept Payment",
  },
  {
    id: "pos:orderManager:performReturn",
    name: "Perform Return",
  },
  {
    id: "pos:openOrders:manageOpenTicket",
    name: "Manage Open Ticket",
  },
  {
    id: "pos:orderManager:voidOpenTicket",
    name: "Void Open Ticket",
  },
  {
    id: "pos:cashRegister:viewShiftReport",
    name: "View Shift Report",
  },
  {
    id: "pos:cashRegister:openCashDrawer",
    name: "Open Cash Drawer",
  },
  {
    id: "pos:orderManager:printReceipt",
    name: "Print Receipt",
  },
  {
    id: "pos:report:viewSalesReport",
    name: "View Sales Report",
  },
  {
    id: "pos:customer:manageCustomer",
    name: "Manage Customer",
  },
  {
    id: "pos:orderManager:cancelReceipt",
    name: "Cancel Receipt",
  },
  {
    id: "pos:dashboard:viewDashboard",
    name: "View Dashboard",
  },
  {
    id: "pos:salesOverview:viewSalesOverview",
    name: "View Sales Overview",
  },
  {
    id: "pos:cashRegister:payInOut",
    name: "Pay In Out",
  },
  {
    id: "pos:kitchenRegister:kitchenRegister",
    name: "Kitchen Register",
  },
  {
    id: "pos:checkout:applyManualDiscount",
    name: "Apply Manual Discount",
  },
  {
    id: "pos:checkout:applyManualPrice",
    name: "Apply Manual Price",
  },
  {
    id: "pos:checkout:performRefund",
    name: "Perform Refund",
  },
  {
    id: "pos:orderManager:viewTransaction",
    name: "View Transaction",
  },
  {
    id: "setting:customerDisplay:manageCustomerDisplay",
    name: "Manage Customer Display",
  },
  {
    id: "pos:orderManager:kitchenPrinter",
    name: "Kitchen Printer",
  },
  {
    id: "pos:kitchenDisplay:kitchenDisplay",
    name: "Kitchen Display",
  },
]

export const INVENTORY_PERMISSIONS = [
  // {
  //   id: "inventory:itemMaintenance:manageItem",
  //   name: "Manage Item",
  // },
  {
    id: "inventory:itemMaintenance:manageItemInventory",
    name: "Manage Item Inventory",
  },
  {
    id: "inventory:itemMaintenance:viewCostItem",
    name: "View Cost Item",
  },
  {
    id: "inventory:dashboard:viewDashboard",
    name: "View Dashboard",
  },
  {
    id: "inventory:report:viewReport",
    name: "View Report",
  },
  {
    id: "inventory:report:viewAdvancedReport",
    name: "View Advanced Report",
  },
  {
    id: "inventory:supplier:manageSupplier",
    name: "Manage Supplier",
  },
  {
    id: "inventory:itemMaintenance:manageIngredients",
    name: "Manage Ingredients",
  },
  {
    id: "inventory:itemMaintenance:printLabels",
    name: "Print Labels",
  },
  {
    id: "inventory:itemMaintenance:manageUnitOfMeasure",
    name: "Manage Unit of Measure",
  },
  {
    id: "inventory:itemMaintenance:managePaymentTerms",
    name: "Manage Payment Terms",
  },
  {
    id: "inventory:itemMaintenance:managePriceList",
    name: "Manage Price List",
  },
  {
    id: "inventory:inventory:createTransferOrder",
    name: "Create Transfer Order",
  },
  {
    id: "inventory:inventory:approveTransferOrder",
    name: "Approve Transfer Order",
  },
  {
    id: "inventory:inventory:editTransferOrder",
    name: "Edit Transfer Order",
  },
  {
    id: "inventory:inventory:receiveTransferOrder",
    name: "Receive Transfer Order",
  },
  {
    id: "inventory:inventory:viewTransferOrder",
    name: "View Transfer Order",
  },
  {
    id: "inventory:purchaseOrder:approvePurchaseOrder",
    name: "Approve Purchase Order",
  },
  {
    id: "inventory:inventory:inventoryCount",
    name: "Manage Inventory Count",
  },
  {
    id: "inventory:inventory:adjustStock",
    name: "Adjust Stock",
  },
  {
    id: "inventory:inventory:production",
    name: "Manage Production",
  },
]

export const SETTINGS_PERMISSIONS = [
  {
    id: "setting:posSetting:changeSetting",
    name: "Change POS Setting",
  },
  {
    id: "setting:general:editGeneralSetting",
    name: "Edit General Setting",
  },
  {
    id: "setting:subscription:manageBilling",
    name: "Manage Billing",
  },
  {
    id: "setting:payment:managePayment",
    name: "Manage Payment",
  },
  {
    id: "setting:discount:manageDiscount",
    name: "Manage Discount",
  },
  {
    id: "setting:loyalty:manageLoyalty",
    name: "Manage Loyalty",
  },
  {
    id: "setting:tax:manageTax",
    name: "Manage Tax",
  },
  {
    id: "setting:cashRegister:managePOSDevice",
    name: "Manage POS Device",
  },
  {
    id: "setting:printers:printerSetup",
    name: "Printer Setup",
  },
  {
    id: "setting:subscription:manageSwitchToDizlog",
    name: "Manage Switch to Dizlog",
  },
]

export const PAYROLL_PERMISSIONS = [
  {
    id: "payroll:payroll:viewPayroll",
    name: "View Payroll",
  },
  {
    id: "payroll:payroll:managePayroll",
    name: "Manage Payroll",
  },
  {
    id: "payroll:payroll:dashboard",
    name: "View Dashboard",
  },
  {
    id: "payroll:payroll:manageSetting",
    name: "Manage Setting",
  },
]

export const HRMS_PERMISSIONS = [
  {
    id: "hrms:shift:manageShift",
    name: "Manage Shift",
  },
  {
    id: "hrms:employee:manageEmployee",
    name: "Manage Employee",
  },
  {
    id: "hrms:loan:manageLoan",
    name: "Manage Loan",
  },
  {
    id: "hrms:approval:requestApproval",
    name: "Request Approval",
  },
  {
    id: "hrms:approval:manageApproval",
    name: "Manage Approval",
  },
  {
    id: "hrms:hrms:dashboard",
    name: "View Dashboard",
  },
  {
    id: "hrms:hrms:manageSetting",
    name: "Manage HRMS Setting",
  },
  {
    id: "hrms:hrms:hrmsReports",
    name: "View HRMS Reports",
  },
  {
    id: "payroll:payroll:payrollReports",
    name: "View HRMS Payroll Reports",
  },
  {
    id: "payroll:payroll:payrunReports",
    name: "View HRMS Payrun Reports",
  },
]

export const INVOICE_PERMISSIONS = [
  {
    id: "invoice:setting:invoiceSetting",
    name: "Invoice Setting",
  },
  {
    id: "invoice:invoice:manageInvoice",
    name: "Manage Invoice",
  },
  {
    id: "invoice:invoice:approveInvoice",
    name: "Approve Invoice",
  },
  {
    id: "invoice:invoice:editInvoice",
    name: "Edit Invoice",
  },
  {
    id: "invoice:invoice:createInvoice",
    name: "Create Invoice",
  },
  {
    id: "invoice:invoice:viewInvoice",
    name: "View Invoice",
  },
  {
    id: "invoice:invoice:deleteInvoice",
    name: "Delete Invoice",
  },
  {
    id: "invoice:quotation:editQuotation",
    name: "Edit Quotation",
  },
  {
    id: "invoice:quotation:createQuotation",
    name: "Create Quotation",
  },
  {
    id: "invoice:quotation:viewQuotation",
    name: "View Quotation",
  },
  {
    id: "invoice:quotation:deleteQuotation",
    name: "Delete Quotation",
  },
]

export const AGRICULTURE_PERMISSION = [
  {
    id: "agriculture:record:view",
    name: "View Agriculture Record",
  },
  {
    id: "agriculture:input:view",
    name: "View Agriculture Input",
  },
  {
    id: "agriculture:maintenance:view",
    name: "View Agriculture Maintenance",
  },
  {
    id: "agriculture:report:view",
    name: "View Agriculture Report",
  },
  {
    id: "agriculture:record:manage",
    name: "Manage Agriculture Record",
  },
  {
    id: "agriculture:input:manage",
    name: "Manage Agriculture Input",
  },
  {
    id: "agriculture:maintenance:manage",
    name: "Manage Agriculture Maintenance",
  },
]

export const RECEIVE_STOCK_PERMISSIONS = [
  {
    id: "receiveStock:receiveStock:setAsHold",
    name: "Set as Hold",
  },
  {
    id: "receiveStock:receiveStock:setAsReceived",
    name: "Set as Received",
  },
  {
    id: "receiveStock:receiveStock:updateDelivery",
    name: "Update Delivered Stock",
  },
  {
    id: "receiveStock:receiveStock:sendGRN",
    name: "Send GRN",
  },
  {
    id: "receiveStock:receiveStock:recordPayment",
    name: "Record Payment",
  },
]

export const FINANCIAL_REPORT_PERMISSIONS = [
  {
    id: "financialReport:financialReport:businessSnapshot",
    name: "View Business Snapshot",
  },
  {
    id: "financialReport:financialReport:salesOverview",
    name: "View Sales Overview",
  },
  {
    id: "financialReport:financialReport:accountReceiveable",
    name: "View Account Receivable",
  },
  {
    id: "financialReport:financialReport:accountReceivableDetails",
    name: "View Account Receivable Detail",
  },
  {
    id: "financialReport:financialReport:balanceSheet",
    name: "View Balance Sheet",
  },
  {
    id: "financialReport:financialReport:accountPayable",
    name: "View Account Payable",
  },
  {
    id: "financialReport:financialReport:profitLoss",
    name: "View Profit Loss",
  },
  {
    id: "financialReport:financialReport:operatingCashFlow",
    name: "View Operating Cash Flow",
  },
  {
    id: "financialReport:financialReport:manageAccounts",
    name: "Manage Accounts",
  },
  {
    id: "financialReport:journal:manage",
    name: "Manage Journal",
  },
  {
    id: "financialReport:bill:create",
    name: "Create Bill",
  },
  {
    id: "financialReport:bill:approve",
    name: "Approve Bill",
  },
  {
    id: "financialReport:bill:pay",
    name: "Pay Bill",
  },
  {
    id: "financialReport:ledger:manage",
    name: "Manage Ledger",
  },
  {
    id: "financialReport:expense:approve",
    name: "Approve Expenses",
  },
]

export const GET_HELP_PERMISSIONS = [
  {
    id: "getHelp:getHelp:liveChatSupport",
    name: "Live Chat Support",
  },
]

export const ONLINE_STORE_PERMISSIONS = [
  {
    id: "setting:onlineStoreSetting:changeSetting",
    name: "Manage Online Store Setting",
  },
  {
    id: "setting:onlineStoreSetting:changeCustomTheme",
    name: "Customize Online Store",
  },
]

export const ROLE_ID_NAME: Record<string, string> = {
  pos: "POS",
  inventory: "Inventory",
  receiveStock: "Receive Stock",
  setting: "Settings",
  payroll: "Payroll",
  hrms: "HRMS",
  invoice: "Invoice",
  agriculture: "Agriculture",
  financialReport: "Financial Report",
}

export type TaxType = "ADDED" | "INCLUDED"

export type TaxOption =
  | "APPLY_TO_NEW_ITEM"
  | "APPLY_TO_EXISTING"
  | "APPLY_TO_ALL"

export enum EstoreTheme {
  DEFAULT = 1,
  MULTIKART = 2, //Grocery
  MULTIKART_SHOES = 3, //Retail
  SERVICE_BOOKING = 4, //Services
  PIZZON = 5, //Restaurant
  HAIR_SALON = 6, //Beauty
}

export const EWALLET_COUNTRY_MAPPING = {
  KE: "MPESA",
  UG: "MOBILE_MONEY",
  GH: "MOBILE_MONEY",
  RW: "MOBILE_MONEY",
  CI: "MOBILE_MONEY",
  SN: "MOBILE_MONEY",
  TZ: "MOBILE_MONEY",
  BF: "MOBILE_MONEY",
  ML: "MOBILE_MONEY",
  ZM: "MOBILE_MONEY",
  CM: "MOBILE_MONEY",
  BW: "MOBILE_MONEY",
  CD: "MOBILE_MONEY",
  MW: "MOBILE_MONEY",
}

export const DEFAULT_TEMPLATE = {
  id: undefined as string | undefined,
  headerText: "",
  footerText: "",
  primaryColor: "#000000",
  defaultNoteInvoice: "",
  defaultNoteEstimates: "",
  defaultNoteGRN: "",
  defaultNotePurchaseOrder: "",
  logoUrl: "",
  fontType: "",
  fontSize: "",
  address: "",
  email: "",
  phone: "",
  createdAt: "",
  updatedAt: "",
  paymentDetails: "",
}

export interface ProductAddOnsGroup {
  id: string | undefined
  name: string
  minChoose: number
  maxChoose: number
  allowQuantitySelector: boolean
  productAddOns: ProductAddOnsOption[]
}

export interface ProductAddOnsOption {
  id: string | undefined
  name: string
  sellingPrice: number
  sellingPriceCurrency: string
  // location: string
  location: any
}

export const DEFAULT_ADDONS = {
  id: "",
  name: "",
  sellingPrice: 0,
  sellingPriceCurrency: "",
  location: "",
}

export const DEFAULT_ADDONS_GROUP: ProductAddOnsGroup = {
  id: "",
  name: "",
  minChoose: 0,
  maxChoose: 0,
  allowQuantitySelector: false,
  productAddOns: [],
}

export interface UnitOfMeasure {
  id?: string | undefined
  unit: string
  precision: number
  isDefault: boolean
  isEnabled: boolean
}

export interface PrinterSetting {
  id?: string | undefined
  name: string
  model: string
  interface: string
  ipAddress: string
  paperWidth: string
  printReceiptsAndBills: boolean
  printOrders: boolean
  automaticPrintReceipt: boolean
  printerGroup?: any
}

export const CUSTOMER_TYPES = [
  {
    key: "RETAIL",
    label: "Retail",
  },
  {
    key: "BUSINESS",
    label: "Business",
  },
]

export enum CustomerTermType {
  NONE = "NONE",
  DUE_ON_FULFILLMENT = "DUE_ON_FULFILLMENT",
  NET_7 = "NET_7",
  NET_15 = "NET_15",
  NET_30 = "NET_30",
  NET_45 = "NET_45",
  NET_60 = "NET_60",
  NET_90 = "NET_90",
}

export const CUSTOMER_TERM_TYPE = [
  { key: CustomerTermType.NONE, label: "None" },
  { key: CustomerTermType.DUE_ON_FULFILLMENT, label: "Due on fulfillment" },
  { key: CustomerTermType.NET_7, label: "Net 7" },
  { key: CustomerTermType.NET_15, label: "Net 15" },
  { key: CustomerTermType.NET_30, label: "Net 30" },
  { key: CustomerTermType.NET_45, label: "Net 45" },
  { key: CustomerTermType.NET_60, label: "Net 60" },
  { key: CustomerTermType.NET_90, label: "Net 90" },
]

export interface PriceList {
  id: string | undefined
  createdAt?: string
  currency: string
  endDate?: string | null
  isActive: boolean
  name: string
  startDate?: string | null
  updatedAt?: string
  customers?: PriceListCustomer[]
  products?: PriceListProduct[]
  priceAdjustmentPercentage?: any
  location: {
    id: string
  } | null
}

export interface PriceListCustomer {
  id: string
}

export interface PriceListProduct {
  id: string
  name?: string
  currency: string
  sellingPrice?: number
  priceType: PriceListPriceType
  priceAdjustmentPercentage?: number
  createdAt?: string
  updatedAt?: string
  minQuantity?: number
  maxQuantity?: number
  productVariant?: string | null
}

export type PriceListPriceType = "FIXED" | "ADJUSTMENT" | "VOLUME"

export interface Reward {
  id: string
  name: string
  type: string
  redeemablePoints: number
  discountPercentage: number
  maximumDiscount: number
  discount: number
  minimumSpending: number
  createdAt: string
  updatedAt: string
  isActive: boolean
  products: any
  categories: any
}

export interface KitchenDisplay {
  id: string | undefined
  name: string
  ipAddress: string
  alertTime: number
  lateTime: number
  isEnabled: boolean
  location: {
    id: string
  }
  category: KitchenDisplayCategory
}

export interface KitchenDisplayCategory {
  id: string | undefined
  name?: string
  productCategories?: Array<{
    id: string
  }>
}

export interface PaymentTerm {
  id: string
  name: string
  days: number
  isDefault: boolean
  isEnabled: boolean
  createdAt: string
  updatedAt: string
}

export interface KitchenDisplayTransaction {
  id: string
  cartTransaction: KitchenDisplayCartTransaction
  isEnabled: boolean
  items: KitchenDisplayItem[]
  kitchenDisplay: KitchenDisplay
  status: KitchenDisplayTransactionStatus
  createdAt: string
  updatedAt: string
}

export interface KitchenDisplayCartTransaction extends CartTransaction {
  customerName: string
}

export type KitchenDisplayTransactionStatus = "OPEN" | "DONE" | "ON_HOLD"

export interface KitchenDisplayItem {
  item: string
  quantity: number
  notes: string
  isProcessed: boolean
  productId: string
  productVariantId: string
  addOns: string[]
}

export interface LoyaltyProgram {
  id: string | undefined
  name: string
  type: LoyaltyProgramType
  rewardedPoints: number
  minimumSpending: number | null
  minimumQuantity: number | null
  isActive: boolean
  products: LoyaltyProgramProduct[]
  categories: LoyaltyProgramCategory[]
  location?: Locations | undefined
  maximumUsageCount?: number
}

export interface LoyaltyProgramProduct {
  id: string | undefined
  product: { id: string; name?: string }
  productVariant: { id: string; variantValues?: any } | undefined
}

export interface LoyaltyProgramCategory {
  id: string | undefined
  category: { id: string; name?: string }
}

export const DEFAULT_LOYALTY_PROGRAM = {
  id: undefined,
  name: "",
  type: "POINTS_FROM_TOTAL_SPENT" as LoyaltyProgramType,
  rewardedPoints: 0,
  minimumSpending: null,
  minimumQuantity: 0,
  isActive: true,
  products: [],
  categories: [],
  maximumUsageCount: 0,
}

export type LoyaltyProgramType =
  | "POINTS_FROM_TOTAL_SPENT"
  | "POINTS_FROM_ITEM_PURCHASE"
  | "POINTS_FROM_REFERRAL"

export interface ClaimApproval {
  id: string | undefined
  amount: number
  attachmentLink: string | null
  claimType: string
  currency: string
  paymentMode: ClaimApprovalPaymentMode
  requestFor: string
  status: ClaimApprovalStatus
  reason: any
  createdAt?: string
  updatedAt?: string
  employee?: any
  paidAt?: string
  requestId?: string
}

export type ClaimApprovalPaymentMode = "Online" | "Cash"

export type ClaimApprovalStatus = "Pending" | "Approved" | "Rejected"

export const claimApprovalStatus = ["Pending", "Approved", "Rejected"]

export const DEFAULT_CLAIM: ClaimApproval = {
  id: undefined,
  amount: 0,
  attachmentLink: null,
  claimType: "",
  currency: "USD",
  paymentMode: "Online" as ClaimApprovalPaymentMode,
  requestFor: "",
  status: "Pending" as ClaimApprovalStatus,
  reason: "",
}

export interface BirPWDMetadata {
  type: "bir-pwd"
  seniorCitizenCount: number
  pwdCount: number
  seniorCitizenId?: string
  pwdId?: string
  customer?: Customer
}

export interface BirNationalAthletesMetadata {
  type: "bir-athletes"
  pnstmId: string
  customer?: Customer
}

export type PromotionMetadata = BirPWDMetadata | BirNationalAthletesMetadata

// we no longer have free plan
export const FREE_PLAN_ID = "starter-lifetime-old-###"

export const HOMEPAGE_URL = "/dashboard/business-snapshot"

export const BILL_PAYMENT_STATUS_LABELS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Awaiting approval",
    value: "SUBMITTED",
  },
  {
    label: "Awaiting payment",
    value: "APPROVED",
  },
  {
    label: "Paid",
    value: "PAID",
  },
  {
    label: "Void",
    value: "VOIDED",
  },
]

export const INVOICE_STATUS_LABELS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Sent",
    value: "SENT",
  },
  {
    label: "Partially Paid",
    value: "PARTIALLY_PAID",
  },
  {
    label: "Paid",
    value: "PAID",
  },
  {
    label: "Void",
    value: "VOID",
  },
]

export const QUOTATION_STATUS_LABELS = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Created",
    value: "CREATED",
  },
  {
    label: "Draft",
    value: "DRAFT",
  },
  {
    label: "Accepted",
    value: "ACCEPTED",
  },
  {
    label: "Invoiced",
    value: "INVOICED",
  },
  {
    label: "Void",
    value: "VOID",
  },
]

export enum TaxApplyTo {
  SALES = "SALES",
  PURCHASE = "PURCHASE",
}

export interface Tax {
  id: string
  rate: number
  name: string
  type: string
}

export enum VatType {
  NO_TAX = "NO_TAX",
  TAX_INCLUSIVE = "TAX_INCLUSIVE",
  TAX_EXCLUSIVE = "TAX_EXCLUSIVE",
}

export enum CalculatePeriod {
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  FORTNIGHT = "FORTNIGHT", //do not pushed type to DB
}

export interface KitchenPrinterOptions {
  type?: "ORIGINAL" | "REPRINT" | "EDITED" | "VOIDED"
  format?: "FULL" | "SHORT"
  variant?: "ONE_LINE" | "SEPARATE_LINES"
}

export enum ReceiptType {
  ORIGINAL = "ORIGINAL",
  REPRINT = "REPRINT",
  VOID = "VOID",
  REFUND = "REFUND",
}

export interface BIRJournalFilter {
  startDate: string
  endDate: string
  location: string
  cashRegister: string
  employee: string
  transaction: string
  transactionType: string
  customer: string
  paymentMethod: string
}

export const BIR_LABEL: Record<string, string> = {
  "bir-pwd-food": "SC/PWD",
  "bir-pwd-retail": "SC/PWD",
}

export const types = [
  { group: "Food and Beverage", type: "Coffee Shop / Café" },
  { group: "Food and Beverage", type: "Fastfood / Restaurant" },
  { group: "Food and Beverage", type: "Food Services - Catering" },
  { group: "Food and Beverage", type: "Other Specialty Food Stores" },
  { group: "Retail", type: "Arts / Crafts / Novelty" },
  { group: "Retail", type: "Bike / Motor Vehicles Supplies and Parts" },
  { group: "Retail", type: "Clothing / Footwear Store" },
  { group: "Retail", type: "Construction and Hardware Equipment and Supplies" },
  { group: "Retail", type: "Department / General Mechandise / Retail" },
  { group: "Retail", type: "Goods and Grocery Store" },
  { group: "Retail", type: "Leisure and Entertainment" },
  { group: "Retail", type: "Manufacturing" },
  { group: "Retail", type: "Other Retail Stores" },
  { group: "Services", type: "Accounting, Auditing and Book keeping" },
  { group: "Services", type: "Beauty and Cosmetic Services" },
  { group: "Services", type: "Cleaning / Washing Services" },
  {
    group: "Services",
    type: "Computer , Data Processing, Design, Information & Comm Services",
  },
  { group: "Services", type: "Metal, Electrical, Plumbing and Heating" },
  { group: "Services", type: "Other Professional or Specialized Services" },
  { group: "Services", type: "Transportation and storage" },
  {
    group: "Services",
    type: "Water Supply & Sewerage, Waste Management and Remediation Activities",
  },
  { group: "Agriculture", type: "Agriculture" },
]

export interface BIRReceiptDetails {
  trx: GetCartTransactionResponse
  business: GetBusinessResponse
  nextSeriesNumber?: string
  nextORNumber?: string
  invoiceSettings: InvoiceSettingsResponseBody[number]
  type: "ORIGINAL" | "DEFAULT"
}

export const TransactionStatus = [
  "PENDING",
  "PENDING_OPEN_ORDER",
  "APPROVED",
  "PREPARING",
  "CANCELLED",
  "PAID",
  "ASSIGNING_DRIVER",
  "READY_FOR_PICKUP",
  "READY_FOR_DELIVERY",
  "ON_ITS_WAY",
  "DELIVERED",
  "PICKED_UP",
  "SHIPMENT_REJECTED",
  "SHIPMENT_EXPIRED",
  "VOID",
  "PAYMENT_FAILED",
  "DONE",
  "PARTIALLY_PAID",
  "RETURN_REQUESTED",
  "RETURN_REJECTED",
  "RETURN_ACCEPTED",
  "REFUNDED",
] as const
